import { connectRouter }                                               from 'connected-react-router';
import { apiSections }                                                 from 'dmpconnectjsapp-base/constants';
import {
  dmpconnect,
  dmpconnectInit,
  dmpconnectInteropCodes,
  dmpconnectMonitoring,
  getDmpconnectConnectorConfigReducer,
  getDmpconnectINSiConfigReducer,
  getDmpconnectTSEConfigReducer,
}                                                                      from 'dmpconnectjsapp-base/reducers';
import getDmpconnectApCvConfigReducer
                                                                       from 'dmpconnectjsapp-base/reducers/dmpconnectApCvConfiguration';
import getDmpconnectPersistedConnectorConfiguration
                                                                       from 'dmpconnectjsapp-base/reducers/dmpconnectPersistedConnectorConfiguration';
import { combineReducers }                                             from 'redux';
import { createMigrate, persistReducer }                               from 'redux-persist';
import indexedDBStorage                                                from 'redux-persist-indexeddb-storage';
import storage                                                         from 'redux-persist/lib/storage';
import { dmpconnectApplication, dmpConnectPersistedAppConfiguration, } from '../dmpconnect/reducers';
import env                                                             from '../envVariables';
import {
  apCvConfigFromEnv,
  connectorConfigFromEnv,
  insiConfigFromEnv,
  persistedConnectorConfigFromEnv,
  tseConfigFromEnv,
}                                                                      from './configFromEnv';

export const storages = [];

function customPersistReducer(config, reducer) {
  storages.push(config.key);
  return persistReducer(config, reducer);
}

const dmpconnectConnectorConfig          = getDmpconnectConnectorConfigReducer(connectorConfigFromEnv);
const dmpconnectPersistedConnectorConfig = getDmpconnectPersistedConnectorConfiguration(persistedConnectorConfigFromEnv);
const dmpconnectTSEConfiguration         = getDmpconnectTSEConfigReducer(tseConfigFromEnv);
const dmpconnectINSiConfiguration        = getDmpconnectINSiConfigReducer(insiConfigFromEnv);
const dmpconnectApCvConfiguration        = getDmpconnectApCvConfigReducer(apCvConfigFromEnv);

const rootPersistConfig = {
  key:       'efficience_root',
  storage,
  whitelist: [
    'dmpConnectPersistedAppConfiguration',
    'userPreferences',
    'dmpconnectPersistedConnectorConfiguration',
    'dmpconnectTSEConfiguration',
  ],
  version:   4,
  migrate:   createMigrate({
                             4: state => ({
                               ...state,
                               dmpConnectPersistedAppConfiguration: {
                                 ...state.dmpConnectPersistedAppConfiguration,

                                 vitalePatientExportMethod:           env.REACT_APP_VITALE_PATIENT_EXPORT_METHOD,
                                 vitalePatientExportURL:              env.REACT_APP_VITALE_PATIENT_EXPORT_URL,
                                 vitalePatientExportArgsMapper:       env.REACT_APP_VITALE_PATIENT_EXPORT_ARGS_MAPPER,
                                 hideFooter:                          Number(env.REACT_APP_HIDE_FOOTER) === 1,
                                 vitalePatientExportLabel:            env.REACT_APP_VITALE_PATIENT_EXPORT_LABEL,
                                 vitalePatientExportExtraArgsFromURL: env.REACT_APP_VITALE_PATIENT_EXPORT_EXTRA_ARGS_FROM_URL,
                               }
                             }),
                           }),
};

if (Number(env.REACT_APP_DEBUG) === 1) {
  rootPersistConfig.whitelist.push('dmpconnectConnectorConfig');
}

const dmpconnectPersistConfig                  = {
  key:       'efficience_commands',
  storage:   indexedDBStorage('efficience_commands'),
  serialize: false,
  version:   3,
  blacklist: [
    apiSections.INTEROPCODES,
  ],
};
const dmpconnectINSiConfigurationPersistConfig = {
  key:     'efficience_insi_config',
  storage,
  version: 1,
};
const dmpconnectApCvConfigurationPersistConfig = {
  key:     'efficience_apcv_config',
  storage,
  version: 1,
};
const interopCodesPersistConfig                = {
  key:       'efficience_interop_codes',
  storage:   indexedDBStorage('efficience_interop_codes'),
  serialize: false,
  version:   2,
};


const createRootReducer = (history) => {
  if (Number(env.REACT_APP_ENABLE_CUSTOM_PRACTICE_SETTING) === 1) {
    localStorage.setItem('enableCustomPracticeLocation', 1);
  }

  return combineReducers({
                           router: connectRouter(history),

                           // external connector
                           dmpconnect: customPersistReducer(dmpconnectPersistConfig, dmpconnect),
                           dmpconnectInit,

                           dmpconnectConnectorConfig,
                           dmpconnectTSEConfiguration,
                           dmpconnectPersistedConnectorConfiguration: dmpconnectPersistedConnectorConfig,
                           dmpconnectInteropCodes:                    customPersistReducer(interopCodesPersistConfig, dmpconnectInteropCodes),

                           // services
                           dmpconnectINSiConfiguration: Number(env.REACT_APP_DEBUG) === 1
                                                        ? customPersistReducer(dmpconnectINSiConfigurationPersistConfig, dmpconnectINSiConfiguration)
                                                        : dmpconnectINSiConfiguration,
                           dmpconnectApCvConfiguration: Number(env.REACT_APP_DEBUG) === 1
                                                        ? customPersistReducer(dmpconnectApCvConfigurationPersistConfig, dmpconnectApCvConfiguration)
                                                        : dmpconnectApCvConfiguration,

                           // application
                           dmpconnectMonitoring,
                           dmpconnectApplication,
                           dmpConnectPersistedAppConfiguration,
                         });
};

export default history => customPersistReducer(rootPersistConfig, createRootReducer(history));
