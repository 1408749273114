import { clearSection }                                    from 'dmpconnectjsapp-base/actions';
import { apiSections }                                     from 'dmpconnectjsapp-base/constants';
import { getApiType, getConfigurationValue, getSessionId } from 'dmpconnectjsapp-base/helpers/accessors';
import { isLoading, isReady }                              from 'dmpconnectjsapp-base/helpers/common';
import PropTypes                                           from 'prop-types';
import React, { useEffect }                                from 'react';
import { connect }                                         from 'react-redux';
import { requestPcscReaders }                              from '../../dmpconnect/actions';
import { API_TYPES }                                       from '../../dmpconnect/constants';
import CenteredCard                                        from '../Common/Card/CenteredCard';
import Loading                                             from '../Common/Loading/Loading';

const CardReaderProvider = ({
  ready, loading, dispatch, apiType, children, esRestVitale, sessionId, showLoading,
}) => {
  const [isReadersLoading, setReadersLoading] = React.useState(loading);
  useEffect(() => {
    if (ready) setReadersLoading(false);
  }, [ready]);

  useEffect(() => {
    if (
      !ready && !isReadersLoading
      && (
        (!!sessionId && apiType !== API_TYPES.REST)
        || (apiType === API_TYPES.REST && esRestVitale)
      )
    ) {
      setReadersLoading(true);
      dispatch(clearSection(apiSections.PCSC_READERS_SECTION));
      dispatch(requestPcscReaders(false));
    }
  }, [apiType, esRestVitale, sessionId, ready, isReadersLoading]);
  if (
    !(!!sessionId && apiType !== API_TYPES.REST)
  ) {
    return children;
  }
  if (!showLoading || ready) return children;
  return <CenteredCard><Loading message="Chargement ..." /></CenteredCard>;
};

CardReaderProvider.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  ready: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  apiType: PropTypes.string.isRequired,
  esRestVitale: PropTypes.bool,
  sessionId: PropTypes.string,
  showLoading: PropTypes.bool,
};

CardReaderProvider.defaultProps = {
  esRestVitale: false,
  sessionId: null,
  showLoading: true,
};

const mapStateToProps = (state) => {
  const {
    dmpconnect: {
      [apiSections.PCSC_READERS_SECTION]: pcscReaders,
    },
    dmpconnectPersistedConnectorConfiguration,
  } = state;

  return {
    ready: isReady(pcscReaders),
    loading: isLoading(pcscReaders),
    apiType: getApiType(state),
    esRestVitale: getConfigurationValue('esRestVitale', dmpconnectPersistedConnectorConfiguration),
    sessionId: getSessionId(state),
  };
};

export default connect(mapStateToProps)(CardReaderProvider);
