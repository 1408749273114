import _objectWithoutProperties from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import merge from 'lodash.merge';
import { dmpCommandFailureContextualizedType, dmpCommandStartContextualizedType, dmpCommandSuccessContextualizedType } from '../actions/dmpconnectActions';
import { accessModes, apiSections, dmpconnectActionConstants, dmpconnectInitActionConstants } from '../constants';
import { checkTD02NameNonRenseigne, checkTD05NameNonRenseigne } from '../helpers/checks/checkNameNonRenseigne';
import { updateDpSearchResults } from '../helpers/DP';
import { apCvContextSuccessToVitaleData, vitaleCardSuccessToVitaleData } from '../helpers/vitaleData';
import { mergeForceNewValues } from '../utils/dataUtils';
import { clearErrors, getClearSectionState, getClearState, getClearSubSectionState, getFailureState, getLoadingState, getSuccessState, getTooLongState } from './helpers';
var initialState = _objectSpread({
  accessMode: accessModes.NormalAccess,
  selectedIns: null,
  selectedCertifiedIdentity: null,
  selectedDMP: null,
  secretConnection: 0,
  sockets: {}
}, Object.values(apiSections).reduce(function (o, section) {
  return _objectSpread({}, o, _defineProperty({}, section, {}));
}, {}));
var dmpRemoteCommandFailureContextualizedType = function dmpRemoteCommandFailureContextualizedType(section) {
  return "DMPC_REMOTE_COMMAND_".concat(section.toUpperCase(), "_FAILURE");
};
var dmpRemoteCommandSuccessContextualizedType = function dmpRemoteCommandSuccessContextualizedType(section) {
  return "DMPC_REMOTE_COMMAND_".concat(section.toUpperCase(), "_SUCCESS");
};
export function dmpconnect() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case dmpconnectInitActionConstants.DMPC_INIT_APPLICATION:
      return clearErrors(state);
    case dmpconnectActionConstants.DMPC_SEND_COMMAND:
    case dmpconnectActionConstants.DMPC_SEND_REST_COMMAND:
    case dmpconnectActionConstants.DMPC_SEND_HTTP_COMMAND:
    case dmpconnectActionConstants.DMPC_SEND_HTTP_SYNCHRONOUS_COMMAND:
    case dmpconnectActionConstants.DMPC_SEND_JSON_COMMAND:
    case dmpconnectActionConstants.DMPC_SEND_SYNCHRONOUS_COMMAND:
    case dmpconnectActionConstants.DMPC_SEND_REST_SYNCHRONOUS_COMMAND:
    case dmpconnectActionConstants.DMPC_SEND_JSON_SYNCHRONOUS_COMMAND:
      return merge({}, state, getLoadingState(action));
    case dmpconnectActionConstants.DMPC_COMMAND_SUCCESS:
      return mergeForceNewValues(state, getSuccessState(action));
    case dmpconnectActionConstants.DMPC_COMMAND_FAILURE:
      return merge({}, state, getFailureState(action));
    case dmpconnectActionConstants.DMPC_COMMAND_TOO_LONG:
      return merge({}, state, getTooLongState(action));
    case dmpconnectActionConstants.DMPC_COMMAND_STOP:
      return getClearState(action, state);
    case dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_SUCCESS:
      return mergeForceNewValues(state, getSuccessState(action));
    case dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_FAILURE:
      return merge({}, state, getFailureState(action));
    case dmpconnectActionConstants.DMPC_CLEAR_SUB_SECTION:
      return getClearSubSectionState(action, state);
    case dmpconnectActionConstants.DMPC_CLEAR_SECTION:
      return getClearSectionState(action, state);
    case dmpconnectActionConstants.DMPC_CLEAR_DATA:
      return merge({}, initialState, {
        session: state.session
      });
    case dmpconnectActionConstants.DMPC_CLEAR_SESSION:
      return initialState;
    case dmpconnectActionConstants.DMPC_SELECT_INS:
      return merge({}, state, {
        selectedIns: action.ins
      });
    case dmpconnectActionConstants.DMPC_SELECT_CERTIFIED_ID:
      return merge({}, state, {
        selectedCertifiedIdentity: action.certifiedIdentity
      });
    case dmpCommandSuccessContextualizedType(apiSections.DMP_ACCESS_MODE_SECTION):
      return merge({}, state, _objectSpread({}, getSuccessState(_objectSpread({}, action, {
        data: {
          s_status: 'OK'
        }
      })), {
        accessMode: Number(action.command.i_accessMode)
      }));
    case dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION):
      return merge({}, state, {
        secretConnection: action.command.i_enableSecretConnection
      });
    case dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION_REST):
      return merge({}, state, _objectSpread({}, getSuccessState(_objectSpread({}, action, {
        data: {
          s_status: 'OK'
        }
      })), {
        secretConnection: action.command.i_enableSecretConnection
      }));
    case dmpconnectActionConstants.DMPC_SET_SOCKET:
      {
        var _state$sockets = state.sockets,
          socketToReplace = _state$sockets[action.commandName],
          sockets = _objectWithoutProperties(_state$sockets, [action.commandName].map(_toPropertyKey)),
          keepState = _objectWithoutProperties(state, ["sockets"]);
        return _objectSpread({
          sockets: _objectSpread(_defineProperty({}, action.commandName, action.socket), sockets)
        }, keepState);
      }
    case dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION):
      return merge({}, state, _defineProperty({}, apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION, _defineProperty({}, action.context.subSection, _objectSpread({}, checkTD02NameNonRenseigne(action.data)))));
    case dmpCommandSuccessContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION):
      return merge({}, state, _defineProperty({}, apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION, _defineProperty({}, action.context.subSection, _objectSpread({}, checkTD02NameNonRenseigne(action.data)))));
    case dmpCommandSuccessContextualizedType(apiSections.FIND_PATIENTS_SECTION):
      return merge({}, state, _defineProperty({}, apiSections.FIND_PATIENTS_SECTION, {
        Patients: _objectSpread({}, checkTD05NameNonRenseigne(action.data))
      }));
    case dmpCommandStartContextualizedType(apiSections.VITALE_CARD_SECTION):
    case dmpCommandStartContextualizedType(apiSections.GET_APCV_CONTEXT):
    case dmpCommandStartContextualizedType(apiSections.GET_NFC_APCV_USER_DATA):
      return merge({}, state, _defineProperty({}, apiSections.VITALE_DATA_SECTION, {
        loading: true
      }));
    case dmpCommandFailureContextualizedType(apiSections.VITALE_CARD_SECTION):
    case dmpCommandFailureContextualizedType(apiSections.GET_APCV_CONTEXT):
    case dmpCommandFailureContextualizedType(apiSections.GET_NFC_APCV_USER_DATA):
      var noCardError = action.data && action.data.s_apiErrorExtendedInformations && action.data.s_apiErrorExtendedInformations.indexOf('SCARD_W_REMOVED_CARD') !== -1;
      if (!noCardError) {
        return merge({}, state, getFailureState(_objectSpread({}, action, {
          context: _objectSpread({}, action.context, {
            section: apiSections.VITALE_DATA_SECTION
          })
        })));
      } else {
        return merge({}, state, _defineProperty({}, apiSections.VITALE_DATA_SECTION, {
          loading: false
        }));
      }
    case dmpCommandSuccessContextualizedType(apiSections.VITALE_CARD_SECTION):
      return merge({}, state, vitaleCardSuccessToVitaleData(action));
    case dmpCommandSuccessContextualizedType(apiSections.GET_APCV_CONTEXT):
      var i_cpxCardType = state[apiSections.CPX_CARD_SECTION].i_cpxCardType;
      return merge({}, state, apCvContextSuccessToVitaleData(action, i_cpxCardType));
    case dmpCommandSuccessContextualizedType(apiSections.DP_GET_RECORD_STATUS):
      return updateDpSearchResults(state, action);
    case dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION):
      return mergeForceNewValues(state, getSuccessState(_objectSpread({}, action, {
        data: checkTD02NameNonRenseigne(action.data),
        context: _objectSpread({}, action.context, {
          section: apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION
        })
      })));
    case dmpRemoteCommandFailureContextualizedType(apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION):
      return mergeForceNewValues(state, getFailureState(_objectSpread({}, action, {
        context: _objectSpread({}, action.context, {
          section: apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION
        })
      })));
    default:
      return state;
  }
}