import { put, select } from 'redux-saga/effects';
import moment from 'moment';
import { setApCvConfiguration } from 'dmpconnectjsapp-base/actions';

const getApCvConfig = ({ dmpconnectApCvConfiguration }) => dmpconnectApCvConfiguration;
export const forceApCvActivationAfterStartDate = function* () {
  const apcvConfig = yield select(getApCvConfig);
  if (!apcvConfig.active && apcvConfig.startDate) {
    const today = moment.utc();
    const startDate = moment.utc(apcvConfig.startDate, 'YYYY-MM-DD');
    if (today.isSameOrAfter(startDate)) {
      yield put(setApCvConfiguration('active', true));
    }
  }
};
