import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import merge from 'lodash.merge';
import { dmpconnectConfigurationActionConstants } from '../constants';
var initialState = {
  active: false,
  idam: null,
  numAm: null,
  lpsName: null,
  lpsVersion: null,
  billingNumber: null
};
export function dmpconnectINSiConfiguration() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case dmpconnectConfigurationActionConstants.DMPC_RESET_INSI_CONFIGURATION_FROM_ENV:
      return action.payload;
    case dmpconnectConfigurationActionConstants.DMPC_SET_INSI_CONFIGURATION:
      return merge({}, state, _defineProperty({}, action.key, action.value));
    default:
      return state;
  }
}
export default function getDmpconnectINSiConfigReducer(_ref) {
  var active = _ref.active,
    idam = _ref.idam,
    numAm = _ref.numAm,
    lpsName = _ref.lpsName,
    lpsVersion = _ref.lpsVersion,
    billingNumber = _ref.billingNumber;
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      active: active,
      idam: idam,
      numAm: numAm,
      lpsName: lpsName,
      lpsVersion: lpsVersion,
      billingNumber: billingNumber
    };
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return dmpconnectINSiConfiguration(state, action);
  };
}