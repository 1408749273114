import env from '../envVariables';


export const connectorConfigFromEnv = {
  sessionTimeout:     Number(env.REACT_APP_SESSION_TIMEOUT),
  applicationId:      env.REACT_APP_ID,
  production_mode:    Number(env.REACT_APP_PRODUCTON_MODE) === 1,
  dmpcjsProtocol:     env.REACT_APP_DMPCONNECTJS_PROTOCOL,
  dmpcjsHttpProtocol: env.REACT_APP_DMPCONNECTJS_HTTPPROTOCOL,
  dmpcjsHost:         env.REACT_APP_DMPCONNECTJS_HOST,
  dmpcjsPort:         env.REACT_APP_DMPCONNECTJS_PORT,
  dmpcjsVersion:      env.REACT_APP_DMPCONNECTJS_VERSION,

  apiType: env.REACT_APP_API_TYPE,

  dcparams: env.REACT_APP_DCPARAMS,

  tlsiServerName: env.REACT_APP_TLSI_SERVER_NAME,

  licensingGroupId: env.REACT_APP_LICENSING_GROUP_ID,
};

export const persistedConnectorConfigFromEnv = {
  authBearerUseExternalJWT: Number(env.REACT_APP_AUTH_BEARER_USE_EXTERNAL_JWT || env.REACT_APP_GENERATED_CONNECTOR_JWT) === 1,
  authBearerUseJWTLogin:    Number(env.REACT_APP_AUTH_BEARER_USE_JWT_LOGIN_TOKEN) === 1,
  esRestVitale:             Number(env.REACT_APP_ES_REST_ENABLE_VITALE_CARD_READING) === 1,
};


export const tseConfigFromEnv = {
  active: Number(env.REACT_APP_TSE) === 1,
};


export const insiConfigFromEnv = {
  active:     Number(env.REACT_APP_INSI) === 1,
  idam:       env.REACT_APP_INSI_ID_AM,
  numAm:      env.REACT_APP_INSI_NUM_AM,
  lpsName:    env.REACT_APP_INSI_LPS_NAME,
  lpsVersion: env.REACT_APP_INSI_LPS_VERSION,
};

export const apCvConfigFromEnv = {
  active:     Number(env.REACT_APP_APCV) === 1,
  serverName: env.REACT_APP_APCV_SERVER_NAME,
  idam:       env.REACT_APP_APCV_ID_AM,
  numAm:      env.REACT_APP_APCV_NUM_AM,
  lpsName:    env.REACT_APP_APCV_LPS_NAME,
  lpsVersion: env.REACT_APP_APCV_LPS_VERSION,
  startDate:  env.REACT_APP_APCV_START_DATE,
};
