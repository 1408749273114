/* Middlewares */
import { routerMiddleware }                      from 'connected-react-router';
import { createBrowserHistory }                  from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger }                          from 'redux-logger';
import { persistStore }                          from 'redux-persist';
import createSagaMiddleware                      from 'redux-saga';
import thunkMiddleware                           from 'redux-thunk';
import env                                       from './envVariables';
import createPersistReducer                      from './reducers';


export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();
// eslint-disable-next-line no-underscore-dangle
export const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// export const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;

export default function configureStore() {
  let middlewares = [routerMiddleware(history), thunkMiddleware, sagaMiddleware];
  if (env.NODE_ENV !== 'production') {
    const loggerMiddleware = createLogger();
    middlewares = [...middlewares, loggerMiddleware];
  }

  const persistedReducer = createPersistReducer(history);

  const store = createStore(
    persistedReducer, // root reducer with router state
    {},
    composeEnhancers(
      applyMiddleware(
        ...middlewares,
      ),
    ),
  );
  const persistor = persistStore(store);
  return { store, persistor, persistedReducer };
}
