import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
import _objectWithoutProperties from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
export var checkTD02NameNonRenseigne = function checkTD02NameNonRenseigne(directAuthData) {
  if (!directAuthData) {
    return {};
  }
  var _directAuthData$Exist = directAuthData.ExistingTestAnswer,
    _directAuthData$Exist2 = _directAuthData$Exist === void 0 ? {} : _directAuthData$Exist,
    _directAuthData$Exist3 = _directAuthData$Exist2.AdminData,
    _directAuthData$Exist4 = _directAuthData$Exist3 === void 0 ? {} : _directAuthData$Exist3,
    patientName = _directAuthData$Exist4.s_patientName,
    restAdmin = _objectWithoutProperties(_directAuthData$Exist4, ["s_patientName"]),
    restTest = _objectWithoutProperties(_directAuthData$Exist2, ["AdminData"]),
    restData = _objectWithoutProperties(directAuthData, ["ExistingTestAnswer"]);
  return _objectSpread({
    isENS: ['NON RENSEIGNE', 'NON_RENSEIGNE'].includes(patientName),
    ExistingTestAnswer: _objectSpread({
      AdminData: _objectSpread({
        s_patientName: !['NON RENSEIGNE', 'NON_RENSEIGNE'].includes(patientName) ? patientName : ''
      }, restAdmin)
    }, restTest)
  }, restData);
};
export var checkTD05NameNonRenseigne = function checkTD05NameNonRenseigne(findPatientData) {
  if (!findPatientData) {
    return {};
  }
  var _findPatientData$Pati = findPatientData.Patients,
    Patients = _findPatientData$Pati === void 0 ? [] : _findPatientData$Pati;
  return Patients.map(function (p) {
    var _p$CivilStatus = p.CivilStatus,
      s_name = _p$CivilStatus.s_name,
      restCivilStatus = _objectWithoutProperties(_p$CivilStatus, ["s_name"]),
      restPatient = _objectWithoutProperties(p, ["CivilStatus"]);
    return _objectSpread({
      CivilStatus: _objectSpread({
        s_name: !['NON RENSEIGNE', 'NON_RENSEIGNE'].includes(s_name) ? s_name : ''
      }, restCivilStatus)
    }, restPatient);
  });
};
export var checkTD13NameNonRenseigne = function checkTD13NameNonRenseigne(adminData) {
  if (!adminData) {
    return {};
  }
  var _adminData$PatientDat = adminData.PatientData,
    _adminData$PatientDat2 = _adminData$PatientDat === void 0 ? {} : _adminData$PatientDat,
    _adminData$PatientDat3 = _adminData$PatientDat2.ExtendedCivilStatus,
    _adminData$PatientDat4 = _adminData$PatientDat3 === void 0 ? {} : _adminData$PatientDat3,
    _adminData$PatientDat5 = _adminData$PatientDat4.CivilStatus,
    _adminData$PatientDat6 = _adminData$PatientDat5 === void 0 ? {} : _adminData$PatientDat5,
    name = _adminData$PatientDat6.s_name,
    restCivilStatus = _objectWithoutProperties(_adminData$PatientDat6, ["s_name"]),
    birthName = _adminData$PatientDat4.s_birthName,
    restExtendedCivilStatus = _objectWithoutProperties(_adminData$PatientDat4, ["CivilStatus", "s_birthName"]),
    restPatientData = _objectWithoutProperties(_adminData$PatientDat2, ["ExtendedCivilStatus"]);
  return {
    PatientData: _objectSpread({
      ExtendedCivilStatus: _objectSpread({
        CivilStatus: _objectSpread({
          s_name: !['NON RENSEIGNE', 'NON_RENSEIGNE'].includes(name) ? name : ''
        }, restCivilStatus)
      }, restExtendedCivilStatus)
    }, restPatientData)
  };
};