import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
export var API_TYPES = {
  WS: 'WS',
  REST: 'REST',
  JSON: 'JSON',
  JSPOST: 'JSPOST'
};
export var cpxCardTypes = {
  PROD: 1,
  DEMO: 2
};

/**
 * @type {{NormalAccess: number, BrisDeGlace: number, Centre15: number}}
 */
export var accessModes = {
  NormalAccess: 1,
  BrisDeGlace: 2,
  Centre15: 3
};
export var disabledTypes = ['EXPPAT_1', 'EXPPAT_2', 'REMB'];
export var disabledOpCodes = ['EXP_PATIENT'];
export var dmpconnectActionConstants = {
  DMPC_REMOTE_SEND_COMMAND: 'DMPC_REMOTE_SEND_COMMAND',
  DMPC_REMOTE_COMMAND_SUCCESS: 'DMPC_REMOTE_COMMAND_SUCCESS',
  DMPC_REMOTE_COMMAND_FAILURE: 'DMPC_REMOTE_COMMAND_FAILURE',
  DMPC_CANCEL_COMMAND: 'DMPC_CANCEL_COMMAND',
  DMPC_SEND_COMMAND: 'DMPC_SEND_COMMAND',
  DMPC_SEND_REST_COMMAND: 'DMPC_SEND_REST_COMMAND',
  DMPC_SEND_JSON_COMMAND: 'DMPC_SEND_JSON_COMMAND',
  DMPC_SEND_SYNCHRONOUS_COMMAND: 'DMPC_SEND_SYNCHRONOUS_COMMAND',
  DMPC_SEND_REST_SYNCHRONOUS_COMMAND: 'DMPC_SEND_REST_SYNCHRONOUS_COMMAND',
  DMPC_SEND_JSON_SYNCHRONOUS_COMMAND: 'DMPC_SEND_JSON_SYNCHRONOUS_COMMAND',
  DMPC_SEND_HTTP_COMMAND: 'DMPC_SEND_HTTP_COMMAND',
  DMPC_SEND_HTTP_SYNCHRONOUS_COMMAND: 'DMPC_SEND_HTTP_SYNCHRONOUS_COMMAND',
  DMPC_COMMAND_SUCCESS: 'DMPC_COMMAND_SUCCESS',
  DMPC_COMMAND_FAILURE: 'DMPC_COMMAND_FAILURE',
  DMPC_COMMAND_TOO_LONG: 'DMPC_COMMAND_TOO_LONG',
  DMPC_COMMAND_STOP: 'DMPC_COMMAND_STOP',
  DMPC_SYNCHRONOUS_COMMAND_SUCCESS: 'DMPC_SYNCHRONOUS_COMMAND_SUCCESS',
  DMPC_SYNCHRONOUS_COMMAND_FAILURE: 'DMPC_SYNCHRONOUS_COMMAND_FAILURE',
  DMPC_CLEAR_DATA: 'DMPC_CLEAR_DATA',
  DMPC_CLEAR_SECTION: 'DMPC_CLEAR_SECTION',
  DMPC_CLEAR_SUB_SECTION: 'DMPC_CLEAR_SUB_SECTION',
  DMPC_CLEAR_SESSION: 'DMPC_CLEAR_SESSION',
  DMPC_SELECT_INS: 'DMPC_SELECT_INS',
  DMPC_SELECT_CERTIFIED_ID: 'DMPC_SELECT_CERTIFIED_ID',
  DMPC_SELECT_CERTIFIEDdd_ID: 'DMPC_SELECT_CERTIFIEddD_ID',
  DMPC_SELECT_DMP: 'DMPC_SELECT_DMP',
  DMPC_READ_CPX_PROCESS: 'DMPC_READ_CPX_PROCESS',
  DMPC_CHECK_LICENCE_PROCESS: 'DMPC_CHECK_LICENCE_PROCESS',
  DMPC_REQUEST_UPDATE_USER_DMP_ACCESS_AUTHORIZATION: 'DMPC_REQUEST_UPDATE_USER_DMP_ACCESS_AUTHORIZATION',
  DMPC_CREATE_DMP: 'DMPC_CREATE_DMP',
  DMPC_CREATE_DMP_AND_OTP: 'DMPC_CREATE_DMP_AND_OTP',
  DMPC_EDIT_DMP: 'DMPC_EDIT_DMP',
  DMPC_CLOSE_DMP: 'DMPC_CLOSE_DMP',
  DMPC_REACTIVATE_DMP: 'DMPC_REACTIVATE_DMP',
  DMPC_MANAGE_DMP_PROCESS: 'DMPC_MANAGE_DMP_PROCESS',
  DMPC_GET_DMP_AUTHORIZATIONS_LIST: 'DMPC_GET_DMP_AUTHORIZATIONS_LIST',
  DMPC_GET_WEB_PS_URLS: 'DMPC_GET_WEB_PS_URLS',
  DMPC_REMOVE_DOC_FROM_PERISTANT_DATA: 'DMPC_REMOVE_DOC_FROM_PERISTANT_DATA',
  DMPC_SEND_DOC_TO_PERISTANT_DATA: 'DMPC_SEND_DOC_TO_PERISTANT_DATA',
  DMPC_CLEAR_PERSISTANT_DATA_FROM_LOCALSTORAGE: 'DMPC_CLEAR_PERSISTANT_DATA_FROM_LOCALSTORAGE',
  DMPC_GET_PERSISTANT_DATA_FROM_LOCALSTORAGE: 'DMPC_GET_PERSISTANT_DATA_FROM_LOCALSTORAGE',
  DMPC_SET_PERSISTANT_DATA_TO_LOCALSTORAGE: 'DMPC_SET_PERSISTANT_DATA_TO_LOCALSTORAGE',
  DMPC_REQUES_PCSC_READERS: 'DMPC_REQUES_PCSC_READERS',
  DMPC_GET_REST_PCSC_READERS: 'DMPC_GET_REST_PCSC_READERS',
  DMPC_INIT_ALD: 'DMPC_INIT_ALD',
  DMPC_INIT_ALD_OK: 'DMPC_INIT_ALD_OK'
};
export var dmpconnectInitActionConstants = {
  DMPC_LOGIN: 'DMPC_LOGIN',
  DMPC_LOGOUT: 'DMPC_LOGOUT',
  DMPC_LOGIN_SUCCESS: 'DMPC_LOGIN_SUCCESS',
  DMPC_LOGOUT_SUCCESS: 'DMPC_LOGOUT_SUCCESS',
  DMPC_SET_FORCE_SECRET_CONNEXION: 'DMPC_SET_FORCE_SECRET_CONNEXION',
  DMPC_DCPARAMS_UNREGISTERED: 'DMPC_DCPARAMS_UNREGISTERED',
  DMPC_DCPARAMS_REGISTERED: 'DMPC_DCPARAMS_REGISTERED',
  DMPC_INIT_APPLICATION: 'DMPC_INIT_APPLICATION',
  WS_INIT_LOADING: 'WS_INIT_LOADING',
  // Init WS in progress when using REST (esRestVitale)
  WS_INIT_FAILED: 'WS_INIT_FAILED',
  // Init WS failed when using REST (esRestVitale)
  DMPC_RESET_APPLICATION: 'DMPC_RESET_APPLICATION',
  DMPC_INIT_APPLICATION_FAILURE: 'DMPC_INIT_APPLICATION_FAILURE',
  DMPC_INIT_APPLICATION_SUCCESS: 'DMPC_INIT_APPLICATION_SUCCESS',
  DMPC_SET_MODAL_ERROR: 'DMPC_SET_MODAL_ERROR',
  DMPC_CLEAR_MODAL_ERROR: 'DMPC_CLEAR_MODAL_ERROR',
  DMPC_SET_DMP_SEARCH_PANEL_OPENED: 'DMPC_SET_DMP_SEARCH_PANEL_OPENED',
  DMPC_CLOSE_MANAGE_DMP_MODAL: 'DMPC_CLOSE_MANAGE_DMP_MODAL',
  DMPC_SET_INTEROP_CODES: 'DMPC_SET_INTEROP_CODES',
  DMPC_SET_DMP_PARAMETERS: 'DMPC_SET_DMP_PARAMETERS',
  DMPC_SET_NOS_CODES: 'DMPC_SET_NOS_CODES',
  DMPC_SET_CISIS_CODES: 'DMPC_SET_CISIS_CODES',
  DMPC_SET_ATC_IMMUNIZATION_CODES: 'DMPC_SET_ATC_IMMUNIZATION_CODES',
  DMPC_SET_CONNECTOR_OK: 'DMPC_SET_CONNECTOR_OK',
  DMPC_SET_CONNECTOR_KO: 'DMPC_SET_CONNECTOR_KO',
  DMPC_SET_CONNECTOR_CONFIG_OK: 'DMPC_SET_CONNECTOR_CONFIG_OK',
  DMPC_SET_CONNECTOR_CONFIG_KO: 'DMPC_SET_CONNECTOR_CONFIG_KO',
  DMPC_SET_SESSION_OK: 'DMPC_SET_SESSION_OK',
  DMPC_SET_SESSION_KO: 'DMPC_SET_SESSION_KO',
  DMPC_SET_CONNECTOR_VERSION_KO: 'DMPC_SET_CONNECTOR_VERSION_KO',
  DMPC_SET_LICENSE_STATUS: 'DMPC_SET_LICENSE_STATUS',
  DMPC_START_VIRTUAL_PRINTER: 'DMPC_START_VIRTUAL_PRINTER',
  DMPC_DASHBOARD_INIT: 'DMPC_DASHBOARD_INIT',
  DMPC_DASHBOARD_INIT_OK: 'DMPC_DASHBOARD_INIT_OK',
  DMPC_CHECK_CONNECTOR: 'DMPC_CHECK_CONNECTOR',
  DMPC_LICENSE_CHECK: 'DMPC_LICENSE_CHECK',
  DMPC_LICENSE_AFTER_FORM_OK: 'DMPC_LICENSE_AFTER_FORM_OK',
  DMPC_LICENSE_CHECK_LOADING: 'DMPC_LICENSE_CHECK_LOADING'
};
export var dmpconnectConfigurationActionConstants = {
  DMPC_SET_GLOBAL_CONFIGURATION: 'DMPC_SET_GLOBAL_CONFIGURATION',
  DMPC_RESET_GLOBAL_CONFIGURATION_FROM_ENV: 'DMPC_RESET_GLOBAL_CONFIGURATION_FROM_ENV',
  DMPC_SET_USER_CONFIGURATION: 'DMPC_SET_USER_CONFIGURATION',
  DMPC_SET_ES_CONFIGURATION: 'DMPC_SET_ES_CONFIGURATION',
  DMPC_RESET_ES_CONFIGURATION_FROM_ENV: 'DMPC_RESET_ES_CONFIGURATION_FROM_ENV',
  DMPC_SET_DP_CONFIGURATION: 'DMPC_SET_DP_CONFIGURATION',
  DMPC_RESET_DP_CONFIGURATION_FROM_ENV: 'DMPC_RESET_DP_CONFIGURATION_FROM_ENV',
  DMPC_SET_INSI_CONFIGURATION: 'DMPC_SET_INSI_CONFIGURATION',
  DMPC_RESET_INSI_CONFIGURATION_FROM_ENV: 'DMPC_RESET_INSI_CONFIGURATION_FROM_ENV',
  DMPC_SET_ALDI_CONFIGURATION: 'DMPC_SET_ALDI_CONFIGURATION',
  DMPC_RESET_ALDI_CONFIGURATION_FROM_ENV: 'DMPC_RESET_ALDI_CONFIGURATION_FROM_ENV',
  DMPC_SET_TSE_CONFIGURATION: 'DMPC_SET_TSE_CONFIGURATION',
  DMPC_SET_PERSIST_CONNECTOR_CONFIGURATION: 'DMPC_SET_PERSIST_CONNECTOR_CONFIGURATION',
  DMPC_SET_APCV_CONFIGURATION: 'DMPC_SET_APCV_CONFIGURATION',
  DMPC_RESET_APCV_CONFIGURATION_FROM_ENV: 'DMPC_RESET_APCV_CONFIGURATION_FROM_ENV',
  DMPC_SET_MSS_CONFIGURATION: 'DMPC_SET_MSS_CONFIGURATION',
  DMPC_RESET_MSS_CONFIGURATION_FROM_ENV: 'DMPC_RESET_MSS_CONFIGURATION_FROM_ENV'
};
export var dmpconnectVirtualPrinterActionConstants = {
  DMPC_VIRTUAL_PRINTER_SEND_COMMAND: 'DMPC_VIRTUAL_PRINTER_SEND_COMMAND',
  DMPC_VIRTUAL_PRINTER_COMMAND_SUCCESS: 'DMPC_VIRTUAL_PRINTER_COMMAND_SUCCESS',
  DMPC_VIRTUAL_PRINTER_COMMAND_FAILURE: 'DMPC_VIRTUAL_PRINTER_COMMAND_FAILURE',
  DMPC_VIRTUAL_PRINTER_CLEAR_SECTION: 'DMPC_VIRTUAL_PRINTER_CLEAR_SECTION',
  DMPC_VIRTUAL_PRINTER_MONITORING_START: 'DMPC_VIRTUAL_PRINTER_MONITORING_START',
  DMPC_VIRTUAL_PRINTER_MONITORING_STOP: 'DMPC_VIRTUAL_PRINTER_MONITORING_STOP',
  DMPC_VIRTUAL_PRINTER_MONITORING_RECEIVED_FILE: 'DMPC_VIRTUAL_PRINTER_MONITORING_RECEIVED_FILE',
  DMPC_VIRTUAL_PRINTER_GET_FILES: 'DMPC_VIRTUAL_PRINTER_GET_FILES',
  DMPC_VIRTUAL_PRINTER_FLUSH_FILES: 'DMPC_VIRTUAL_PRINTER_FLUSH_FILES',
  DMPC_VIRTUAL_PRINTER_TOGGLE_OVERLAY: 'DMPC_VIRTUAL_PRINTER_TOGGLE_OVERLAY',
  DMPC_VIRTUAL_PRINTER_SEND_DOCUMENT: 'DMPC_VIRTUAL_PRINTER_SEND_DOCUMENT',
  DMPC_VIRTUAL_PRINTER_SELECT_INS: 'DMPC_VIRTUAL_PRINTER_SELECT_INS'
};
export var dmpconnectMonitoringActionConstants = {
  DMPC_VITALE_MONITORING_START: 'DMPC_VITALE_MONITORING_START',
  DMPC_VITALE_MONITORING_STOP: 'DMPC_VITALE_MONITORING_STOP',
  DMPC_VITALE_MONITORING_UPDATE: 'DMPC_VITALE_MONITORING_UPDATE',
  DMPC_CPX_MONITORING_START: 'DMPC_CPX_MONITORING_START',
  DMPC_CPX_MONITORING_STOP: 'DMPC_CPX_MONITORING_STOP',
  DMPC_CPX_MONITORING_UPDATE: 'DMPC_CPX_MONITORING_UPDATE'
};
export var mssActionConstants = {
  EMPTY_ALL_MSS_MESSAGES: 'EMPTY_ALL_MSS_MESSAGES',
  SET_MSS_MESSAGES_API_TYPE: 'SET_MSS_MESSAGES_API_TYPE',
  IMAP_SYNC_MESSAGES: 'IMAP_SYNC_MESSAGES',
  SEND_WEB_EMAIL: 'SEND_WEB_EMAIL',
  SET_ATTACHMENT_DOWNLOADED: 'SET_ATTACHMENT_DOWNLOADED',
  SET_MESSAGE_CONTENT: 'SET_MESSAGE_CONTENT',
  SET_MSS_ACCOUNT_ACTIVE: 'SET_MSS_ACCOUNT_ACTIVE',
  ADD_MSS_ACCOUNT: 'ADD_MSS_ACCOUNT',
  UPDATE_MSS_ACCOUNT: 'UPDATE_MSS_ACCOUNT',
  DELETE_MSS_ACCOUNT: 'DELETE_MSS_ACCOUNT'
};
export var apiSections = {
  GET_ACTIVE_FEATURES: 'getActiveFeatures',
  GET_TIME_SYNCHRONIZATION_DIFFERENCE: 'getTimeSynchronizationDifference',
  ADMINISTRATIVE_DATA_SECTION: 'administrativeData',
  CREATE_DMP_SECTION: 'createDMP',
  EDIT_DMP_SECTION: 'editDMP',
  CLOSE_DMP_SECTION: 'closeDMP',
  REACTIVATE_DMP_SECTION: 'reactivateDMP',
  AUTHORIZATIONS_LIST_SECTION: 'authorizationsList',
  GET_PATIENT_WEB_PDF_SECTION: 'patientWebAccessPdf',
  GET_PATIENT_ACK_PDF_SECTION: 'patientAcknowledgementPdf',
  VITALE_DATA_SECTION: 'vitaleData',
  VITALE_CARD_SECTION: 'vitaleCard',
  VITALE_SECTION: 'vitale',
  VITALE_XML_CONTENT_SECTION: 'vitaleXmlContent',
  USER_DMP_ACCESS_AUTHORIZATION_SECTION: 'userDmpAccessAuthorization',
  DMP_ACCESS_MODE_SECTION: 'dmpAccessMode',
  DOCUMENT_CONTENT_SECTION: 'documentContent',
  SEND_DOCUMENT_SECTION: 'sendDocument',
  DELETE_DOCUMENT_SECTION: 'deleteDocument',
  DELETE_ARCHIVED_DOCUMENT_SECTION: 'deleteArchivedDocument',
  UPDATE_DOCUMENT_STATUS: 'updateDocumentStatus',
  GET_SUBMISSION_SET_DOCUMENTS: 'getSubmissionSetDocuments',
  GET_SUBMISSION_SET_ID: 'getSubmissionSetId',
  CERTIFIFIED_IDENTITY_SECTION: 'certifiedIdentity',
  DIRECT_AUTHENTICATION_DMP_STATUS_SECTION: 'directAuthenticationDMPStatus',
  OBJECT_PARAMETERS_SECTION: 'objectParameters',
  UPDATE_DOCUMENT_STATUS_SECTION: 'updateDocumentStatus',
  FIND_DOCUMENTS_SECTION: 'findDocuments',
  FIND_DOCUMENTS_IMAGERY_SECTION: 'findDocumentsImagery',
  FIND_DOCUMENTS_REFERENCED_SECTION: 'findDocumentsRef',
  GET_DOCUMENTS_IMAGERY_SECTION: 'getDocumentsImagery',
  FIND_HR_SECTION: 'findHR',
  FIND_VSM_SECTION: 'findVSM',
  FIND_PATIENTS_SECTION: 'findPatients',
  ACCESSIBLE_DMP_LIST_SECTION: 'accessibleDMPList',
  ACCESSIBLE_CPE_OWNER_DMP_LIST_SECTION: 'accessibleDMPListCPEOwner',
  SET_PRACTICE_LOCATION_SECTION: 'setPracticeLocation',
  CONNECTOR_SECTION: 'connector',
  TLSI_CONNECTOR_SECTION: 'tlsiConnector',
  DP_CONNECTOR_SECTION: 'dpConnector',
  APCV_CONNECTOR_SECTION: 'apCvConnector',
  PCSC_READERS_SECTION: 'pcscReaders',
  CPX_CARD_SECTION: 'cpxCard',
  CPX_STATUS_SECTION: 'cpxStatus',
  CPX_HP_INFOS_SECTION: 'cpxHpInfos',
  CPX_SECTION: 'cpx',
  SESSION_STATE_SECTION: 'sessionState',
  SESSION_EXISTS_SECTION: 'sessionExists',
  SESSION_SECTION: 'session',
  REGISTER_DC_PARAMS: 'REGISTER_DC_PARAMS',
  UNREGISTER_DC_PARAMS: 'UNREGISTER_DC_PARAMS',
  IS_REGISTERED_DC_PARAMS: 'IS_REGISTERED_DC_PARAMS',
  CONFIDENTIALITY_LEVEL_SECTION: 'confidentialityLevel',
  CONFIDENTIALITY_LEVEL_SECTION_REST: 'confidentialityLevelRest',
  UPDATE_DOCUMENT_VISIBILITY_SECTION: 'updateDocumentVisibility',
  WEB_PS_URLS: 'webPsUrls',
  OTP_EMAIL_VALUE: 'otpEmailValue',
  OTP_MOBILE_VALUE: 'optMobileValue',
  INSC_TO_NIR: 'convert_insc_to_nir',
  INTEROPCODES: 'interOpCodes',
  NOS_CODES: 'nosCodes',
  CISIS_CODES: 'ciSisCodes',
  CIS_CODES: 'cisCodes',
  ATC_IMMUNIZATION_CODES: 'atcImmunizationCodes',
  DMP_PARAMETERS: 'dmpParameters',
  CHECK_LICENSE_USER_RIGHT: 'checkUserLicenseRight',
  ACCESSIBLE_DMP_LIST_CACHE: 'cache_accessible_dmp_list',
  REMOTE_CONTROL_STATUS: 'remoteControlStatus',
  START_REMOTE_CONTROL: 'startRemoteControl',
  STOP_REMOTE_CONTROL: 'stopRemoteControl',
  REMOTE_CONTROL_REQUEST_RESPONSE_SECTION: 'remoteRequestResponse',
  REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION: 'directAuthenticationDMPStatus',
  REMOTE_FIND_PATIENTS_SECTION: 'findPatientsRemote',
  REMOTE_EXPORT_DOCUMENT_SECTION: 'remoteExportDoc',
  REMOTE_OPEN_OR_CREATE_DMP: 'openOrCreateDMP',
  REMOTE_CREATE_DMP: 'remoteCreateDMP',
  REMOTE_REACTIVATE_DMP: 'remoteReactivateDMP',
  REMOTE_GET_PATIENT_WEB_PDF: 'remotePatientWebAccessPdf',
  REMOTE_GET_PATIENT_ACK_PDF: 'remotePatientAcknowledgementPdf',
  REMOTE_SEND_DOCUMENT: 'remoteSendDocument',
  REMOTE_CERTIFIFIED_IDENTITY_SECTION: 'remoteCertifiedIdentity',
  REMOTE_CLOSE_DMP_SESSION: 'remoteCloseDmpSession',
  REMOTE_LOGIN_NOTIFICATION: 'remoteLoginNotification',
  REMOTE_USER_DMP_ACCESS_AUTHORIZATION_SECTION: 'remoteUserDmpAccessAuthorization',
  REMOTE_SUBMIT_DOCUMENT: 'submitDocument',
  REMOTE_DELETE_DOCUMENT: 'deleteDocument',
  REMOTE_VALIDATE_DOCUMENT: 'validateDocument',
  VIRTUAL_PRINTER_FILES_SECTION: 'printedFiles',
  VIRTUAL_PRINTER_SHOW_OVERLAY: 'showVirtualPrinterOverlay',
  VIRTUAL_PRINTER_NB_FILES: 'printedFilesCount',
  CPX_CARD_TYPE_SECTION: 'cpxCardType',
  SET_PERSISTANT_DATA: 'set_persistant_data',
  GET_PERSISTANT_DATA: 'get_persistant_data',
  CLEAR_PERSISTANT_DATA: 'clear_persistant_data',
  PERSISTANT_DATA_LS: 'persistantDataLS',
  ALD_FROM_VITALE_CARD: 'getALD',
  INS_FROM_VITALE_CARD: 'getINS',
  INSI_CHECK_IDENTITY: 'checkIdentity',
  RELOAD_PP_CERTS: 'reloadPpCerts',
  ADD_TO_DP: 'addToDp',
  GET_DP: 'getDp',
  GET_NDP: 'getNdp',
  DP_GET_RECORD_STATUS: 'dpGetRecordStatus',
  DP_SEARCH_RECORD: 'dpSearchRecord',
  DP_ADD_UPDATE_RECORD: 'dpAddUpdateRecord',
  DP_DELETE_RECORD: 'dpDeleteRecord',
  MSS_PERFORM_AUTHENTICATION: 'performMssAuthentication',
  MSS_GET_HP_INFOS: 'getMssHpInfos',
  MSS_GET_EMAIL: 'getEmailFromMssWS',
  MSS_SEND_SMTP_EMAIL: 'sendMssSmtpEmail',
  MSS_GENERATE_ATTACHMENTS: 'generateMssAttachments',
  MSS_INIT_OTP_AUTH: 'initiateMssOtpAuthentication',
  MSS_FINALIZE_OTP_AUTH: 'finalizeMssOtpAuthentication',
  MSS_SEND_REQUEST: 'sendMssRequest',
  MSS_GET_FOLDERS: 'sendMssFoldersRequest',
  MSS_CREATE_FOLDERS: 'createMssFolder',
  MSS_DELETE_FOLDERS: 'deleteMssFolder',
  MSS_RENAME_FOLDERS: 'renameMssFolder',
  MSS_SEARCH_MESSAGES: 'sendMssSearchMessagesRequest',
  MSS_SYNC_MESSAGES: 'sendMssSyncMessagesRequest',
  MSS_UPDATE_MESSAGE: 'sendMssUpdateMessageRequest',
  MSS_DOWNLOAD_ATTACHMENT: 'sendMssDownloadAttachmentRequest',
  MSS_GET_MESSAGE_CONTENT: 'getMssMessageContent',
  MSS_MOVE_MESSAGES: 'moveMssMessages',
  MSS_DELETE_MESSAGES: 'deleteMssMessages',
  MSS_FULL_SEARCH_MESSAGES: 'fullSearchMssMessages',
  GET_DOCS_FROM_IHE_XDM: 'getDocsFromIheXdm',
  MSS_IMAP_APPEND_MESSAGE: 'mssImapAppendMessage',
  MSS_SET_IMAP_CERT: 'mssSetImapCert',
  MSS_SET_SMTP_CERT: 'mssSetSmtpCert',
  MSS_SET_WS_CERT: 'mssSetWsCert',
  GET_USER_INFOS: 'getUserInfos',
  GET_ES_INFOS: 'getEsInfos',
  EXPORT_CDA_DOCUMENT: 'exportCdaDocument',
  GET_NFC_CARD: 'GetNfcCard',
  GET_NFC_APCV_USER_DATA: 'getNfcApCvUserData',
  GET_APCV_CONTEXT: 'getApCvContext',
  RELEASE_APCV_CONTEXT: 'releaseApCvContext',
  GET_LOG_LEVELS: 'getLogLevels',
  SET_LOG_LEVELS: 'setLogLevels',
  GET_LOG_TAIL: 'getLogTail',
  GET_SESSION_LOG_TAIL: 'getSessionLogTail',
  GET_SYSTEM_INFO: 'getSystemInformation',
  GET_TD010_VIHF: 'getTd010Vihf',
  FHIR_SEARCH: 'FHIR_SEARCH',
  FHIR_SEARCH_STRUCTURE_ALL_HP: 'FHIR_SEARCH_STRUCTURE_ALL_HP',
  AUTHENTICATE_CIBA: 'AUTHENTICATE_CIBA'
};
export var cardStatuses = {
  NotInitialized: 0,
  ValidCardInserted: 1,
  NoCardInserted: 2,
  UnknownCardInserted: 3,
  CardStatusChanged: 4,
  CardIsBlocked: 5,
  CardIsExpired: 6,
  CpxReaderError: 7
};
export var vitaleCardStatuses = {
  MISSING_CARD: 1,
  REAL_CARD: 3,
  TEST_CARD: 4,
  DEMO_CARD: 5
};
export var vitaleCardStatusesText = _defineProperty(_defineProperty(_defineProperty({}, vitaleCardStatuses.REAL_CARD, 'réelle'), vitaleCardStatuses.TEST_CARD, 'de test'), vitaleCardStatuses.DEMO_CARD, 'de démonstration');
export var cpxCardTypesText = _defineProperty(_defineProperty({}, cpxCardTypes.PROD, 'réelle'), cpxCardTypes.DEMO, 'de démonstration');
export var insTypes = {
  DEMO_INS: 'D',
  TEST_INS: 'T',
  REAL_INS: 'N'
};
export var insTypeToVitaleCardStatus = _defineProperty(_defineProperty(_defineProperty({}, insTypes.DEMO_INS, vitaleCardStatuses.DEMO_CARD), insTypes.REAL_INS, vitaleCardStatuses.REAL_CARD), insTypes.TEST_INS, vitaleCardStatuses.TEST_CARD);
export var userAuthenticationModes = {
  InternetProtocol: 1,
  InternetProtocolPassword: 2,
  Kerberos: 3,
  MobileOneFactorUnregistered: 4,
  MobileTwoFactorUnregistered: 5,
  MobileOneFactorContract: 6,
  MobileTwoFactorContract: 7,
  Password: 8,
  PasswordProtectedTransport: 9,
  PreviousSession: 10,
  PublicKeyX509: 11,
  PublicKeyPGP: 12,
  PublicKeySPKI: 13,
  PublicKeyXMLDigitalSignature: 14,
  Smartcard: 15,
  SmartcardPKI: 16,
  SoftwarePKI: 17,
  Telephony: 18,
  TelephonyNomadic: 19,
  TelephonyPersonalized: 20,
  TelephonyAuthenticated: 21,
  SecureRemotePassword: 22,
  SSL_TLSCertificateBasedClientAuthentication: 23,
  TimeSyncToken: 24,
  Unspecified: 25
};
export var userAuthenticationContexts = {
  AuthContext_CPS: 1,
  AuthContext_CARTE_ETS: 2,
  AuthContext_CPSSANSCONTACT: 3,
  AuthContext_OTP_SMS: 4,
  AuthContext_APP_MOBILE_AUTH: 5,
  AuthContext_APP_BROWSER_AUTH: 6,
  AuthContext_Unspecified: 100
};
export var availableAirStrongAuthModes = {
  CPS_PIN: {
    value: 1,
    label: 'Lecture CPS + Code PIN'
  },
  SMARTCARDPKI_PIN: {
    value: 2,
    label: 'Carte Etab sans contact + Code PIN'
  },
  PREVIOUSSESSION_CPSSANSCONTACT: {
    value: 3,
    label: 'Lecture CPS + Authentification sans contact',
    default: true
  },
  MOBILETWOFACTORCONTRACT_OTP: {
    value: 4,
    label: 'Login/Password + OTP'
  },
  MOBILETWOFACTORCONTRACT_MOBILE_AUTH: {
    value: 5,
    label: 'Login/Password + Code Pin mobile'
  },
  MOBILETWOFACTORCONTRACT_BROWSER_AUTH: {
    value: 6,
    label: 'Login/Password + Code Pin navigateur'
  },
  NONE: {
    value: 100,
    label: 'Aucune'
  }
};
export var vitaleCardMonitoringStatuses = {
  error: 1,
  newCard: 2,
  NoCard: 4,
  unknownCard: 5
};
export var mssReceiptNotificationTypes = {
  DSN: 'DSN',
  MDN: 'MDN'
};
export var mssLoginTypes = {
  CPX: 'CPX',
  OTP: 'OTP',
  CERT: 'CERT',
  PSC: 'PSC'
};
export var validateINSiOIDS = {
  NORMAL: '1.2.250.1.213.1.4.8',
  NIA: '1.2.250.1.213.1.4.9'
};
export var structureIdTypes = {
  ADELI: {
    value: 'ADELI',
    label: 'Identifiant cabinet ADELI',
    firstDigit: 0,
    length: 9,
    type: 'w' // alphanum
  },
  FINESS: {
    value: 'FINESS',
    label: 'FINESS',
    firstDigit: 1,
    length: 9,
    type: 'w' // alphanum
  },
  SIREN: {
    value: 'SIREN',
    label: 'SIREN',
    firstDigit: 2,
    length: 9,
    type: 'w' // alphanum
  },
  SIRET: {
    value: 'SIRET',
    label: 'SIRET',
    firstDigit: 3,
    length: 14,
    type: 'w' // alphanum
  },
  RPPS: {
    value: 'RPPS',
    label: 'Identifiant cabinet RPPS',
    firstDigit: 4,
    length: 14,
    type: 'd' // digits
  }
};
export var DmpcLogLevels = {
  DISABLED: 0,
  FATAL: 1,
  ERRORS: 2,
  WARNINGS: 3,
  INFOS: 4,
  DEV: 5
};
export var DmpcLogLevelsLabel = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DmpcLogLevels.DISABLED, 'Disabled'), DmpcLogLevels.FATAL, 'Fatal'), DmpcLogLevels.ERRORS, 'Errors'), DmpcLogLevels.WARNINGS, 'Warnings'), DmpcLogLevels.INFOS, 'Infos'), DmpcLogLevels.DEV, 'Dev');
export var DmpcJsLogLevel = {
  DISABLED: 0,
  FATAL: 1,
  CRITICAL: 2,
  ERRORS: 3,
  WARNINGS: 4,
  NOTICE: 5,
  INFOS: 6,
  DEBUG: 7,
  TRACE: 8
};
export var DmpcJsLogLevelLabel = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DmpcJsLogLevel.DISABLED, 'Disabled'), DmpcJsLogLevel.FATAL, 'Fatal'), DmpcJsLogLevel.CRITICAL, 'Critical'), DmpcJsLogLevel.ERRORS, 'Errors'), DmpcJsLogLevel.WARNINGS, 'Warnings'), DmpcJsLogLevel.NOTICE, 'Notice'), DmpcJsLogLevel.INFOS, 'Infos'), DmpcJsLogLevel.DEBUG, 'Debug'), DmpcJsLogLevel.TRACE, 'Trace');