import { dmpconnectInitActionConstants } from '../constants';
import { getSessionId } from '../helpers/accessors';
export var initApplication = function initApplication() {
  return function (dispatch, getState) {
    return dispatch({
      type: dmpconnectInitActionConstants.DMPC_INIT_APPLICATION,
      sessionId: getSessionId(getState())
    });
  };
};
export var initApplicationSuccess = function initApplicationSuccess() {
  return {
    type: dmpconnectInitActionConstants.DMPC_INIT_APPLICATION_SUCCESS
  };
};
export var initApplicationFailure = function initApplicationFailure() {
  var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return {
    type: dmpconnectInitActionConstants.DMPC_INIT_APPLICATION_FAILURE,
    error: error
  };
};
export var resetApplication = function resetApplication() {
  return {
    type: dmpconnectInitActionConstants.DMPC_RESET_APPLICATION
  };
};
export var setModalError = function setModalError(modalError) {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_MODAL_ERROR,
    modalError: modalError
  };
};
export var clearModalError = function clearModalError() {
  return {
    type: dmpconnectInitActionConstants.DMPC_CLEAR_MODAL_ERROR
  };
};
export var setDmpSearchPanelOpened = function setDmpSearchPanelOpened(panel) {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_DMP_SEARCH_PANEL_OPENED,
    panel: panel
  };
};
export var setInteropCodes = function setInteropCodes(codes) {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_INTEROP_CODES,
    codes: codes
  };
};
export var setDmpParameters = function setDmpParameters(params) {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_DMP_PARAMETERS,
    params: params
  };
};
export var setNosCodes = function setNosCodes(codes) {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_NOS_CODES,
    codes: codes
  };
};
export var setCiSisCodes = function setCiSisCodes(codes) {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_CISIS_CODES,
    codes: codes
  };
};
export var setAtcImmunizationCodes = function setAtcImmunizationCodes(codes) {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_ATC_IMMUNIZATION_CODES,
    codes: codes
  };
};
export var setConnectorKo = function setConnectorKo() {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_CONNECTOR_KO
  };
};
export var setSessionKo = function setSessionKo() {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_SESSION_KO
  };
};
export var setConnectorOk = function setConnectorOk() {
  var createSession = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_CONNECTOR_OK,
    createSession: createSession
  };
};
export var setConnectorConfigOk = function setConnectorConfigOk() {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_CONNECTOR_CONFIG_OK
  };
};
export var setConnectorConfigKo = function setConnectorConfigKo() {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_CONNECTOR_CONFIG_KO
  };
};
export var setSessionOk = function setSessionOk() {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_SESSION_OK
  };
};
export var setConnectorVersionKo = function setConnectorVersionKo(version) {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_CONNECTOR_VERSION_KO,
    version: version
  };
};
export var setLicenseStatus = function setLicenseStatus(status, error) {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_LICENSE_STATUS,
    status: status,
    error: error
  };
};
export var startVirtualPrinter = function startVirtualPrinter() {
  return {
    type: dmpconnectInitActionConstants.DMPC_START_VIRTUAL_PRINTER
  };
};
export var dashboardInit = function dashboardInit() {
  return {
    type: dmpconnectInitActionConstants.DMPC_DASHBOARD_INIT
  };
};
export var setDashboardInitOk = function setDashboardInitOk() {
  var status = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return {
    type: dmpconnectInitActionConstants.DMPC_DASHBOARD_INIT_OK,
    status: status
  };
};
export var monitorLicenseAfterForm = function monitorLicenseAfterForm() {
  return {
    type: dmpconnectInitActionConstants.DMPC_LICENSE_CHECK
  };
};
export var setLicenseAfterFormOK = function setLicenseAfterFormOK() {
  return {
    type: dmpconnectInitActionConstants.DMPC_LICENSE_AFTER_FORM_OK
  };
};
export var setLicenseMonitoringLoading = function setLicenseMonitoringLoading(status) {
  return {
    type: dmpconnectInitActionConstants.DMPC_LICENSE_CHECK_LOADING,
    status: status
  };
};
export var setDcParamsRegistered = function setDcParamsRegistered() {
  return {
    type: dmpconnectInitActionConstants.DMPC_DCPARAMS_REGISTERED
  };
};
export var setDcParamsUnregistered = function setDcParamsUnregistered() {
  return {
    type: dmpconnectInitActionConstants.DMPC_DCPARAMS_UNREGISTERED
  };
};
export var login = function login(pinCode, practiceSetting, cpsPracticeLocationIndice) {
  var auto = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return {
    type: dmpconnectInitActionConstants.DMPC_LOGIN,
    pinCode: pinCode,
    practiceSetting: practiceSetting,
    cpsPracticeLocationIndice: cpsPracticeLocationIndice,
    auto: auto
  };
};
export var logout = function logout() {
  var noTypeCheck = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return function (dispatch, getState) {
    return dispatch({
      type: dmpconnectInitActionConstants.DMPC_LOGOUT,
      sessionId: getSessionId(getState())
    });
  };
};
export var logoutSuccess = function logoutSuccess() {
  return {
    type: dmpconnectInitActionConstants.DMPC_LOGOUT_SUCCESS
  };
};
export var loginSuccess = function loginSuccess() {
  return {
    type: dmpconnectInitActionConstants.DMPC_LOGIN_SUCCESS
  };
};
export var setForceSecretConnexion = function setForceSecretConnexion(ins, force) {
  return {
    type: dmpconnectInitActionConstants.DMPC_SET_FORCE_SECRET_CONNEXION,
    ins: ins,
    force: force
  };
};
export var setWsInitLoading = function setWsInitLoading(loading) {
  return {
    type: dmpconnectInitActionConstants.WS_INIT_LOADING,
    loading: loading
  };
};
export var setWsInitError = function setWsInitError(error) {
  return {
    type: dmpconnectInitActionConstants.WS_INIT_FAILED,
    error: error
  };
};