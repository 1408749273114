import JSum from 'jsum';

const processEnv = typeof process !== 'undefined' ? process.env : {};
const injectedEnv = window && window.injectedEnv ? window.injectedEnv : {};
export const appConfig = window && window.appConfig ? window.appConfig : {};

const overridableParams = [
  'REACT_APP_SESSION_TIMEOUT',
  'REACT_APP_DMPCONNECTJS_PORT',
  'REACT_APP_API_TYPE',
  'REACT_APP_DCPARAMS',
  'REACT_APP_DC_PARAMS',

  'REACT_APP_TSE',
  'REACT_APP_SAAS_TOKEN',
  'REACT_APP_LICENSE_FORM_URL',
  'REACT_APP_HIDE_CONNECTOR_NEW_VERSION_MESSAGE',
  'REACT_APP_LOG_LEVEL', // 0 : no log, 1 : display console.log
  'REACT_APP_MISSING_CONNECTOR_ERROR_MESSAGE', // message d'erreur custom sur l'écran indiquant que le connecteur est introuvable
  'REACT_APP_MISSING_CONNECTOR_ERROR_MESSAGE_IS_BASE64', // 1 si le message est encodé en base64 (pour gérer des chaines contenant des apostrophe ou caractères spéciaux)
  'REACT_APP_ENABLE_CUSTOM_PRACTICE_SETTING',
  'REACT_APP_FOOTER_LOGS_LINK',
  'REACT_APP_HIDE_FOOTER',

  'REACT_APP_EXTERNAL_PSC_ACCESS_TOKEN',

  'REACT_APP_ES_JWT_KEY',
  'REACT_APP_GENERATED_CONNECTOR_JWT', // kept for compatibility
  'REACT_APP_AUTH_BEARER_USE_EXTERNAL_JWT', // => pour accès REST avec auth bearer fourni dans l'url
  'REACT_APP_AUTH_BEARER_USE_JWT_LOGIN_TOKEN', // => pour accès REST avec auth bearer venant du SSO utilisé au login

  'REACT_APP_WIRELESS_USE_JWT_LOGIN_TOKEN', // => pour olaqin
  'REACT_APP_WIRELESS_MODE_OLAQIN', // mode olaqin special icanopee (JS2 + REST pour PSC uniquement, quand on sélectionne un lecteur wireless)

  'REACT_APP_LICENSING_GROUP_ID',

  'REACT_APP_OHIF_VIEWER_HOST',

  'REACT_APP_VITALE_PATIENT_EXPORT_METHOD',
  'REACT_APP_VITALE_PATIENT_EXPORT_URL',
  'REACT_APP_VITALE_PATIENT_EXPORT_ARGS_MAPPER',
  'REACT_APP_VITALE_PATIENT_EXPORT_LABEL',
  'REACT_APP_VITALE_PATIENT_EXPORT_EXTRA_ARGS_FROM_URL',
];

const filteredAppConfig = Object.assign(
  {},
  ...overridableParams.map(key => (appConfig[key] !== undefined ? ({ [key]: appConfig[key] }) : undefined)),
);

const env = {
  ...processEnv,
  ...injectedEnv,
  ...filteredAppConfig,
};

export const generateEnvCheckSum = (currentEnv) => {
  const { REACT_APP_VERSION, REACT_APP_DCPARAMS, REACT_APP_DC_PARAMS, ...curEnv } = currentEnv;
  return JSum.digest(curEnv, 'SHA256', 'hex');
};

export default env;
