import _objectWithoutProperties from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export var getLoadingState = function getLoadingState(action) {
  var context = action.context;
  var section = context.section,
    subSection = context.subSection;
  if (subSection) {
    return _defineProperty({}, section, _defineProperty({}, subSection, {
      loading: true,
      updatedAt: null,
      error: null,
      tooLong: false
    }));
  }
  return _defineProperty({}, section, {
    loading: true,
    updatedAt: null,
    error: null,
    tooLong: false
  });
};
export var getTooLongState = function getTooLongState(action) {
  var context = action.context;
  var section = context.section,
    subSection = context.subSection;
  if (subSection) {
    return _defineProperty({}, section, _defineProperty({}, subSection, {
      loading: true,
      updatedAt: Date.now(),
      tooLong: true
    }));
  }
  return _defineProperty({}, section, {
    loading: true,
    updatedAt: Date.now(),
    tooLong: true
  });
};
export var getSuccessState = function getSuccessState(action) {
  var context = action.context,
    data = action.data;
  var section = context.section,
    subSection = context.subSection,
    params = context.params;
  if (subSection) {
    return _defineProperty({}, section, _defineProperty({}, subSection, _objectSpread({}, data, {
      error: null,
      loading: false,
      tooLong: false,
      params: params,
      updatedAt: Date.now()
    })));
  }
  return _defineProperty({}, section, _objectSpread({}, data, {
    error: null,
    updatedAt: Date.now(),
    loading: false,
    tooLong: false,
    params: params
  }));
};
export var getClearSectionState = function getClearSectionState(action, state) {
  var section = state[action.section],
    newState = _objectWithoutProperties(state, [action.section].map(_toPropertyKey));
  return _objectSpread({}, newState, _defineProperty({}, action.section, {}));
};
export var getClearSubSectionState = function getClearSubSectionState(action, state) {
  var section = state[action.section],
    newState = _objectWithoutProperties(state, [action.section].map(_toPropertyKey));
  if (!section) {
    return state;
  }
  if (action.subSection in section) {
    var subsection = section[action.subSection],
      newSubsections = _objectWithoutProperties(section, [action.subSection].map(_toPropertyKey));
    return _objectSpread({}, newState, _defineProperty({}, action.section, _objectSpread({}, newSubsections)));
  }
  return state;
};
export var getClearState = function getClearState(action, state) {
  var subSection = action.context.subSection;
  if (subSection) {
    return getClearSubSectionState(action.context, state);
  }
  return getClearSectionState(action.context, state);
};
export var clearErrors = function clearErrors(state) {
  var newState = {};
  Object.keys(state).forEach(function (key) {
    if (state[key] && !Array.isArray(state[key]) && key !== 'error' && typeof state[key] === 'object') {
      Object.assign(newState, _defineProperty({}, key, _objectSpread({}, clearErrors(state[key]))));
    } else {
      var value = state[key];
      // reset error
      if (key === 'error') {
        value = null;
      }
      // reset loading
      if (key === 'loading') {
        value = false;
      }
      Object.assign(newState, _defineProperty({}, key, value));
    }
  });
  return newState;
};
export var getFailureState = function getFailureState(action) {
  var context = action.context,
    error = action.data;
  var section = context.section,
    subSection = context.subSection,
    params = context.params;
  if (subSection) {
    return _defineProperty({}, section, _defineProperty({}, subSection, {
      loading: false,
      tooLong: false,
      updatedAt: null,
      error: error,
      params: params
    }));
  }
  return _defineProperty({}, section, {
    loading: false,
    tooLong: false,
    updatedAt: null,
    error: error,
    params: params
  });
};