import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import merge from 'lodash.merge';
import { dmpconnectConfigurationActionConstants } from '../constants';
var initialState = {
  serverName: null,
  active: false,
  idam: null,
  numAm: null,
  lpsName: null,
  lpsVersion: null,
  codeSpeAMO: null,
  startDate: null,
  nfcReaderActive: true,
  qrCodeReaderActive: true
};
export function dmpconnectApCvConfiguration() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case dmpconnectConfigurationActionConstants.DMPC_RESET_APCV_CONFIGURATION_FROM_ENV:
      return action.payload;
    case dmpconnectConfigurationActionConstants.DMPC_SET_APCV_CONFIGURATION:
      return merge({}, state, _defineProperty({}, action.key, action.value));
    default:
      return state;
  }
}
export default function getDmpconnectApCvConfigReducer(_ref) {
  var active = _ref.active,
    serverName = _ref.serverName,
    idam = _ref.idam,
    numAm = _ref.numAm,
    lpsName = _ref.lpsName,
    lpsVersion = _ref.lpsVersion,
    codeSpeAMO = _ref.codeSpeAMO,
    startDate = _ref.startDate,
    _ref$nfcReaderActive = _ref.nfcReaderActive,
    nfcReaderActive = _ref$nfcReaderActive === void 0 ? true : _ref$nfcReaderActive,
    _ref$qrCodeReaderActi = _ref.qrCodeReaderActive,
    qrCodeReaderActive = _ref$qrCodeReaderActi === void 0 ? true : _ref$qrCodeReaderActi;
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      active: active,
      serverName: serverName,
      idam: idam,
      numAm: numAm,
      lpsName: lpsName,
      lpsVersion: lpsVersion,
      codeSpeAMO: codeSpeAMO,
      startDate: startDate,
      nfcReaderActive: nfcReaderActive,
      qrCodeReaderActive: qrCodeReaderActive
    };
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return dmpconnectApCvConfiguration(state, action);
  };
}