import { mssLoginTypes } from '../../constants';
import { getDefaultMssConfig } from '../../helpers/accessors';
var commands = {
  activeFeatures: 'activeFeatures',
  getTimeSynchronizationDifference: 'getTimeSynchronizationDifference',
  // dcParams
  isDcParamsRegistered: 'isDcParamsRegistered',
  registerDcParams: 'registerDcParams',
  unregisterDcParams: 'unregisterDcParams',
  // Session
  openSession: 'openSession',
  closeSession: 'closeSession',
  getSessionState: 'getSessionState',
  sessionExists: 'sessionExists',
  // Cards
  updateCpxAuthenticationToken: 'updateCpxAuthenticationToken',
  updateVitaleAuthenticationToken: 'updateVitaleAuthenticationToken',
  getPcscReaders: 'getPcscReaders',
  getCpxCard: 'getCpxCard',
  readCpxCard: 'readCpxCard',
  getCpxStatus: 'getCpxStatus',
  getCpxHpInfos: 'getCpxHpInfos',
  getVitaleCard: 'getVitaleCard',
  readVitaleCard: 'readVitaleCard',
  getVitaleXmlContent: 'getVitaleXmlContent',
  setPracticeLocation: 'setPracticeLocation',
  checkCpxTokenStatus: 'checkCpxTokenStatus',
  // Cards monitoring
  startCpxCardMonitoring: 'startCpxCardMonitoring',
  startVitaleCardMonitoring: 'startVitaleCardMonitoring',
  // DMP
  createDMPConnector: 'createDMPConnector',
  createEsDMPConnector: 'createEsDMPConnector',
  setDMPAccessMode: 'setDMPAccessMode',
  setConfidentialityLevel: 'SetConfidentialityLevel',
  getOtpChannelValue: 'getOtpChannelValue',
  // TLSi
  createTLSiConnector: 'createTLSiConnector',
  createEsTLsiConnector: 'createEsTLsiConnector',
  // DP
  createDpConnector: 'createDpConnector',
  // TDs
  getCertifiedIdentity: 'getCertifiedIdentity',
  getDirectAuthenticationDMPStatus: 'getDirectAuthenticationDMPStatus',
  getDMPStatus: 'getDMPStatus',
  updateUserDmpAccessAuthorization: 'updateUserDmpAccessAuthorization',
  getAccessibleDMPList: 'getAccessibleDMPList',
  findPatient: 'findPatient',
  getWebPsDmpUrls: 'getWebPsDmpUrls',
  setWebPsRootUrl: 'setWebPsRootUrl',
  createDMP: 'createDMP',
  createDMPAndOtp: 'createDMPAndOtp',
  reactivateDMP: 'reactivateDMP',
  getDmpAdministrativeData: 'getDmpAdministrativeData',
  updateDmpAdministrativeData: 'updateDmpAdministrativeData',
  closeDMP: 'closeDMP',
  getDmpAuthorizationsList: 'getDmpAuthorizationsList',
  sendDocument: 'sendDocument',
  validateDocuments: 'validateDocuments',
  findDocuments: 'findDocuments',
  getDocumentContentByUniqueId: 'getDocumentContentByUniqueId',
  getDocumentsContentByUniqueId: 'getDocumentsContentByUniqueId',
  updateDocumentVisibilityByUniqueId: 'updateDocumentVisibilityByUniqueId',
  updateDocumentStatusByUniqueId: 'updateDocumentStatusByUniqueId',
  deleteDocument: 'deleteDocument',
  deleteArchivedDocument: 'deleteArchivedDocument',
  getSubmissionSetDocuments: 'getSubmissionSetDocuments',
  getDocumentSubmissionSetIdByUniqueId: 'getDocumentSubmissionSetIdByUniqueId',
  // Persistant data
  getPersistantData: 'getPersistantData',
  setPersistantData: 'setPersistantData',
  clearPersistantData: 'clearPersistantData',
  // high level
  getPatientWebAccessPdf: 'getPatientWebAccessPdf',
  createAcknowledgementPdf: 'createAcknowledgementPdf',
  getInteropCodes: 'getInteropCodes',
  getNosCodes: 'getNosCodes',
  getCiSisCodes: 'getCiSisCodes',
  getCisCodes: 'getCisCodes',
  getAtcCodes: 'getAtcCodes',
  getDmpParameters: 'getDmpParameters',
  getInsNirFromInsC: 'getInsNirFromInsC',
  checkUserLicenseRight: 'checkUserLicenseRight',
  // low level
  getObjectParameterInt: 'getObjectParameterInt',
  setVitaleXmlContent: 'setVitaleXmlContent',
  // ald
  getAldFromVitaleCard: 'getAldFromVitaleCard',
  // insi
  getInsFromVitaleCard: 'getInsFromVitaleCard',
  getInsFromRawVitaleData: 'getInsFromRawVitaleData',
  getInsFromIdentityInformation: 'getInsFromIdentityInformation',
  checkIdentity: 'checkIdentity',
  submitInsiBatchValidationSet: 'submitInsiBatchValidationSet',
  getInsiBatchValidationSetResult: 'getInsiBatchValidationSetResult',
  // dp
  reloadPpCerts: 'reloadPpCerts',
  getDp: 'getDp',
  getNdp: 'getNdp',
  dpGetMedicationDispensations: 'dpGetMedicationDispensations',
  dpAddMedicationDispensations: 'dpAddMedicationDispensations',
  dpDirectory_GetRecordStatus: 'dpDirectory_GetRecordStatus',
  dpDirectory_SearchRecord: 'dpDirectory_SearchRecord',
  dpDirectory_AddOrUpdateRecord: 'dpDirectory_AddOrUpdateRecord',
  dpDirectory_DeleteRecord: 'dpDirectory_DeleteRecord',
  // DP log
  addFunctionalDpLogEntry: 'addFunctionalDpLogEntry',
  // mss - authentication
  performMssAuthentication: 'performMssAuthentication',
  // mss
  getMssHpInfos: 'getMssHpInfos',
  getEmailAdress: 'getEmailAdress',
  getFoldersMSS: 'getFolderMSS',
  createFolderMSS: 'createFolderMSS',
  deleteFolderMSS: 'deleteFolderMSS',
  renameFolderMSS: 'renameFolderMSS',
  getSearchMessagesMSS: 'getSearchMessagesMSS',
  getSyncMessagesMSS: 'getSyncMessagesMSS',
  getMessageContentMss: 'getMessageContentMss',
  updateMessagesMSS: 'updateMessagesMSS',
  getDownloadAttachmentMSS: 'getDownloadAttachmentMSS',
  sendMssSmtpEmail: 'sendMssSmtpEmail',
  sendMssWebEmailConnector: 'sendMssWebEmailConnector',
  sendMssWebEmailSoap: 'sendMssWebEmailSoap',
  generateMssAttachments: 'generateMssAttachments',
  sendMssRequest: 'sendMssRequest',
  moveMSSMessages: 'moveMssMessages',
  deleteMssMessages: 'deleteMssMessages',
  fullSearchMssMessages: 'fullSearchMssMessages',
  imapAppendMessage: 'imapAppendMessage',
  // mss - ihe-dxm
  getDocumentsFromIheXdm: 'getDocumentsFromIheXdm',
  // mss - rest
  initiateMssOtpAuthentication: 'initiateMssOtpAuthentication',
  finalizeMssOtpAuthentication: 'finalizeMssOtpAuthentication',
  sendMssAuthenticatedEmail: 'sendMssAuthenticatedEmail',
  // mss - config
  setMssEsSmtpCertificate: 'setMssEsSmtpCertificate',
  setMssEsImapCertificate: 'setMssEsImapCertificate',
  setMssEsWebServiceCertificate: 'setMssEsWebServiceCertificate',
  // misc
  getStatistics: 'getStatistics',
  exportCdaDocument: 'exportCdaDocument',
  exportData: 'exportData',
  // es
  getEsInfos: 'getEsInfos',
  getUserInfos: 'getUserInfos',
  // ApCV
  createApCVConnector: 'createApCVConnector',
  getNfcCard: 'getNfcCard',
  getNfcApCvUserData: 'getNfcApCvUserData',
  getApCvContext: 'getApCvContext',
  releaseApCvContext: 'releaseApCvContext',
  // Logs
  getLogLevels: 'getLogLevels',
  setLogLevels: 'setLogLevels',
  getLogsTail: 'getLogsTail',
  getSessionLogsTail: 'getSessionLogsTail',
  getSystemInformation: 'getSystemInformation',
  //webPS
  getTd010Vihf: 'getTd010Vihf',
  // health directory
  searchLocalHealthDirectory: 'searchLocalHealthDirectory',
  searchLocalHealthDirectoryByRpps: 'searchLocalHealthDirectoryByRpps',
  searchFhirHealthDirectory: 'searchFhirHealthDirectory',
  searchFhirAllHpOfStructure: 'searchFhirAllHpOfStructure',
  // PSC - CIBA
  pscAuthenticateUserCiba: 'pscAuthenticateUserCiba'
};
export var commandTypes = {
  HTTP: 'HTTP',
  REST: 'REST',
  WS: 'WS',
  JSON: 'JSON'
};
export var commandSubTypes = {
  DP: 'DP',
  INSI: 'INSI',
  MSS: 'MSS',
  MSS_IMAP: 'IMAP',
  MSS_SMTP: 'SMTP',
  MSS_WS: 'MSS_WS'
};
export var mssSubTypes = {
  WEB: 'WEB',
  IMAP: 'IMAP'
};
export var mssOperators = {
  MAILIZ: 'mailiz',
  WRAPTOR: 'wraptor',
  OTHERS: 'others'
};
export var generateCommonDpParams = function generateCommonDpParams(state) {
  var dmpconnectDpConfiguration = state.dmpconnectDpConfiguration;
  var serverName = dmpconnectDpConfiguration.serverName,
    isDpEtab = dmpconnectDpConfiguration.isDpEtab,
    etabType = dmpconnectDpConfiguration.etabType,
    etabIdentifier = dmpconnectDpConfiguration.etabIdentifier,
    softwareValidationCode = dmpconnectDpConfiguration.softwareValidationCode,
    softwareVersion = dmpconnectDpConfiguration.softwareVersion,
    softwarePatch = dmpconnectDpConfiguration.softwarePatch;
  return {
    LpsParams: {
      i_structureType: Number(etabType === 'officine' ? 1 : etabType),
      s_softwarePatch: softwarePatch,
      s_softwareValidationCode: softwareValidationCode,
      s_softwareVersion: softwareVersion,
      s_structureIdentifier: etabIdentifier,
      i_isDpEtab: isDpEtab === true ? 1 : 0
    },
    s_dpUrl: serverName
  };
};
export var generateMssImapServerParams = function generateMssImapServerParams(state, mssAccountConfig) {
  var mssEmail = mssAccountConfig.mssEmail,
    mssImapServer = mssAccountConfig.mssImapServer,
    mssImapPort = mssAccountConfig.mssImapPort,
    mssImapLogin = mssAccountConfig.mssImapLogin,
    mssImapPasswd = mssAccountConfig.mssImapPasswd,
    mssImapSaslLogin = mssAccountConfig.mssImapSaslLogin,
    mssLoginType = mssAccountConfig.mssLoginType;
  var _getDefaultMssConfig = getDefaultMssConfig(state),
    mssPscAccessToken = _getDefaultMssConfig.mssPscAccessToken;
  var imapLoginPasswd = "".concat(mssImapLogin || '', ":").concat(mssImapPasswd || '', ":").concat(mssImapSaslLogin || '');
  // remove the ':' to check if a string is built.
  var imapLoginPasswdCheck = "".concat(mssImapLogin || '').concat(mssImapPasswd || '').concat(mssImapSaslLogin || '');
  if (mssLoginType === mssLoginTypes.PSC) {
    imapLoginPasswd = "".concat(mssImapLogin || mssEmail, ":::").concat(mssPscAccessToken);
    return {
      s_url: mssImapServer ? "imap://".concat(mssImapServer) : undefined,
      i_port: mssImapPort ? Number(mssImapPort || 0) : undefined,
      s_login: imapLoginPasswd,
      mssGetUpToDatePscToken: true
    };
  }
  return {
    s_url: mssImapServer ? "imap://".concat(mssImapServer) : undefined,
    i_port: mssImapPort ? Number(mssImapPort || 0) : undefined,
    s_login: !!imapLoginPasswdCheck && imapLoginPasswdCheck !== mssEmail ? imapLoginPasswd : mssEmail
  };
};
export var generateMssSmtpServerParams = function generateMssSmtpServerParams(state, mssAccountConfig) {
  var mssEmail = mssAccountConfig.mssEmail,
    mssSmtpServer = mssAccountConfig.mssSmtpServer,
    mssSmtpPort = mssAccountConfig.mssSmtpPort,
    mssSmtpLogin = mssAccountConfig.mssSmtpLogin,
    mssSmtpPasswd = mssAccountConfig.mssSmtpPasswd,
    mssSmtpSaslLogin = mssAccountConfig.mssSmtpSaslLogin,
    mssPscAccessToken = mssAccountConfig.mssPscAccessToken,
    mssLoginType = mssAccountConfig.mssLoginType;
  var smtpLoginPasswd = "".concat(mssSmtpLogin || '', ":").concat(mssSmtpPasswd || '', ":").concat(mssSmtpSaslLogin || '');
  // remove the ':' to check if a string is built.
  var smtpLoginPasswdCheck = "".concat(mssSmtpLogin || '').concat(mssSmtpPasswd || '').concat(mssSmtpSaslLogin || '');
  if (mssLoginType === mssLoginTypes.PSC) {
    smtpLoginPasswd = "".concat(mssSmtpLogin || mssEmail, ":::").concat(mssPscAccessToken);
    return {
      s_url: mssSmtpServer ? "smtp://".concat(mssSmtpServer) : undefined,
      i_port: mssSmtpPort ? Number(mssSmtpPort || 0) : undefined,
      s_login: smtpLoginPasswd,
      mssGetUpToDatePscToken: true
    };
  }
  return {
    s_url: mssSmtpServer ? "smtp://".concat(mssSmtpServer) : undefined,
    i_port: mssSmtpPort ? Number(mssSmtpPort || 0) : undefined,
    s_login: smtpLoginPasswdCheck && smtpLoginPasswdCheck !== mssEmail ? smtpLoginPasswd : mssEmail
  };
};
export var generateCommonMssCertParams = function generateCommonMssCertParams(state, subType, mssAccountConfig) {
  var mssEmail = mssAccountConfig.mssEmail,
    mssImapLogin = mssAccountConfig.mssImapLogin,
    mssImapPasswd = mssAccountConfig.mssImapPasswd,
    mssImapSaslLogin = mssAccountConfig.mssImapSaslLogin,
    mssSmtpLogin = mssAccountConfig.mssSmtpLogin,
    mssSmtpPasswd = mssAccountConfig.mssSmtpPasswd,
    mssSmtpSaslLogin = mssAccountConfig.mssSmtpSaslLogin,
    mssLoginType = mssAccountConfig.mssLoginType;
  var imapLoginPasswdCheck = "".concat(mssImapLogin || '').concat(mssImapPasswd || '').concat(mssImapSaslLogin || '');
  var smtpLoginPasswdCheck = "".concat(mssSmtpLogin || '').concat(mssSmtpPasswd || '').concat(mssSmtpSaslLogin || '');
  if ((mssLoginType === mssLoginTypes.CERT || mssLoginType === mssLoginTypes.PSC || mssLoginType === mssLoginTypes.CPX && (imapLoginPasswdCheck || smtpLoginPasswdCheck) && (mssEmail !== imapLoginPasswdCheck || mssEmail !== smtpLoginPasswdCheck)) && subType && [commandSubTypes.MSS_IMAP, commandSubTypes.MSS_SMTP].includes(subType)) {
    return {
      i_useEsAuthentication: 1
    };
  }
  return undefined;
};
export default commands;