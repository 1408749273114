import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
export var ndpOrigin = {
  VITALE: 1,
  IDENTITY: 2,
  UNKNONW: 3
};
export var dpEtabTypes = {
  officine: 1,
  pui: 2,
  distributeur: 3
};
export var dpEtabTypesLabel = _defineProperty(_defineProperty(_defineProperty({}, dpEtabTypes.officine, 'Officine'), dpEtabTypes.pui, 'PUI'), dpEtabTypes.distributeur, 'Distributeur');