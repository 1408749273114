import _toConsumableArray from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import { apiSections, cpxCardTypes, cpxCardTypesText, insTypeToVitaleCardStatus, vitaleCardStatuses, vitaleCardStatusesText } from '../constants';
import { apCvErrors, createError, errorTypes } from '../errors';
export var vitaleDataType = {
  VITALE_CARD: 1,
  APCV_CONTEXT: 2
};
export var vitaleCardSuccessToVitaleData = function vitaleCardSuccessToVitaleData(vitaleCardAction) {
  var data = vitaleCardAction.data;
  return _defineProperty({}, apiSections.VITALE_DATA_SECTION, {
    type: vitaleDataType.VITALE_CARD,
    s_status: data.s_status,
    loading: false,
    Patients: data.Patients,
    i_cardStatus: data.i_cardStatus
  });
};
var apCvContextIdentityToVitaleData = function apCvContextIdentityToVitaleData(vitaleData, identity) {
  var _vitaleData$NirData = vitaleData.NirData,
    CertifiedNir = _vitaleData$NirData.CertifiedNir,
    Nir = _vitaleData$NirData.Nir;
  var i_sex = identity.i_sex;
  var sex = i_sex;
  if (sex === 1 && CertifiedNir && CertifiedNir.s_nir) {
    var firstDigit = CertifiedNir.s_nir.substring(0, 1);
    if (Number(firstDigit) === 1) {
      sex = 2;
    }
    if (Number(firstDigit) === 2) {
      sex = 3;
    }
  }
  return {
    i_birthRank: vitaleData.i_rank,
    i_quality: vitaleData.i_qualBenef,
    s_birthday: vitaleData.s_birthday,
    s_birthdayYYYYMMDD: vitaleData.s_birthday,
    s_rawBirthdayYYYYMMDD: vitaleData.s_birthday,
    s_birthname: vitaleData.s_birthName,
    s_certifiedNir: "".concat(CertifiedNir.s_nir, " ").concat(CertifiedNir.s_key),
    s_given: vitaleData.s_givenName,
    s_name: vitaleData.s_name,
    s_nir: "".concat(Nir.s_nir, " ").concat(Nir.s_key),
    i_sex: sex
  };
};
export var apCvContextSuccessToVitaleData = function apCvContextSuccessToVitaleData(apCvContextAction, cpxCardType) {
  var data = apCvContextAction.data;
  var _data$User = data.User,
    VitaleData = _data$User.VitaleData,
    Identity = _data$User.Identity,
    Wallet = data.Wallet,
    s_profileType = data.s_profileType;
  var cardStatus = insTypeToVitaleCardStatus[s_profileType];
  if (!(cpxCardType === cpxCardTypes.DEMO && [vitaleCardStatuses.DEMO_CARD, vitaleCardStatuses.TEST_CARD].includes(cardStatus) || cpxCardType === cpxCardTypes.PROD && [vitaleCardStatuses.DEMO_CARD, vitaleCardStatuses.REAL_CARD].includes(cardStatus))) {
    var error = _objectSpread({}, createError(errorTypes.apCvErrors, apCvErrors.WRONG_CARD_TYPES), {
      s_apiErrorDescription: "Vous ne pouvez pas utiliser une appli carte Vitale ".concat(vitaleCardStatusesText[cardStatus], " avec une carte PS ").concat(cpxCardTypesText[cpxCardType])
    });
    return _defineProperty({}, apiSections.VITALE_DATA_SECTION, {
      type: vitaleDataType.APCV_CONTEXT,
      s_status: 'KO',
      loading: false,
      i_cardStatus: cardStatus,
      error: error
    });
  }
  return _defineProperty({}, apiSections.VITALE_DATA_SECTION, {
    type: vitaleDataType.APCV_CONTEXT,
    s_status: 'OK',
    loading: false,
    i_cardStatus: cardStatus,
    Patients: [apCvContextIdentityToVitaleData(VitaleData, Identity)].concat(_toConsumableArray(Wallet.map(function (_ref3) {
      var VitaleData = _ref3.VitaleData,
        Identity = _ref3.Identity;
      return apCvContextIdentityToVitaleData(VitaleData, Identity);
    })))
  });
};