import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import merge from 'lodash.merge';
import { dmpconnectConfigurationActionConstants } from '../constants';
export var esLoginTypes = {
  OPENID: 'OPENID',
  TOKEN: 'TOKEN',
  API: 'API',
  LOGIN_PASSWD: 'LOGIN_PASSWD',
  CONNECTOR_LOGIN_PASSWD: 'CONNECTOR_LOGIN_PASSWD'
};
var initialState = {
  loginType: esLoginTypes.OPENID,
  configRequired: false,
  hpAuthenticationMode: undefined,
  hpAuthenticationContext: undefined,
  hpGiven: undefined,
  hpInternalId: undefined,
  hpName: undefined,
  hpProfession: undefined,
  hpProfessionOid: undefined,
  hpSpeciality: undefined,
  es_id: null,
  es_id_mss: null,
  es_id_insi: null,
  es_finess: null,
  esLoginActive: false,
  esCertificate: null,
  esMssCertificate: null,
  esMssImapCertificate: null,
  esMssSmtpCertificate: null,
  esMssWsCertificate: null,
  practiceLocationName: null,
  activitySector: null,
  practiceSetting: null,
  healthcareSetting: null,
  serviceName: null,
  loginApiEndpoint: null,
  loginApiPSParam: null,
  loginApiPatientParam: null,
  loginApiExtraHeaders: null,
  loginCheckApi: false,
  loginCheckApiEndpoint: null,
  loginCheckApiPSParam: null,
  loginCheckApiPatientParam: null,
  cpxLoginActive: false
};
export function dmpconnectESConfiguration() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case dmpconnectConfigurationActionConstants.DMPC_RESET_ES_CONFIGURATION_FROM_ENV:
      return action.payload;
    case dmpconnectConfigurationActionConstants.DMPC_SET_ES_CONFIGURATION:
      return merge({}, state, _defineProperty({}, action.key, action.value));
    default:
      return state;
  }
}
export default function getDmpconnectEsConfigReducer(_ref) {
  var loginType = _ref.loginType,
    configRequired = _ref.configRequired,
    hpAuthenticationMode = _ref.hpAuthenticationMode,
    hpAuthenticationContext = _ref.hpAuthenticationContext,
    hpGiven = _ref.hpGiven,
    hpInternalId = _ref.hpInternalId,
    hpName = _ref.hpName,
    hpProfession = _ref.hpProfession,
    hpProfessionOid = _ref.hpProfessionOid,
    hpSpeciality = _ref.hpSpeciality,
    es_id = _ref.es_id,
    es_id_mss = _ref.es_id_mss,
    es_id_insi = _ref.es_id_insi,
    es_finess = _ref.es_finess,
    esLoginActive = _ref.esLoginActive,
    esCertificate = _ref.esCertificate,
    esMssCertificate = _ref.esMssCertificate,
    esMssImapCertificate = _ref.esMssImapCertificate,
    esMssSmtpCertificate = _ref.esMssSmtpCertificate,
    esMssWsCertificate = _ref.esMssWsCertificate,
    practiceLocationName = _ref.practiceLocationName,
    activitySector = _ref.activitySector,
    practiceSetting = _ref.practiceSetting,
    healthcareSetting = _ref.healthcareSetting,
    serviceName = _ref.serviceName,
    loginApiEndpoint = _ref.loginApiEndpoint,
    loginApiPSParam = _ref.loginApiPSParam,
    loginApiPatientParam = _ref.loginApiPatientParam,
    loginApiExtraHeaders = _ref.loginApiExtraHeaders,
    loginCheckApi = _ref.loginCheckApi,
    loginCheckApiEndpoint = _ref.loginCheckApiEndpoint,
    loginCheckApiPSParam = _ref.loginCheckApiPSParam,
    loginCheckApiPatientParam = _ref.loginCheckApiPatientParam,
    cpxLoginActive = _ref.cpxLoginActive;
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      loginType: loginType,
      configRequired: configRequired,
      hpAuthenticationMode: hpAuthenticationMode,
      hpAuthenticationContext: hpAuthenticationContext,
      hpGiven: hpGiven,
      hpInternalId: hpInternalId,
      hpName: hpName,
      hpProfession: hpProfession,
      hpProfessionOid: hpProfessionOid,
      hpSpeciality: hpSpeciality,
      es_id: es_id,
      es_id_mss: es_id_mss,
      es_id_insi: es_id_insi,
      es_finess: es_finess,
      esLoginActive: esLoginActive,
      esCertificate: esCertificate,
      esMssCertificate: esMssCertificate,
      esMssImapCertificate: esMssImapCertificate,
      esMssSmtpCertificate: esMssSmtpCertificate,
      esMssWsCertificate: esMssWsCertificate,
      practiceLocationName: practiceLocationName,
      activitySector: activitySector,
      practiceSetting: practiceSetting,
      healthcareSetting: healthcareSetting,
      serviceName: serviceName,
      loginApiEndpoint: loginApiEndpoint,
      loginApiPSParam: loginApiPSParam,
      loginApiPatientParam: loginApiPatientParam,
      loginApiExtraHeaders: loginApiExtraHeaders,
      loginCheckApi: loginCheckApi,
      loginCheckApiEndpoint: loginCheckApiEndpoint,
      loginCheckApiPSParam: loginCheckApiPSParam,
      loginCheckApiPatientParam: loginCheckApiPatientParam,
      cpxLoginActive: cpxLoginActive
    };
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return dmpconnectESConfiguration(state, action);
  };
}