import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import merge from 'lodash.merge';
import { apiSections, dmpconnectInitActionConstants } from '../constants';
var initialState = _defineProperty(_defineProperty(_defineProperty({
  WSInitError: undefined,
  WSInitLoading: false,
  initRunning: false,
  applicationInitialized: false,
  dcParamsRegistered: false,
  connectorOk: true,
  sessionOk: true,
  connectorVersionOk: true,
  licenseOk: true,
  licenseError: {},
  error: null
}, apiSections.INTEROPCODES, {}), apiSections.DMP_PARAMETERS, {
  i_cumulMasquePsPatient: 0,
  i_majorityAge: 18,
  i_minorFunctionManagement: 0
}), "sockets", {});
function dmpconnectInit() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case dmpconnectInitActionConstants.DMPC_RESET_APPLICATION:
      return initialState;
    case dmpconnectInitActionConstants.DMPC_INIT_APPLICATION:
      return merge({}, state, {
        initRunning: true,
        applicationInitialized: false,
        connectorOk: true,
        sessionOk: true,
        connectorVersionOk: true,
        licenseOk: true
      });
    case dmpconnectInitActionConstants.DMPC_INIT_APPLICATION_SUCCESS:
      return merge({}, state, {
        applicationInitialized: true,
        initRunning: false
      });
    case dmpconnectInitActionConstants.DMPC_INIT_APPLICATION_FAILURE:
      return merge({}, state, {
        error: action.error,
        applicationInitialized: false,
        initRunning: false
      });
    case dmpconnectInitActionConstants.DMPC_SET_CONNECTOR_OK:
      return merge({}, state, {
        connectorOk: true
      });
    case dmpconnectInitActionConstants.DMPC_SET_CONNECTOR_KO:
      return merge({}, state, {
        connectorOk: false
      });
    case dmpconnectInitActionConstants.DMPC_SET_SESSION_OK:
      return merge({}, state, {
        sessionOk: true
      });
    case dmpconnectInitActionConstants.DMPC_SET_SESSION_KO:
      return merge({}, state, {
        sessionOk: false
      });
    case dmpconnectInitActionConstants.DMPC_SET_CONNECTOR_VERSION_KO:
      return merge({}, state, {
        connectorVersionOk: false,
        version: action.version
      });
    case dmpconnectInitActionConstants.DMPC_SET_LICENSE_STATUS:
      return merge({}, state, {
        licenseOk: action.status,
        licenseError: action.error
      });
    case dmpconnectInitActionConstants.DMPC_LICENSE_AFTER_FORM_OK:
      return merge({}, state, {
        licenseOkAfterForm: true
      });
    case dmpconnectInitActionConstants.DMPC_LICENSE_CHECK_LOADING:
      return merge({}, state, {
        licenseCheckLoading: action.status
      });
    case dmpconnectInitActionConstants.DMPC_DCPARAMS_REGISTERED:
      return merge({}, state, {
        dcParamsRegistered: true
      });
    case dmpconnectInitActionConstants.DMPC_DCPARAMS_UNREGISTERED:
      return merge({}, state, {
        dcParamsRegistered: false
      });
    case dmpconnectInitActionConstants.WS_INIT_LOADING:
      return merge({}, state, {
        WSInitLoading: action.loading
      });
    case dmpconnectInitActionConstants.WS_INIT_FAILED:
      return merge({}, state, {
        WSInitError: action.error,
        WSInitLoading: !!action.error ? false : state.WSInitLoading
      });
    default:
      return state;
  }
}
export default dmpconnectInit;