import PropTypes                                                                from 'prop-types';
import React, { Suspense }                                                      from 'react';
import { connect }                                                              from 'react-redux';
import { Route, Switch }                                                        from 'react-router-dom';
import { ToastContainer }                                                       from 'react-toastify';
import CardReaderProvider
                                                                                from './components/Card/CardReaderProvider';
import CenteredCard
                                                                                from './components/Common/Card/CenteredCard';
import AvailableNewVersion
                                                                                from './components/Common/Header/AvailableNewVersion';
import Loading
                                                                                from './components/Common/Loading/Loading';
import GetLogsPopup
                                                                                from './components/Common/Logs/GetLogsPopup';
import VersionFooter
                                                                                from './components/Common/Version/VersionFooter';
import ErrorBoundary                                                            from './components/Error/ErrorBoundary';
// Components
import { initApplication, resetAllConfigFromEnv, setPersistedAppConfiguration } from './dmpconnect/actions';
import env, { generateEnvCheckSum }                                             from './envVariables';
import lazyWithRetry                                                            from './lib/lazyWithRetry';

import { doesNotTriggerInitApp, routes } from './router';

const InitApp                 = lazyWithRetry(() => import('./components/Init/InitApp'));
const DMPSearch               = lazyWithRetry(() => import('./scenes/DMPSearch'));
const ErrorModal              = lazyWithRetry(() => import('./components/Error/ErrorModal'));
const GlobalModalConfirmation = lazyWithRetry(() => import('./components/Common/Modal/GlobalModalConfirmation'));

const Logs         = lazyWithRetry(() => import('./scenes/Logs'));
const DMPTseConfig = lazyWithRetry(() => import('./scenes/DMPTseConfig'));
const DMPDebug     = lazyWithRetry(() => import('./scenes/DMPDebug'));

const App = ({
               dispatch,
               saasTokenOk,
               envCheckSum,
               envCheckSumVersion,
               applicationInitialized,
               connectorOk,
               sessionOk,
               connectorVersionOk,
               licenseOk,
               pathname,
               urlProcessed,
               hideFooter,
             }) => {
  React.useEffect(() => {
    document.title = 'Efficience Vitale Reader';

  }, []);
  React.useEffect(() => {
    const currentEnvChecksum = generateEnvCheckSum(env);
    if (envCheckSum !== currentEnvChecksum && !!envCheckSumVersion) {
      dispatch(resetAllConfigFromEnv());
    }
    dispatch(setPersistedAppConfiguration('envCheckSum', currentEnvChecksum));
    dispatch(setPersistedAppConfiguration('envCheckSumVersion', env.REACT_APP_VERSION));
  }, [env, envCheckSum, envCheckSumVersion]);

  React.useEffect(() => {
    if (
      !applicationInitialized
      && connectorOk
      && sessionOk
      && connectorVersionOk
      && licenseOk
      && saasTokenOk
      && urlProcessed
      && !doesNotTriggerInitApp.includes(pathname)
    ) {
      dispatch(initApplication());
    }
  }, [
                    saasTokenOk,
                    urlProcessed,
                    applicationInitialized,
                    connectorOk,
                    sessionOk,
                    connectorVersionOk,
                    licenseOk,
                  ]);


  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={
          <CenteredCard><Loading message="Chargement ..."/></CenteredCard>
        }>
          <AvailableNewVersion/>
          <Switch>

            <Route path={routes.logs.path} component={Logs}/>
            {Number(env.REACT_APP_DEBUG) === 1 && (
              <Route exact path={routes.debug.path} component={DMPDebug}/>
            )}
            <Route exact path={routes.tseConfig.path} component={DMPTseConfig}/>
            <Route path="/*">
              <>
                {saasTokenOk ? (
                  <>
                    {applicationInitialized ? (
                      <>
                        <CardReaderProvider showLoading={true}>
                          <Route path="/*" component={DMPSearch}/>
                        </CardReaderProvider>
                        {!hideFooter && <VersionFooter/>}
                      </>
                    ) : (
                       <InitApp/>
                     )}
                  </>
                ) : (
                   <CenteredCard>
                     Vous n'&ecirc;tes pas autorisé à utiliser cette application.
                   </CenteredCard>
                 )}
              </>
            </Route>
          </Switch>

          <ErrorModal/>
          <GlobalModalConfirmation/>

          <ToastContainer
            autoClose={2000}
            position={'top-right'}
            closeOnClick
            hideProgressBar
          />
        </Suspense>
      </ErrorBoundary>
      <GetLogsPopup/>
    </>
  );
};

App.propTypes = {
  dispatch:               PropTypes.func.isRequired,
  applicationInitialized: PropTypes.bool,
  saasTokenOk:            PropTypes.bool,
  envCheckSum:            PropTypes.string,
  envCheckSumVersion:     PropTypes.string,
  pathname:               PropTypes.string.isRequired,
  urlProcessed:           PropTypes.bool,
  hideFooter:             PropTypes.bool,
  connectorOk:            PropTypes.bool.isRequired,
  sessionOk:              PropTypes.bool.isRequired,
  connectorVersionOk:     PropTypes.bool.isRequired,
  licenseOk:              PropTypes.bool.isRequired,
};

App.defaultProps = {
  applicationInitialized: false,
  saasTokenOk:            false,
  envCheckSum:            null,
  envCheckSumVersion:     null,
  urlProcessed:           false,
  hideFooter:             false,
};

function mapStateToProps(state) {
  const {
          router:                              { location: { pathname } },
          dmpconnectInit:                      {
                                                 applicationInitialized,
                                                 connectorOk,
                                                 sessionOk,
                                                 connectorVersionOk,
                                                 licenseOk,
                                               },
          dmpconnectApplication:               {
                                                 saasTokenOk,
                                                 urlProcessed,
                                               },
          dmpConnectPersistedAppConfiguration: {
                                                 envCheckSum,
                                                 envCheckSumVersion,
                                                 hideFooter
                                               },
        } = state;

  return {
    pathname,
    urlProcessed,
    connectorOk,
    sessionOk,
    connectorVersionOk,
    licenseOk,
    applicationInitialized,
    saasTokenOk,
    envCheckSum,
    envCheckSumVersion,
    hideFooter,
  };
}

const connectedApp = connect(mapStateToProps,
                             null,
                             null,
                             {
                               pure: false,
                             })(App);

export default connectedApp;
