import { dmpconnectMonitoringActionConstants } from '../constants';
import { getSessionId } from '../helpers/accessors';
export var startVitaleMonitoring = function startVitaleMonitoring() {
  return function (dispatch, getState) {
    return dispatch({
      type: dmpconnectMonitoringActionConstants.DMPC_VITALE_MONITORING_START,
      command: {
        s_commandName: 'hl_startVitaleCardMonitoring',
        i_checkingInterval: 5,
        s_sessionId: getSessionId(getState())
      }
    });
  };
};
export var stopVitaleMonitoring = function stopVitaleMonitoring() {
  return {
    type: dmpconnectMonitoringActionConstants.DMPC_VITALE_MONITORING_STOP
  };
};
export var startCpxMonitoring = function startCpxMonitoring() {
  return function (dispatch, getState) {
    return dispatch({
      type: dmpconnectMonitoringActionConstants.DMPC_CPX_MONITORING_START,
      command: {
        s_commandName: 'hl_startCpxCardMonitoring',
        i_checkingInterval: 5,
        s_sessionId: getSessionId(getState())
      }
    });
  };
};
export var stopCpxMonitoring = function stopCpxMonitoring() {
  return {
    type: dmpconnectMonitoringActionConstants.DMPC_CPX_MONITORING_STOP
  };
};
export var updateVitaleMonitoring = function updateVitaleMonitoring(data) {
  return {
    type: dmpconnectMonitoringActionConstants.DMPC_VITALE_MONITORING_UPDATE,
    data: data
  };
};
export var updateCpxMonitoring = function updateCpxMonitoring(data) {
  return {
    type: dmpconnectMonitoringActionConstants.DMPC_CPX_MONITORING_UPDATE,
    data: data
  };
};