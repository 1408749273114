import PropTypes  from "prop-types";
import * as React from 'react';
import Loading    from "../Common/Loading/Loading";

// const MobileCss = React.lazy(() => import('./MobileCss'));
const DesktopCss = React.lazy(() => import('./DesktopCss'));

const CssSelector = () => {
  return (
    <>
      <React.Suspense fallback={<Loading centered/>}>
        {/*{isMobile ? <MobileCss/> : <DesktopCss/>}*/}
        <DesktopCss/>
      </React.Suspense>
    </>
  );
};

CssSelector.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

// function mapStateToProps(state) {
//     return {
//         isMobile: getIsMobileFromState(state),
//     }
// }

// export default connect(mapStateToProps)(CssSelector);
export default CssSelector;
