import {
  API_TYPES as connectorApiTypes,
  dmpconnectConfigurationActionConstants as connectorConfigActionConstants,
} from 'dmpconnectjsapp-base/constants';

export const API_TYPES                              = connectorApiTypes;
export const dmpconnectConfigurationActionConstants = {
  ...connectorConfigActionConstants,
  DMPC_SET_PERSIST_APP_CONFIGURATION:            'DMPC_SET_PERSIST_APP_CONFIGURATION',
  DMPC_RESET_PERSIST_APP_CONFIGURATION_FROM_ENV: 'DMPC_RESET_PERSIST_APP_CONFIGURATION_FROM_ENV',
  DMPC_RESET_ALL_CONFIGURATION_FROM_ENV:         'DMPC_RESET_ALL_CONFIGURATION_FROM_ENV',

  SET_USER_PREFERENCE: 'SET_USER_PREFERENCE',
};

export const dmpconnectMonitoringActionConstants = {
  DMPC_VITALE_MONITORING_START:  'DMPC_VITALE_MONITORING_START',
  DMPC_VITALE_MONITORING_STOP:   'DMPC_VITALE_MONITORING_STOP',
  DMPC_VITALE_MONITORING_UPDATE: 'DMPC_VITALE_MONITORING_UPDATE',
};

export const dmpconnectApplicationActionConstants = {
  MESSAGE_EVENT_LOGGED_IN_EVT_SENT: 'MESSAGE_EVENT_LOGGED_IN_EVT_SENT',
  HARD_RESET:                       'HARD_RESET',
  DMPS_SET_URL_PROCESSED:           'DMPS_SET_URL_PROCESSED',
  DMPC_DCPARAMS_UNREGISTERED:       'DMPC_DCPARAMS_UNREGISTERED',
  DMPC_DCPARAMS_REGISTERED:         'DMPC_DCPARAMS_REGISTERED',
  DMPC_INIT_APPLICATION:            'DMPC_INIT_APPLICATION',
  DMPC_RESET_APPLICATION:           'DMPC_RESET_APPLICATION',
  DMPC_INIT_APPLICATION_FAILURE:    'DMPC_INIT_APPLICATION_FAILURE',
  DMPC_INIT_APPLICATION_SUCCESS:    'DMPC_INIT_APPLICATION_SUCCESS',
  DMPC_SET_MODAL_ERROR:             'DMPC_SET_MODAL_ERROR',
  DMPC_CLEAR_MODAL_ERROR:           'DMPC_CLEAR_MODAL_ERROR',
  DMPC_SET_DMP_SEARCH_PANEL_OPENED: 'DMPC_SET_DMP_SEARCH_PANEL_OPENED',
  DMPC_CLOSE_MANAGE_DMP_MODAL:      'DMPC_CLOSE_MANAGE_DMP_MODAL',
  DMPC_SET_INTEROP_CODES:           'DMPC_SET_INTEROP_CODES',
  DMPC_SET_DMP_PARAMETERS:          'DMPC_SET_DMP_PARAMETERS',
  DMPC_SET_SOCKET:                  'DMPC_SET_SOCKET',
  DMPC_SET_CONNECTOR_OK:            'DMPC_SET_CONNECTOR_OK',
  DMPC_SET_CONNECTOR_KO:            'DMPC_SET_CONNECTOR_KO',
  DMPC_SET_CONNECTOR_CONFIG_OK:     'DMPC_SET_CONNECTOR_CONFIG_OK',
  DMPC_SET_CONNECTOR_CONFIG_KO:     'DMPC_SET_CONNECTOR_CONFIG_KO',
  DMPC_SET_SESSION_OK:              'DMPC_SET_SESSION_OK',
  DMPC_SET_SESSION_KO:              'DMPC_SET_SESSION_KO',
  DMPC_SET_CONNECTOR_VERSION_KO:    'DMPC_SET_CONNECTOR_VERSION_KO',
  DMPC_SET_LICENSE_STATUS:          'DMPC_SET_LICENSE_STATUS',
  DMPC_START_VIRTUAL_PRINTER:       'DMPC_START_VIRTUAL_PRINTER',
  DMPC_DASHBOARD_INIT:              'DMPC_DASHBOARD_INIT',
  DMPC_DASHBOARD_INIT_OK:           'DMPC_DASHBOARD_INIT_OK',
  DMPC_CHECK_CONNECTOR:             'DMPC_CHECK_CONNECTOR',

  DMPC_LICENSE_CHECK:         'DMPC_LICENSE_CHECK',
  DMPC_LICENSE_AFTER_FORM_OK: 'DMPC_LICENSE_AFTER_FORM_OK',
  DMPC_LICENSE_CHECK_LOADING: 'DMPC_LICENSE_CHECK_LOADING',

  DMPC_DP_POPUP_OPENED:     'DMPC_DP_POPUP_OPENED',
  DMPC_ALD_POPUP_OPENED:    'DMPC_ALD_POPUP_OPENED',
  DMPC_MSS_POPUP_OPENED:    'DMPC_MSS_POPUP_OPENED',
  DMPC_READ_VITALE_PROCESS: 'DMPC_READ_VITALE_PROCESS',

  DMPC_MSS_RESET_EMAIL_CONTENT:   'DMPC_MSS_RESET_EMAIL_CONTENT',
  DMPC_MSS_SET_EMAIL_CONTENT:     'DMPC_MSS_SET_EMAIL_CONTENT',
  DMPC_MSS_ADD_EMAIL_ATTACHEMENT: 'DMPC_MSS_ADD_EMAIL_ATTACHEMENT',
  DMPC_MSS_SET_PATIENT_INFOS:     'DMPC_MSS_SET_PATIENT_INFOS',
  DMPC_MSS_RESET_PATIENT_INFOS:   'DMPC_MSS_RESET_PATIENT_INFOS',
  REMOVE_MSS_ZIP_ALL:             'REMOVE_MSS_ZIP_ALL',

  DMPC_SAAS_TOKEN_VALIDATED: 'DMPC_SAAS_TOKEN_VALIDATED',

  DMPC_SET_MODAL_CONFIRMATION: 'DMPC_SET_MODAL_CONFIRMATION',
  DMPC_SET_MODAL_INFORMATION:  'DMPC_SET_MODAL_INFORMATION',

  DMPC_SHOW_UPLOAD_DOCUMENT_MODAL: 'DMPC_SHOW_UPLOAD_DOCUMENT_MODAL',
  DMPC_SHOW_IMMUNIZATION_MODAL:    'DMPC_SHOW_IMMUNIZATION_MODAL',

  DMPC_EXPORT_CDA_CONTENT:      'DMPC_EXPORT_CDA_CONTENT',
  DMPC_SAVE_IN_PERSISTANT_DATA: 'DMPC_SAVE_IN_PERSISTANT_DATA',

  DMPC_SET_BACK_PAGE: 'DMPC_SET_BACK_PAGE',

  DMPC_GET_INS_FROM_VITALE_CARD: 'DMPC_GET_INS_FROM_VITALE_CARD',
  DMPC_CHECK_AND_GET_INS:        'DMPC_CHECK_AND_GET_INS',
  SET_VITALE_CARD_SERIAL_NUMBER: 'SET_VITALE_CARD_SERIAL_NUMBER',

  DMPC_UPDATE_CPX_ACCESS_TOKEN: 'DMPC_UPDATE_CPX_ACCESS_TOKEN',

  DMPC_API_LOGIN_CHECK_VALUES: 'DMPC_API_LOGIN_CHECK_VALUES',

  SHOW_LOADING_BACKDROP: 'SHOW_LOADING_BACKDROP',

  SET_IS_FIRST_LOGIN: 'SET_IS_FIRST_LOGIN',

  INIT_USER_PREFERENCES:             'INIT_USER_PREFERENCES',
  SAVE_USER_DATA_IN_PERSISTANT_DATA: 'SAVE_USER_DATA_IN_PERSISTANT_DATA',

  GET_LOG_LEVELS:    'GET_LOG_LEVELS',
  RESET_LOG_LEVELS:  'RESET_LOG_LEVELS',
  SET_LOG_LEVELS:    'SET_LOG_LEVELS',
  GET_LOGS:          'GET_LOGS',
  GOT_LOGS:          'GOT_LOGS',
  SHOW_LOGS_POPUP:   'SHOW_LOGS_POPUP',
  SET_SUBMIT_ENGINE: 'SET_SUBMIT_ENGINE',
  SET_LOGIN_TAB:     'SET_LOGIN_TAB',

  UPDATE_USER_DMP_ACCESS_AUTHORIZATION: 'UPDATE_USER_DMP_ACCESS_AUTHORIZATION',

  SET_FORCED_CPX_READER:    'SET_FORCED_CPX_READER',
  SET_FORCED_VITALE_READER: 'SET_FORCED_VITALE_READER',

  SET_IS_MOBILE_LAYOUT:      'SET_IS_MOBILE_LAYOUT',
  SET_MOBILE_PREVIEW_ACTIVE: 'SET_MOBILE_PREVIEW_ACTIVE',
};


export const cardStatuses = {
  NotInitialized:      0,
  ValidCardInserted:   1,
  NoCardInserted:      2,
  UnknownCardInserted: 3,
  CardStatusChanged:   4,
  CardIsBlocked:       5,
  CardIsExpired:       6,
  CpxReaderError:      7,
};

export const readerSlotTypes = {
  NONE:    1,
  VITALE:  2,
  CPS:     3,
  UNKNOWN: 4,
  ERROR:   5,
};

export const readerSlotTypesLabel = {
  [readerSlotTypes.NONE]:    'Aucune carte',
  [readerSlotTypes.VITALE]:  'Carte Vitale',
  [readerSlotTypes.CPS]:     'Carte CPS',
  [readerSlotTypes.UNKNOWN]: 'Carte inconnue',
  [readerSlotTypes.ERROR]:   'Détection impossible',
};
