import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
import merge from 'lodash.merge';
import { dmpconnectConfigurationActionConstants, dmpconnectInitActionConstants } from '../constants';
var initialState = {
  applicationId: null,
  production_mode: null,
  dmpcjsProtocol: null,
  dmpcjsHttpProtocol: null,
  dmpcjsHost: null,
  dmpcjsPort: null,
  dmpcjsVersion: null,
  dmpcjsonVersion: null,
  dmpcrestVersion: null,
  apiType: null,
  air: false,
  esRESTHost: null,
  esRESTPort: null,
  serverName: null,
  webPsRootUrl: null,
  dcparams: null,
  debugActivateRemoteControl: false,
  forceDesactivateRemoteControl: false,
  tlsiServerName: null,
  dpServerName: null,
  consultOnly: false,
  airOnly: false,
  exportCdaDocument: false,
  licensingGroupId: undefined
};
function dmpconnectConnectorConfig() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case dmpconnectConfigurationActionConstants.DMPC_RESET_GLOBAL_CONFIGURATION_FROM_ENV:
      return action.payload;
    case dmpconnectInitActionConstants.DMPC_RESET_APPLICATION:
      return merge({}, state, {
        forceDesactivateRemoteControl: false
      });
    case dmpconnectInitActionConstants.DMPC_INIT_APPLICATION:
      return merge({}, state, {
        forceDesactivateRemoteControl: false
      });
    case dmpconnectConfigurationActionConstants.DMPC_SET_GLOBAL_CONFIGURATION:
      return _objectSpread({}, state, _defineProperty({}, action.key, action.value));
    default:
      return state;
  }
}
export default function getDmpconnectConnectorConfigReducer(_ref) {
  var sessionTimeout = _ref.sessionTimeout,
    applicationId = _ref.applicationId,
    production_mode = _ref.production_mode,
    dmpcjsProtocol = _ref.dmpcjsProtocol,
    dmpcjsHttpProtocol = _ref.dmpcjsHttpProtocol,
    dmpcjsHost = _ref.dmpcjsHost,
    dmpcjsPort = _ref.dmpcjsPort,
    dmpcjsVersion = _ref.dmpcjsVersion,
    apiType = _ref.apiType,
    air = _ref.air,
    esRESTHost = _ref.esRESTHost,
    esRESTPort = _ref.esRESTPort,
    esRestUrl = _ref.esRestUrl,
    serverName = _ref.serverName,
    webPsRootUrl = _ref.webPsRootUrl,
    dcparams = _ref.dcparams,
    debugActivateRemoteControl = _ref.debugActivateRemoteControl,
    forceDesactivateRemoteControl = _ref.forceDesactivateRemoteControl,
    tlsiServerName = _ref.tlsiServerName,
    dpServerName = _ref.dpServerName,
    _ref$consultOnly = _ref.consultOnly,
    consultOnly = _ref$consultOnly === void 0 ? false : _ref$consultOnly,
    _ref$airOnly = _ref.airOnly,
    airOnly = _ref$airOnly === void 0 ? false : _ref$airOnly,
    _ref$exportCdaDocumen = _ref.exportCdaDocument,
    exportCdaDocument = _ref$exportCdaDocumen === void 0 ? false : _ref$exportCdaDocumen,
    licensingGroupId = _ref.licensingGroupId;
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      sessionTimeout: sessionTimeout,
      applicationId: applicationId,
      production_mode: production_mode,
      dmpcjsProtocol: dmpcjsProtocol,
      dmpcjsHttpProtocol: dmpcjsHttpProtocol,
      dmpcjsHost: dmpcjsHost,
      dmpcjsPort: dmpcjsPort,
      dmpcjsVersion: dmpcjsVersion,
      apiType: apiType,
      air: air,
      esRESTHost: esRESTHost,
      esRESTPort: esRESTPort,
      esRestUrl: esRestUrl,
      serverName: serverName,
      webPsRootUrl: webPsRootUrl,
      dcparams: dcparams,
      debugActivateRemoteControl: debugActivateRemoteControl,
      forceDesactivateRemoteControl: forceDesactivateRemoteControl,
      tlsiServerName: tlsiServerName,
      dpServerName: dpServerName,
      consultOnly: consultOnly,
      airOnly: airOnly,
      exportCdaDocument: exportCdaDocument,
      licensingGroupId: licensingGroupId
    };
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return dmpconnectConnectorConfig(state, action);
  };
}