import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
var _JSONCommandParams;
import moment from "moment";
import { mssActionConstants } from '../../constants';
import { formatCreateFolderMssWs, formatDeleteFolderMssWs, formatDownloadAttachementFromMssWs, formatFullSearchMssMessages, formatFullSearchMssMessagesIMAP, formatGenerateMssAttachments, formatGetEmailAdressFromMssWs, formatGetFoldersFromMssWs, formatMoveMssMessages, formatRenameFolderMssWs, formatSearchMessagesFromMssWs, formatSendDocumentParams, formatSendMssEmail, formatSendMssWebEmail, formatSyncMessagesFromMssWs, formatUpdateMessagesFromMssWs } from './commandParamsFormatters';
import commands, { commandSubTypes, commandTypes, mssSubTypes } from './commands';
var JSONCommandParams = (_JSONCommandParams = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.activeFeatures, {
  type: commandTypes.WS,
  commandName: 'hl_activeFeatures'
}), commands.getTimeSynchronizationDifference, {
  type: commandTypes.WS,
  commandName: 'hl_getTimeSynchronizationDifference'
}), commands.openSession, {
  type: commandTypes.WS,
  commandName: 'hl_openSession'
}), commands.closeSession, {
  type: commandTypes.WS,
  commandName: 'hl_closeSession'
}), commands.getSessionState, {
  type: commandTypes.WS,
  commandName: 'hl_getSessionState'
}), commands.sessionExists, {
  type: commandTypes.WS,
  commandName: 'hl_sessionExists'
}), commands.updateCpxAuthenticationToken, {
  type: commandTypes.WS,
  commandName: 'hl_updateCpxAuthenticationToken'
}), commands.getCpxCard, {
  type: commandTypes.WS,
  commandName: 'hl_getCpxCard'
}), commands.getCpxStatus, {
  type: commandTypes.WS,
  commandName: 'hl_getCpxStatus'
}), commands.getCpxHpInfos, {
  type: commandTypes.WS,
  commandName: 'hl_getCpxHpInfos'
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.readCpxCard, {
  type: commandTypes.WS,
  commandName: 'hl_readCpxCard',
  timeout: 60
}), commands.getVitaleCard, {
  type: commandTypes.WS,
  commandName: 'hl_getVitaleCard'
}), commands.readVitaleCard, {
  type: commandTypes.WS,
  commandName: 'hl_readVitaleCard'
}), commands.getVitaleXmlContent, {
  type: commandTypes.WS,
  commandName: 'getObjectParameterString',
  paramsGetter: function paramsGetter(vitaleHandle) {
    return {
      i_parameter: 30,
      i_resultInBase64: 1,
      i_object: Number(vitaleHandle)
    };
  }
}), commands.getPcscReaders, {
  type: commandTypes.WS,
  commandName: 'hl_getPcscReaders'
}), commands.createDMPConnector, {
  type: commandTypes.WS,
  commandName: 'hl_createDmpConnector'
}), commands.createTLSiConnector, {
  type: commandTypes.WS,
  commandName: 'hl_createTlsiConnector'
}), commands.createEsTLsiConnector, {
  type: commandTypes.WS,
  commandName: 'hl_createEsTLsiConnector'
}), commands.createDpConnector, {
  type: commandTypes.WS,
  commandName: 'hl_createDpConnector'
}), commands.createEsDMPConnector, {
  type: commandTypes.WS,
  commandName: 'hl_createEsDmpConnector'
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.findPatient, {
  type: commandTypes.WS,
  commandName: 'hl_findPatients',
  timeout: 180
}), commands.getAccessibleDMPList, {
  type: commandTypes.WS,
  commandName: 'hl_getAccessibleDMPList',
  timeout: 600
}), commands.getDirectAuthenticationDMPStatus, {
  type: commandTypes.WS,
  commandName: 'hl_getDirectAuthenticationDMPStatus'
}), commands.getDMPStatus, {
  type: commandTypes.WS,
  commandName: 'hl_getDMPStatus'
}), commands.getCertifiedIdentity, {
  type: commandTypes.WS,
  commandName: 'hl_getCertifiedIdentity'
}), commands.findDocuments, {
  type: commandTypes.WS,
  commandName: 'hl_findDocuments',
  timeout: 600,
  longRunningTimeout: 120,
  multipleCalls: false
}), commands.updateUserDmpAccessAuthorization, {
  type: commandTypes.WS,
  commandName: 'hl_updateUserDmpAccessAuthorization'
}), commands.updateDocumentVisibilityByUniqueId, {
  type: commandTypes.WS,
  commandName: 'hl_updateDocumentVisibilityByUniqueId'
}), commands.setDMPAccessMode, {
  type: commandTypes.WS,
  commandName: 'hl_setDmpAccessMode'
}), commands.setConfidentialityLevel, {
  type: commandTypes.WS,
  commandName: 'hl_setConfidentialityLevel'
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.getDmpAdministrativeData, {
  type: commandTypes.WS,
  commandName: 'hl_getDmpAdministrativeData'
}), commands.getDocumentContentByUniqueId, {
  type: commandTypes.WS,
  commandName: 'hl_getDocumentContentByUniqueId',
  timeout: 600
}), commands.getDocumentsContentByUniqueId, {
  type: commandTypes.WS,
  commandName: 'hl_getDocumentsContentByUniqueId',
  timeout: 600
}), commands.deleteDocument, {
  type: commandTypes.WS,
  commandName: 'hl_deleteDocument'
}), commands.deleteArchivedDocument, {
  type: commandTypes.WS,
  commandName: 'hl_deleteArchivedDocument'
}), commands.updateDocumentStatusByUniqueId, {
  type: commandTypes.WS,
  commandName: 'hl_updateDocumentStatusByUniqueId'
}), commands.validateDocuments, {
  type: commandTypes.WS,
  commandName: 'hl_validateDocuments',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref) {
    var ins = _ref.ins,
      base64Content = _ref.base64Content,
      title = _ref.title,
      description = _ref.description,
      category = _ref.category,
      visibility = _ref.visibility,
      format = _ref.format,
      healthcareSetting = _ref.healthcareSetting,
      creationDate = _ref.creationDate,
      serviceStartDate = _ref.serviceStartDate,
      serviceStopDate = _ref.serviceStopDate,
      replacedDocumentId = _ref.replacedDocumentId,
      Informants = _ref.Informants,
      Performer = _ref.Performer,
      TreatingPhycisian = _ref.TreatingPhycisian,
      AdditionalAuthors = _ref.AdditionalAuthors,
      IntendedRecipients = _ref.IntendedRecipients,
      eventCodes = _ref.eventCodes,
      retrieveDocumentUuid = _ref.retrieveDocumentUuid,
      forceSchematronsValidation = _ref.forceSchematronsValidation,
      ignorePdfA1Transparency = _ref.ignorePdfA1Transparency,
      disabledPdfA1Conversion = _ref.disabledPdfA1Conversion,
      versionNumber = _ref.versionNumber,
      setIdRoot = _ref.setIdRoot,
      setIdExtension = _ref.setIdExtension,
      uniqueId = _ref.uniqueId,
      Identity = _ref.Identity,
      transcodeTypeCode = _ref.transcodeTypeCode,
      getCdaHeaders = _ref.getCdaHeaders,
      getContent = _ref.getContent,
      getCdaContent = _ref.getCdaContent,
      stylesheetBase64 = _ref.stylesheetBase64,
      structuredBody = _ref.structuredBody;
    return {
      Documents: [{
        s_oid: uniqueId,
        s_title: title,
        s_description: description,
        s_category: category,
        i_visibility: visibility,
        i_format: format,
        s_contentInBase64: base64Content,
        s_stylesheetInBase64: stylesheetBase64,
        s_creationDate: creationDate ? moment.utc(creationDate, ['DD/MM/YYYY']).local().format('YYYYMMDDHHmmssZZ') : undefined,
        s_serviceStartDate: serviceStartDate ? moment.utc(serviceStartDate, ['DD/MM/YYYY']).local().format('YYYYMMDDHHmmssZZ') : undefined,
        s_serviceStopDate: serviceStopDate ? moment.utc(serviceStopDate, ['DD/MM/YYYY']).local().format('YYYYMMDDHHmmssZZ') : undefined,
        s_replacedDocumentUniqueId: replacedDocumentId,
        EventCodes: (eventCodes || []).map(function (eventCode) {
          return {
            s_code: eventCode.code,
            s_classification: eventCode.classification,
            s_description: eventCode.description
          };
        }),
        TreatingPhysician: TreatingPhycisian,
        Performer: Performer,
        AdditionalAuthors: AdditionalAuthors,
        IntendedRecipients: IntendedRecipients,
        Informants: Informants,
        s_versionNumber: versionNumber,
        s_setIdRoot: setIdRoot,
        s_setIdExtension: setIdExtension,
        StructuredBody: structuredBody
      }],
      s_ins: ins,
      s_healthcareSetting: healthcareSetting,
      Identity: Identity,
      s_submissionSetTitle: title,
      i_transcodeTypeCode: Number(transcodeTypeCode),
      i_getCdaHeaders: Number(getCdaHeaders),
      i_getContent: Number(getContent),
      i_getCda: Number(getCdaContent),
      i_forceSchematronsValidation: forceSchematronsValidation === true ? 1 : 0,
      i_pdfa1IgnoreTransparency: ignorePdfA1Transparency === true ? 1 : 0,
      i_disablePdfa1Conversion: disabledPdfA1Conversion === true ? 1 : 0,
      i_retrieveDocumentUuid: retrieveDocumentUuid
    };
  }
}), commands.sendDocument, {
  type: commandTypes.WS,
  commandName: 'hl_sendDocument',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref2) {
    var ins = _ref2.ins,
      base64Content = _ref2.base64Content,
      title = _ref2.title,
      description = _ref2.description,
      category = _ref2.category,
      visibility = _ref2.visibility,
      format = _ref2.format,
      healthcareSetting = _ref2.healthcareSetting,
      creationDate = _ref2.creationDate,
      serviceStartDate = _ref2.serviceStartDate,
      serviceStopDate = _ref2.serviceStopDate,
      replacedDocumentId = _ref2.replacedDocumentId,
      Informants = _ref2.Informants,
      Performer = _ref2.Performer,
      TreatingPhycisian = _ref2.TreatingPhycisian,
      AdditionalAuthors = _ref2.AdditionalAuthors,
      IntendedRecipients = _ref2.IntendedRecipients,
      eventCodes = _ref2.eventCodes,
      retrieveDocumentUuid = _ref2.retrieveDocumentUuid,
      forceSchematronsValidation = _ref2.forceSchematronsValidation,
      ignorePdfA1Transparency = _ref2.ignorePdfA1Transparency,
      disabledPdfA1Conversion = _ref2.disabledPdfA1Conversion,
      versionNumber = _ref2.versionNumber,
      setIdRoot = _ref2.setIdRoot,
      setIdExtension = _ref2.setIdExtension,
      uniqueId = _ref2.uniqueId,
      Identity = _ref2.Identity,
      transcodeTypecode = _ref2.transcodeTypecode,
      stylesheetBase64 = _ref2.stylesheetBase64,
      AdditionalPatientIdentifiers = _ref2.AdditionalPatientIdentifiers,
      getDocumentContent = _ref2.getDocumentContent,
      structuredBody = _ref2.structuredBody;
    return formatSendDocumentParams(ins, base64Content, title, description, category, visibility, format, healthcareSetting, creationDate, replacedDocumentId, eventCodes, Informants, Performer, TreatingPhycisian, AdditionalAuthors, IntendedRecipients, retrieveDocumentUuid, serviceStartDate, serviceStopDate, forceSchematronsValidation, ignorePdfA1Transparency, disabledPdfA1Conversion, versionNumber, setIdRoot, setIdExtension, uniqueId, Identity, transcodeTypecode, stylesheetBase64, AdditionalPatientIdentifiers, getDocumentContent, structuredBody);
  }
}), commands.getSubmissionSetDocuments, {
  type: commandTypes.WS,
  commandName: 'hl_getSubmissionSetDocuments'
}), commands.getDocumentSubmissionSetIdByUniqueId, {
  type: commandTypes.WS,
  commandName: 'hl_getDocumentSubmissionSetIdByUniqueId'
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.createDMPAndOtp, {
  type: commandTypes.WS,
  commandName: 'hl_createDmpAndOtp'
}), commands.closeDMP, {
  type: commandTypes.WS,
  commandName: 'hl_closeDmp'
}), commands.reactivateDMP, {
  type: commandTypes.WS,
  commandName: 'hl_reactivateDmp'
}), commands.updateDmpAdministrativeData, {
  type: commandTypes.WS,
  commandName: 'hl_updateDmpAdministrativeData'
}), commands.getPatientWebAccessPdf, {
  type: commandTypes.WS,
  commandName: 'hl_getPatientWebAccessPdf'
}), commands.createAcknowledgementPdf, {
  type: commandTypes.WS,
  commandName: 'hl_createAcknowledgementPdf'
}), commands.getDmpAuthorizationsList, {
  type: commandTypes.WS,
  commandName: 'hl_getDmpAuthorizationsList'
}), commands.setWebPsRootUrl, {
  type: commandTypes.WS,
  commandName: 'hl_setWebPsRootUrl'
}), commands.getWebPsDmpUrls, {
  type: commandTypes.WS,
  commandName: 'hl_getWebPsDmpUrls'
}), commands.getOtpChannelValue, {
  type: commandTypes.WS,
  commandName: 'hl_getOtpChannelValue'
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.getInteropCodes, {
  type: commandTypes.WS,
  commandName: 'hl_getInteropCodes'
}), commands.getNosCodes, {
  type: commandTypes.WS,
  commandName: 'hl_getNosCodes'
}), commands.getCiSisCodes, {
  type: commandTypes.WS,
  commandName: 'hl_getCiSisCodes'
}), commands.getCisCodes, {
  type: commandTypes.WS,
  commandName: 'hl_getCisCodes'
}), commands.getAtcCodes, {
  type: commandTypes.WS,
  commandName: 'hl_getAtcCodes'
}), commands.getDmpParameters, {
  type: commandTypes.WS,
  commandName: 'hl_getDmpParameters'
}), commands.getInsNirFromInsC, {
  type: commandTypes.WS,
  commandName: 'hl_getInsNirFromInsC'
}), commands.getObjectParameterInt, {
  type: commandTypes.WS,
  commandName: 'getObjectParameterInt'
}), commands.clearPersistantData, {
  type: commandTypes.WS,
  commandName: 'hl_clearPersistantData'
}), commands.getPersistantData, {
  type: commandTypes.WS,
  commandName: 'hl_getPersistantData'
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.setPersistantData, {
  type: commandTypes.WS,
  commandName: 'hl_setPersistantData'
}), commands.checkUserLicenseRight, {
  type: commandTypes.WS,
  commandName: 'hl_checkUserLicenseRight'
}), commands.isDcParamsRegistered, {
  type: commandTypes.HTTP,
  endpoint: '/remotecommand/isDcParameterRegistered',
  paramsGetter: function paramsGetter(dcParams) {
    return {
      s_dcparameters64: dcParams
    };
  }
}), commands.registerDcParams, {
  type: commandTypes.HTTP,
  endpoint: '/remotecommand/registerDcParameter',
  paramsGetter: function paramsGetter(dcParams) {
    return {
      s_dcparameters64: dcParams
    };
  }
}), commands.unregisterDcParams, {
  type: commandTypes.HTTP,
  endpoint: '/remotecommand/unregisterDcParameter',
  paramsGetter: function paramsGetter(dcParams) {
    return {
      s_dcparameters64: dcParams
    };
  }
}), commands.setVitaleXmlContent, {
  type: commandTypes.WS,
  commandName: 'hl_setVitaleXmlContent'
}), commands.getAldFromVitaleCard, {
  type: commandTypes.WS,
  commandName: 'hl_getAldFromVitaleCard'
}), commands.getInsFromVitaleCard, {
  type: commandTypes.WS,
  commandName: 'hl_getInsFromVitaleCard'
}), commands.getInsFromIdentityInformation, {
  type: commandTypes.WS,
  commandName: 'hl_getInsFromIdentityInformation'
}), commands.checkIdentity, {
  type: commandTypes.WS,
  commandName: 'hl_checkInsIdentity'
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.submitInsiBatchValidationSet, {
  type: commandTypes.WS,
  commandName: 'hl_submitInsiBatchValidationSet'
}), commands.getInsiBatchValidationSetResult, {
  type: commandTypes.WS,
  commandName: 'hl_getInsiBatchValidationSetResult'
}), commands.getMssHpInfos, {
  type: commandTypes.WS,
  commandName: 'hl_getMssHpInfos'
}), commands.getEmailAdress, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  paramsGetter: function paramsGetter(rpps) {
    return formatGetEmailAdressFromMssWs(rpps);
  }
}), commands.getFoldersMSS, _defineProperty(_defineProperty({
  subType: commandSubTypes.MSS
}, mssSubTypes.WEB, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  timeout: 600,
  paramsGetter: function paramsGetter(email) {
    return formatGetFoldersFromMssWs(email);
  }
}), mssSubTypes.IMAP, {
  type: commandTypes.WS,
  commandName: 'hl_imapListFolders',
  timeout: 600,
  paramsGetter: function paramsGetter(email) {
    return {
      s_login: email
    };
  }
})), commands.createFolderMSS, _defineProperty(_defineProperty({
  subType: commandSubTypes.MSS
}, mssSubTypes.WEB, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref3) {
    var email = _ref3.email,
      folderName = _ref3.folderName,
      folderParentId = _ref3.folderParentId;
    return formatCreateFolderMssWs(email, folderName, folderParentId);
  }
}), mssSubTypes.IMAP, {
  type: commandTypes.WS,
  commandName: 'hl_imapCreateFolder',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref4) {
    var email = _ref4.email,
      folderName = _ref4.folderName,
      folderParentId = _ref4.folderParentId;
    return {
      s_login: email,
      s_folderName: folderName
    };
  }
})), commands.deleteFolderMSS, _defineProperty(_defineProperty({
  subType: commandSubTypes.MSS
}, mssSubTypes.WEB, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref5) {
    var email = _ref5.email,
      folderId = _ref5.folderId;
    return formatDeleteFolderMssWs(email, folderId);
  }
}), mssSubTypes.IMAP, {
  type: commandTypes.WS,
  commandName: 'hl_imapDeleteFolder',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref6) {
    var email = _ref6.email,
      folderId = _ref6.folderId;
    return {
      s_login: email,
      s_folderName: folderId
    };
  }
})), commands.renameFolderMSS, _defineProperty(_defineProperty({
  subType: commandSubTypes.MSS
}, mssSubTypes.WEB, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref7) {
    var email = _ref7.email,
      folderId = _ref7.folderId,
      newFolderName = _ref7.newFolderName;
    return formatRenameFolderMssWs(email, folderId, newFolderName);
  }
}), mssSubTypes.IMAP, {
  type: commandTypes.WS,
  commandName: 'hl_imapRenameFolder',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref8) {
    var email = _ref8.email,
      folderId = _ref8.folderId,
      newFolderName = _ref8.newFolderName;
    return {
      s_login: email,
      s_oldFolderName: folderId,
      s_newFolderName: newFolderName
    };
  }
})), commands.getSearchMessagesMSS, _defineProperty(_defineProperty({}, mssSubTypes.WEB, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref9) {
    var email = _ref9.email,
      folderId = _ref9.folderId,
      offset = _ref9.offset,
      limit = _ref9.limit;
    return formatSearchMessagesFromMssWs(email, folderId, offset, limit);
  }
}), mssSubTypes.IMAP, {
  type: commandTypes.WS,
  commandName: 'hl_imapListMessages',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref10) {
    var email = _ref10.email,
      folderId = _ref10.folderId;
    return {
      s_login: email,
      s_folderName: folderId
    };
  }
})), commands.getMessageContentMss, _defineProperty({}, mssSubTypes.IMAP, {
  type: commandTypes.WS,
  commandName: 'hl_imapGetMessage',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref11) {
    var email = _ref11.email,
      folderId = _ref11.folderId,
      messageId = _ref11.messageId;
    return {
      s_login: email,
      s_folderName: folderId,
      s_uid: messageId
    };
  }
})), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.getSyncMessagesMSS, _defineProperty(_defineProperty({}, mssSubTypes.WEB, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  paramsGetter: function paramsGetter(_ref12) {
    var email = _ref12.email,
      folderId = _ref12.folderId,
      token = _ref12.token;
    return formatSyncMessagesFromMssWs(email, folderId, token);
  }
}), mssSubTypes.IMAP, {
  forwardTo: mssActionConstants.IMAP_SYNC_MESSAGES
})), commands.updateMessagesMSS, _defineProperty(_defineProperty({}, mssSubTypes.WEB, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  paramsGetter: function paramsGetter(_ref13) {
    var email = _ref13.email,
      messageIds = _ref13.messageIds,
      operation = _ref13.operation;
    return formatUpdateMessagesFromMssWs(email, messageIds, operation);
  }
}), mssSubTypes.IMAP, {
  type: commandTypes.WS,
  commandName: 'hl_imapUpdateMessageFlags',
  paramsGetter: function paramsGetter(_ref14) {
    var email = _ref14.email,
      messageIds = _ref14.messageIds,
      operation = _ref14.operation,
      folderId = _ref14.folderId,
      add = _ref14.add;
    return {
      s_login: email,
      s_uid: messageIds[0],
      s_folderName: folderId,
      i_mode: add ? 1 : 2,
      Flags: [operation === 'READ' ? '\\Seen' : '']
    };
  }
})), commands.deleteMssMessages, _defineProperty(_defineProperty({}, mssSubTypes.WEB, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  paramsGetter: function paramsGetter(_ref15) {
    var email = _ref15.email,
      messageIds = _ref15.messageIds;
    return formatUpdateMessagesFromMssWs(email, messageIds, 'DELETE');
  }
}), mssSubTypes.IMAP, {
  type: commandTypes.WS,
  commandName: 'hl_imapDeleteMessage',
  paramsGetter: function paramsGetter(_ref16) {
    var email = _ref16.email,
      messageIds = _ref16.messageIds,
      folderId = _ref16.folderId;
    return {
      s_login: email,
      s_uid: messageIds[0],
      s_folderName: folderId
    };
  }
})), commands.moveMSSMessages, _defineProperty(_defineProperty({}, mssSubTypes.WEB, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  paramsGetter: function paramsGetter(_ref17) {
    var email = _ref17.email,
      messageIds = _ref17.messageIds,
      destinationFolderId = _ref17.destinationFolderId;
    return formatMoveMssMessages(email, messageIds, destinationFolderId);
  }
}), mssSubTypes.IMAP, {
  type: commandTypes.WS,
  commandName: 'hl_imapMoveMessage',
  paramsGetter: function paramsGetter(_ref18) {
    var email = _ref18.email,
      messageIds = _ref18.messageIds,
      destinationFolderId = _ref18.destinationFolderId,
      sourceFolderId = _ref18.sourceFolderId;
    return {
      s_login: email,
      s_uid: messageIds[0],
      s_sourceFolderName: sourceFolderId,
      s_destinationFolderName: destinationFolderId
    };
  }
})), commands.fullSearchMssMessages, _defineProperty(_defineProperty({}, mssSubTypes.WEB, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  paramsGetter: function paramsGetter(_ref19) {
    var email = _ref19.email,
      folderId = _ref19.folderId,
      from = _ref19.from,
      to = _ref19.to,
      subject = _ref19.subject,
      body = _ref19.body,
      dateFrom = _ref19.dateFrom,
      dateTo = _ref19.dateTo,
      hasAttachment = _ref19.hasAttachment;
    return formatFullSearchMssMessages(email, folderId, from, to, subject, body, dateFrom, dateTo, hasAttachment);
  }
}), mssSubTypes.IMAP, {
  type: commandTypes.WS,
  commandName: 'hl_imapSearchMessages',
  paramsGetter: function paramsGetter(_ref20) {
    var email = _ref20.email,
      folderId = _ref20.folderId,
      from = _ref20.from,
      to = _ref20.to,
      subject = _ref20.subject,
      body = _ref20.body,
      dateFrom = _ref20.dateFrom,
      dateTo = _ref20.dateTo,
      hasAttachment = _ref20.hasAttachment;
    return formatFullSearchMssMessagesIMAP(email, folderId, from, to, subject, body, dateFrom, dateTo, hasAttachment);
  }
})), commands.getDownloadAttachmentMSS, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref21) {
    var email = _ref21.email,
      messageId = _ref21.messageId,
      part = _ref21.part;
    return formatDownloadAttachementFromMssWs(email, messageId, part);
  }
}), commands.sendMssSmtpEmail, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssSmtpEmail',
  timeout: 600
}), commands.sendMssWebEmailSoap, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  paramsGetter: function paramsGetter(emailContent) {
    return formatSendMssWebEmail(emailContent);
  },
  timeout: 600
}), commands.sendMssWebEmailConnector, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssEmail',
  paramsGetter: function paramsGetter(emailContent) {
    return formatSendMssEmail(emailContent);
  },
  timeout: 600
}), commands.generateMssAttachments, {
  type: commandTypes.WS,
  commandName: 'hl_generateMssAttachments',
  timeout: 600,
  paramsGetter: function paramsGetter(_ref22) {
    var attachments = _ref22.attachments,
      Identity = _ref22.Identity,
      insIsNotQualified = _ref22.insIsNotQualified,
      AdditionalPatientIdentifiers = _ref22.AdditionalPatientIdentifiers;
    return _objectSpread({}, formatGenerateMssAttachments(attachments), {
      Identity: Identity,
      i_insIsNotQualified: Number(insIsNotQualified),
      AdditionalPatientIdentifiers: AdditionalPatientIdentifiers
    });
  }
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.sendMssRequest, {
  type: commandTypes.WS,
  commandName: 'hl_sendMssRequest',
  timeout: 600
}), commands.getEsInfos, {
  type: commandTypes.WS,
  commandName: 'hl_getEsInfos'
}), commands.getUserInfos, {
  type: commandTypes.WS,
  commandName: 'hl_getUserInfos'
}), commands.getDp, {
  type: commandTypes.WS,
  commandName: 'hl_sendDpRequest'
}), commands.getNdp, {
  type: commandTypes.WS,
  commandName: 'hl_getNdp'
}), commands.dpGetMedicationDispensations, {
  type: commandTypes.WS,
  subType: commandSubTypes.DP,
  commandName: 'hl_dpGetMedicationDispensations'
}), commands.dpDirectory_SearchRecord, {
  type: commandTypes.WS,
  subType: commandSubTypes.DP,
  commandName: 'hl_dpDirectory_SearchRecord'
}), commands.dpDirectory_GetRecordStatus, {
  type: commandTypes.WS,
  subType: commandSubTypes.DP,
  commandName: 'hl_dpDirectory_GetRecordStatus'
}), commands.dpDirectory_AddOrUpdateRecord, {
  type: commandTypes.WS,
  subType: commandSubTypes.DP,
  commandName: 'hl_dpDirectory_AddOrUpdateRecord'
}), commands.dpDirectory_DeleteRecord, {
  type: commandTypes.WS,
  subType: commandSubTypes.DP,
  commandName: 'hl_dpDirectory_DeleteRecord'
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.createApCVConnector, {
  type: commandTypes.WS,
  commandName: 'hl_createApcvConnector'
}), commands.getNfcCard, {
  type: commandTypes.WS,
  commandName: 'hl_getNfcCard'
}), commands.getNfcApCvUserData, {
  type: commandTypes.WS,
  commandName: 'hl_getNfcApCvUserData'
}), commands.getApCvContext, {
  type: commandTypes.WS,
  commandName: 'hl_getApCvContext'
}), commands.releaseApCvContext, {
  type: commandTypes.WS,
  commandName: 'hl_releaseApCvContext'
}), commands.getLogLevels, {
  type: commandTypes.WS,
  commandName: 'getLogLevels'
}), commands.setLogLevels, {
  type: commandTypes.WS,
  commandName: 'setLogLevels',
  paramsGetter: function paramsGetter(_ref23) {
    var dmpConnectLogLevel = _ref23.dmpConnectLogLevel,
      dmpConnectJsLogLevel = _ref23.dmpConnectJsLogLevel;
    return {
      i_dmpConnectLogLevel: Number(dmpConnectLogLevel),
      i_dmpConnectJsLogLevel: Number(dmpConnectJsLogLevel)
    };
  }
}), commands.getLogsTail, {
  type: commandTypes.WS,
  commandName: 'getLogsTail',
  paramsGetter: function paramsGetter(_ref24) {
    var getServerLog = _ref24.getServerLog,
      getDmpConnectLog = _ref24.getDmpConnectLog,
      nbServerLines = _ref24.nbServerLines,
      nbDmpConnectLines = _ref24.nbDmpConnectLines;
    return {
      i_getServerLog: Number(getServerLog),
      i_getDmpConnectLog: Number(getDmpConnectLog),
      i_serverLogNbLastLines: Number(nbServerLines),
      i_dmpConnectLogNbLastLines: Number(nbDmpConnectLines)
    };
  },
  timeout: 600
}), commands.getSessionLogsTail, {
  type: commandTypes.WS,
  commandName: 'hl_getSessionLogsTail',
  paramsGetter: function paramsGetter(_ref25) {
    var getServerLog = _ref25.getServerLog,
      getDmpConnectLog = _ref25.getDmpConnectLog,
      nbServerLines = _ref25.nbServerLines,
      nbDmpConnectLines = _ref25.nbDmpConnectLines;
    return {
      i_getServerLog: Number(getServerLog),
      i_getDmpConnectLog: Number(getDmpConnectLog),
      i_serverLogNbLastLines: Number(nbServerLines),
      i_dmpConnectLogNbLastLines: Number(nbDmpConnectLines)
    };
  },
  timeout: 600
}), commands.getSystemInformation, {
  type: commandTypes.WS,
  commandName: 'hl_getSystemInformation',
  timeout: 600
}), _defineProperty(_defineProperty(_defineProperty(_JSONCommandParams, commands.searchFhirHealthDirectory, {
  type: commandTypes.WS,
  commandName: 'hl_searchFhirHealthDirectory',
  timeout: 600
}), commands.searchFhirAllHpOfStructure, {
  type: commandTypes.WS,
  commandName: 'hl_searchFhirAllHpOfStructure',
  timeout: 600
}), commands.pscAuthenticateUserCiba, {
  type: commandTypes.WS,
  commandName: 'hl_pscAuthenticateUserCiba',
  timeout: 600
}));
export default JSONCommandParams;