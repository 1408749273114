import { dmpconnectInitActionConstants } from 'dmpconnectjsapp-base/constants';

import { dmpconnectApplicationActionConstants, } from '../constants';


export const initApplication = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_INIT_APPLICATION,
});

export const initApplicationSuccess = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_INIT_APPLICATION_SUCCESS,
});

export const initApplicationFailure = (error = null) => ({
  type: dmpconnectApplicationActionConstants.DMPC_INIT_APPLICATION_FAILURE,
  error,
});

export const resetApplication = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_RESET_APPLICATION,
});

export const setModalConfirmation = modalConfirmation => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_MODAL_CONFIRMATION,
  modalConfirmation,
});
export const setModalInformation  = information => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_MODAL_INFORMATION,
  information,
});
export const setModalError        = modalError => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_MODAL_ERROR,
  modalError,
});

export const clearModalError = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_CLEAR_MODAL_ERROR,
});

export const dashboardInit = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_DASHBOARD_INIT,
});

export const setDashboardInitOk = (status = true) => ({
  type: dmpconnectApplicationActionConstants.DMPC_DASHBOARD_INIT_OK,
  status,
});

export const monitorLicenseAfterForm = () => ({
  type: dmpconnectInitActionConstants.DMPC_LICENSE_CHECK,
});

export const readVitaleProcess = (sessionId, sectionName) => ({
  type: dmpconnectApplicationActionConstants.DMPC_READ_VITALE_PROCESS,
  sessionId,
  sectionName,
});

export const setSaasTokenValidated = validated => ({
  type: dmpconnectApplicationActionConstants.DMPC_SAAS_TOKEN_VALIDATED,
  validated,
});

export const setVitaleCardSerialNumber = serialNumber => ({
  type: dmpconnectApplicationActionConstants.SET_VITALE_CARD_SERIAL_NUMBER,
  serialNumber,
});

export const setUrlProcessed = processed => ({
  type: dmpconnectApplicationActionConstants.DMPS_SET_URL_PROCESSED,
  processed,
});

export const setLogLevels   = (dmpConnectLogLevel, dmpConnectJsLogLevel) => ({
  type: dmpconnectApplicationActionConstants.SET_LOG_LEVELS,
  dmpConnectLogLevel,
  dmpConnectJsLogLevel,
});
export const resetLogLevels = () => ({
  type: dmpconnectApplicationActionConstants.RESET_LOG_LEVELS,
});
export const getLogLevels   = () => ({
  type: dmpconnectApplicationActionConstants.GET_LOG_LEVELS,
});
export const getLogs        = (error, resetLogLevel = true) => ({
  type: dmpconnectApplicationActionConstants.GET_LOGS,
  resetLogLevel,
  error,
});
export const gotLogs        = () => ({
  type: dmpconnectApplicationActionConstants.GOT_LOGS,
});
export const showLogsPopup  = (show, error, onClose) => ({
  type: dmpconnectApplicationActionConstants.SHOW_LOGS_POPUP,
  show,
  error,
  onClose,
});

export const setForcedVitaleReader = readerName => ({
  type: dmpconnectApplicationActionConstants.SET_FORCED_VITALE_READER,
  readerName,
});

export const hardResetApplication = () => ({
  type: dmpconnectApplicationActionConstants.HARD_RESET,
});
