import { LOCATION_CHANGE }                                              from 'connected-react-router';
import {
  clearModalError,
  clearSection,
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
  logoutSuccess,
  setGlobalConfiguration,
  setPersistedConnectorConfiguration,
}                                                                       from 'dmpconnectjsapp-base/actions';
import { apiSections }                                                  from 'dmpconnectjsapp-base/constants';
import { softwareErrors }                                               from 'dmpconnectjsapp-base/errors';
import { getApiType, getConfigurationValue, }                           from 'dmpconnectjsapp-base/helpers/accessors';
import { hasError, isLoading, isReady }                                 from 'dmpconnectjsapp-base/helpers/common';
import moment                                                           from 'moment';
import { toast }                                                        from 'react-toastify';
import { call, put, select, take }                                      from 'redux-saga/effects';
import {
  requestPcscReaders,
  setForcedVitaleReader,
  setModalError,
  setPersistedAppConfiguration,
  setSaasTokenValidated,
  setTseConfiguration,
  setUrlProcessed,
}                                                                       from '../actions';
import { API_TYPES, }                                                   from '../constants';
import { createError, createErrorDetails, createModalError }            from '../errors';
import { errorTypes }                                                   from '../errors/errorConfiguration';
import { getSaasTokenConfig, getSessionId, }                            from '../helpers';
import { b64DecodeUnicode, mergeSearchAndHash, objectWithoutTheseKeys } from '../utils/dataUtils';

const checkSaasToken = function* (providedToken, saasTokenConfig) {
  const { saasToken, validatedSaasTokens = [] } = saasTokenConfig;
  let validated                                 = false;

  if (saasToken && saasToken !== '') {
    // check if token has already been validated
    if (validatedSaasTokens) {
      const validatedToken = validatedSaasTokens.some(t => t.token === saasToken);
      if (validatedToken) {
        validated = true;
      }
    }

    if (validated === false) {
      // validate the provided token agains configured one
      if (providedToken === saasToken) {
        const newValidatedTokens = [
          ...validatedSaasTokens,
          {
            token:       providedToken,
            validatedAt: moment.utc().unix(),
          },
        ];
        // set token as validated
        yield put(setPersistedAppConfiguration('validatedSaasTokens', newValidatedTokens));
        validated = true;
      }
    }
  } else {
    validated = true;
  }
  yield put(setSaasTokenValidated(validated));
  return validated;
};


const handleReadersChange = function* (readers) {
  const pcscReadersSection = yield select((state) => {
    const { dmpconnect: { [apiSections.PCSC_READERS_SECTION]: readersSection } } = state;
    return readersSection;
  });

  if (!isReady(pcscReadersSection) && !hasError(pcscReadersSection) && !isLoading(pcscReadersSection)) {
    const apiType      = yield select(getApiType);
    const sessionId    = yield select(getSessionId);
    const esRestVitale = yield select((state) => {
      const { dmpconnectPersistedConnectorConfiguration } = state;
      return getConfigurationValue('esRestVitale', dmpconnectPersistedConnectorConfiguration);
    });

    if (
      (!!sessionId && apiType !== API_TYPES.REST)
      || (apiType === API_TYPES.REST && esRestVitale)
    ) {
      yield put(clearSection(apiSections.PCSC_READERS_SECTION));
      yield put(requestPcscReaders(false));

      yield take([
                   dmpCommandSuccessContextualizedType(apiSections.PCSC_READERS_SECTION),
                   dmpCommandFailureContextualizedType(apiSections.PCSC_READERS_SECTION),
                 ]);

      yield call(handleReadersChange, readers);
    }
  }
  const { Readers: pcscReaders = [] } = pcscReadersSection;
  const { vitaleReader }              = readers;

  if (vitaleReader) {
    const index = pcscReaders.findIndex(r => r.s_name.toLowerCase() === vitaleReader.toLowerCase());
    yield put(setPersistedConnectorConfiguration('vitaleCardReader', index));
    yield put(setForcedVitaleReader(index));
  }
};

export const handleLocationChange = function* () {
  while (true) {
    const { payload }                              = yield take(LOCATION_CHANGE);
    const { location: { pathname, hash, search } } = payload;
    if (pathname === '/login-token') {
      yield put(logoutSuccess());
    }

    const cleanedHash         = hash.slice(1);
    const cleanedSearch       = search.slice(1);
    const mergedSearchAndHash = mergeSearchAndHash(cleanedSearch, cleanedHash);
    const parameters          = Object.fromEntries(new URLSearchParams(mergedSearchAndHash));
    const
      {
        params,
      }                       = parameters || {};
    const saasTokenConfig     = yield select(getSaasTokenConfig);

    let decodedParams;
    let parsedParams;

    try {
      yield put(clearModalError());

      let tseId;
      let saasToken;
      let readers;
      let licensingGroupId;

      if (params) {
        // base64 decode
        decodedParams = b64DecodeUnicode(decodeURIComponent(params));

        // parse json
        parsedParams = JSON.parse(decodedParams);

        ({
          tseId,
          saasToken,
          readers,
          licensingGroupId,
        } = parsedParams || {});
      }

      let redirectParams = parsedParams || {};

      if (licensingGroupId) {
        yield put(setGlobalConfiguration('licensingGroupId', licensingGroupId));
        redirectParams = objectWithoutTheseKeys(redirectParams, ['licensingGroupId']);
        toast.success('L\'identifiant du groupe de licence a été mis à jour.', {
          autoClose: 2000,
          toastId:   'licensingGroupId',
        });
      }

      const saasTokenValidated = yield call(checkSaasToken, saasToken, saasTokenConfig);
      if (saasTokenValidated) {

        if (readers) {
          yield call(handleReadersChange, readers);
          redirectParams = objectWithoutTheseKeys(redirectParams, ['readers']);
        }


        if (tseId) {
          yield put(setTseConfiguration('whoami', tseId));
          yield put(setTseConfiguration('active', true));
          toast.success('Identifiant utilisateur TSE mis à jour', {
            autoClose: 2000,
            toastId:   'whoami',
          });
        }
      }
    } catch (e) {
      console.log('error params', e);
      const error      = createError(errorTypes.SoftwareErrors, softwareErrors.PARAMS_INVALID_JSON);
      const details    = [
        createErrorDetails('provided', {
          base64:  parameters,
          decoded: decodedParams,
        }),
      ];
      const modalError = createModalError(error, details);
      yield put(setModalError(modalError));
    }
    yield put(setUrlProcessed(true));
  }
};
