import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
import { structureIdTypes } from '../constants';
import { isReady } from '../helpers/common';
export var practiceLocationSources = {
  CARD: 'card',
  FHIR: 'fhir',
  CUSTOM: 'custom'
};
export var practiceLocationSourcesLabel = {
  CARD: 'Situation en carte',
  FHIR: 'Annuaire santé',
  CUSTOM: 'Situation personnalisée'
};
export var getStructureIdTypeFromId = function getStructureIdTypeFromId() {
  var structureId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var firstDigit = structureId.charAt(0);
  var structureType = Object.values(structureIdTypes).find(function (sit) {
    return sit.firstDigit === Number(firstDigit);
  }) || {};
  return structureType.value || undefined;
};
export var isStructureIdValid = function isStructureIdValid(type, id) {
  if (!type || !id) {
    return false;
  }
  var structureType = structureIdTypes[type];
  var regexp = new RegExp("^\\".concat(structureType.type, "{").concat(structureType.length, "}$"));
  return regexp.test(id);
};
export var getStructureIdErrorMessage = function getStructureIdErrorMessage(type) {
  if (!type) {
    return '';
  }
  var structureType = structureIdTypes[type];
  var charType = structureType.type === 'd' ? 'chiffres' : 'caractères';
  return "L'identifiant doit contenir ".concat(structureType.length, " ").concat(charType, ".");
};
export var extractPracticeLocationFromState = function extractPracticeLocationFromState(state, rpps, serialNumber) {
  var _state$dmpconnectCPxC = state.dmpconnectCPxConfiguration,
    _state$dmpconnectCPxC2 = _state$dmpconnectCPxC === void 0 ? {} : _state$dmpconnectCPxC,
    savedPracticeLocation = _state$dmpconnectCPxC2[rpps]; // if we didn't save any practice location, OR the cpx serialNumber changed
  if (!savedPracticeLocation || serialNumber && serialNumber !== savedPracticeLocation.cpxSerialNumber) {
    return undefined;
  }
  var practiceLocationSetting = savedPracticeLocation.practiceLocationSetting,
    customPracticeLocationStructureName = savedPracticeLocation.customPracticeLocationStructureName,
    customPracticeLocationStructureIdType = savedPracticeLocation.customPracticeLocationStructureIdType,
    customPracticeLocationStructureId = savedPracticeLocation.customPracticeLocationStructureId,
    customPracticeLocationActivitySector = savedPracticeLocation.customPracticeLocationActivitySector,
    practiceLocationSettingDetails = savedPracticeLocation.practiceLocationSettingDetails,
    situationSource = savedPracticeLocation.situationSource,
    cpeRpps = savedPracticeLocation.cpeRpps;
  var definedPracticeLocation = {
    cpeRpps: cpeRpps,
    situationSource: situationSource
  };
  if (practiceLocationSetting !== null && Number(practiceLocationSetting) >= 0) {
    definedPracticeLocation = _objectSpread({}, definedPracticeLocation, {
      cpsPracticeLocationIndice: Number(practiceLocationSetting),
      situationSource: practiceLocationSources.CARD
    });
    if (practiceLocationSettingDetails) {
      definedPracticeLocation = _objectSpread({}, definedPracticeLocation, {
        structureName: practiceLocationSettingDetails.s_practiceLocationName,
        structureIdType: getStructureIdTypeFromId(practiceLocationSettingDetails.s_practiceLocationStructureId || ''),
        structureId: (practiceLocationSettingDetails.s_practiceLocationStructureId || '').substring(1),
        activitySector: practiceLocationSettingDetails.s_practiceLocationActivity
      });
    }
  } else {
    definedPracticeLocation = _objectSpread({}, definedPracticeLocation, {
      cpsPracticeLocationIndice: -1,
      structureName: customPracticeLocationStructureName,
      structureIdType: customPracticeLocationStructureIdType,
      structureId: customPracticeLocationStructureId,
      activitySector: customPracticeLocationActivitySector,
      situationSource: !situationSource ? practiceLocationSources.CUSTOM : situationSource
    });
  }
  return _objectSpread({}, definedPracticeLocation, {
    savedPracticeLocation: savedPracticeLocation
  });
};
export var extractFhirSituation = function extractFhirSituation(situations) {
  if (!isReady(situations)) {
    return [];
  }
  var _situations$PsList = situations.PsList,
    PsList = _situations$PsList === void 0 ? [] : _situations$PsList;
  return PsList.filter(function (s) {
    return Object.keys(s.Organization || {}).length > 0 && s.Organization.Identifiers && s.Organization.Identifiers.length > 0 && s.i_isActive === 1;
  }).map(function (s) {
    var _s$Organization = s.Organization,
      _s$Organization2 = _s$Organization === void 0 ? {} : _s$Organization,
      _s$Organization2$s_na = _s$Organization2.s_name,
      orgName = _s$Organization2$s_na === void 0 ? '' : _s$Organization2$s_na,
      _s$Organization2$s_ac = _s$Organization2.s_activitySectorCode,
      s_activitySectorCode = _s$Organization2$s_ac === void 0 ? 'SA43' : _s$Organization2$s_ac,
      _s$Organization2$Iden = _s$Organization2.Identifiers,
      Identifiers = _s$Organization2$Iden === void 0 ? [] : _s$Organization2$Iden;
    var idnst = Identifiers.find(function (id) {
      return id.s_type === 'IDNST';
    }) || {};
    return {
      group: practiceLocationSources.FHIR,
      label: orgName,
      activity: s_activitySectorCode,
      structureId: (idnst.s_value || '').substring(1),
      structureIdType: getStructureIdTypeFromId(idnst.s_value || '')
    };
  });
};
export var extractCardSituation = function extractCardSituation(card) {
  return card.PracticeLocations.map(function (p, i) {
    return {
      practiceLocationIndex: i,
      group: practiceLocationSources.CARD,
      label: p.s_practiceLocationName,
      activity: p.s_practiceLocationActivity,
      structureId: (p.s_practiceLocationStructureId || '').substring(1),
      structureIdType: getStructureIdTypeFromId(p.s_practiceLocationStructureId || '')
    };
  });
};