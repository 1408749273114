import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
export var errorTypes = {
  ApiErrors: 1,
  // Erreurs DMPConnect
  DMPErrors: 2,
  // Erreurs DMP / XDS
  CurlErrors: 3,
  // Erreurs techniques
  VitaleErrors: 4,
  // Erreurs remontés par DMPConnect
  MssErrors: 5,
  // Erreurs remontés par DMPConnect
  TLSiErrors: 6,
  // Erreurs remontés par DMPConnect
  DPErrors: 7,
  // Erreurs remontés par DMPConnect
  dmpConnectRestSoftwareErrors: 5000,
  // Erreurs remontés par DMPConnect-REST
  DmpConnectJsSoftwareErrors: 6000,
  // Erreurs remontés par DMPConnectJS
  SoftwareErrors: 7000,
  // React App error,
  RemoteErrors: 8000,
  // Erreurs mode pilotage
  JWTErrors: 9000,
  apCvErrors: 9999
};
export var apiErrors = {
  INVALID_CALL: 1,
  // The function should not have been called. Some operation should have been done before.
  INVALID_PARAMETER: 2,
  // At least one of the function parameters - or the setup of a handle given in parameter - is invalid.
  OPERATION_FAILED: 3,
  // An unexpected error occurred.
  CONCURRENT_ACCESS_ERROR: 4,
  // One of the objects given to the function is already in use by another function.
  CPX_EXPIRED: 5,
  // The given pincode is invalid.
  CPX_READ_ERROR: 6,
  // Either the CPx reader is not connected, or its setup is incorrect, or a transmission error occurred.
  CPX_INVALIDPINCODE: 7,
  // The given PIN code is invalid. The number of PIN code tries of the smartcard has been decremented.
  CPX_BLOCKED: 8,
  // Card is blocked. Too many pin code errors.
  CPX_MISSING: 9,
  // No CPS found in the smartcard reader.
  CPX_STATUSCHANGED: 10,
  // Card status has changed since last access: the card has been removed, or has been changed. Please recreate a new CPx object.
  CPX_UNKNOWNCARD: 11,
  // The card found in the smartcard reader is not a CPS3. Either its a CPS2ter or a non-CPS card.
  LICENSE_INTEGRATOR_DISABLED: 12,
  // The integrator does not exists or it's right are expired. Please contact icanopee support team.
  LICENSE_VERSION_DISABLED: 13,
  // This version of the product is disabled.
  LICENSE_USER_DISABLED: 14,
  // The user rights are expired.
  LICENSE_USER_DOES_NOT_EXISTS: 15,
  // The user is not known from the licensing system.
  LICENSE_UNKNOWN_STATE: 16,
  // Unknown error during the check of the licence rights.
  INVALID_ins: 17,
  // Unknown error during the check of the licence rights.
  DEMO_CARD_IN_PROD: 18,
  // Using demonstration Vitale cards is forbidden in production
  DOCUMENT_NOT_FOUND: 19,
  // Document was not found. Check the UUID or the Unique ID of the document.
  NO_NIR_FOUND: 20,
  // can be returned by function getInsNirFromInsC when patient has an INS-C but no INS-NIR.
  VITALE_CHANGED: 23,
  // Indicate that vitale card has changed between last readVitaleCard and it's usage in TLSi function.
  NTP_FAILED: 24,
  // DmpConnect initialization failed: NTP initialization failed.
  NETWORK_TEST_FAILED: 25,
  // DmpConnect initialization failed: network test failed.
  APCV_CONTEXT_EXPIRED: 46 // The ApCV context is expired
};
export var dmpErrors = {
  DMPSystemError: 1,
  DMPFound: 2,
  DMPPatientNotFound: 3,
  DMPClosed: 4,
  DMPLPSNotValidated: 5,
  DMPErrorLPS: 6,
  DMPAccessForbidden: 7,
  DMPAuthorizationNotFound: 8,
  DMPAuthorizationExpired: 9,
  DMPAccessDeniedByRightsService: 10,
  DMPInvalidData: 11,
  DMPInvalidRequest: 12,
  DMPConcurrentAccess: 13,
  DMPInvalidSignature: 14,
  DMPInvalidCertificate: 15,
  DMPVirusFound: 16,
  DMPDataAlreadyUsed: 17,
  DMPOutOfEXperimentalArea: 18,
  DMPTooManyResult: 19,
  DMPActorNotFound: 20,
  DMPDocumentFormatError: 21,
  DMPPatientAccessNotFound: 22,
  DMPPatientAccessAlreadyExists: 23,
  DMPPatientAccessOTPNotFound: 24,
  DMPPatientAccessOTPAlreadyExists: 25,
  DMPPatientAccessOTPDeleteForbidden: 26,
  unknownError: 58,
  DMPIdentityCertificationFailure: 61,
  DMPIdentityCertificationBusy: 62,
  XDSRegistryBusy: 63
};
export var softwareErrors = {
  CPX_REMOVED: 1,
  INVALID_SEARCH_PARAMS: 2,
  DMPCONNECTJS_MISSING_OR_UNREACHABLE: 3,
  DMPCONNECJS_WRONG_VERSION: 4,
  UNEXPECTED_ERROR: 5,
  WEBSOCKET_ERROR: 6,
  WEBSOCKET_TIMEOUT: 7,
  DEMO_CPX_IN_PROD: 8,
  WRONG_DC_PARAMS_CONFIG: 9,
  LOGINCHECK_MISSING_VALUE: 10,
  PARAMS_INVALID_JSON: 11,
  INS_NIR_INVALID_FORMAT: 12,
  INSC_INVALID_FORMAT: 13,
  API_LOGIN_MISSING_VALUE: 14,
  AIR_AUTH_MODE_INVALID: 15,
  NO_PRACTICE_LOCATIONS: 16,
  JWT_SESSION_EXPIRED: 17,
  INVALID_ES_SETTINGS: 18,
  WEBAPP_UPDATED: 19,
  INVALID_MSS_OPERATORS_CONFIG: 20,
  INSI_EXPORT_ERROR: 98,
  CONNECTOR_REST_UNREACHABLE: 99
};
export var jsSoftwareErrors = {
  TECHNICAL_ERROR: 1,
  FUNCTIONAL_ERROR: 2,
  EXPIRED_SESSION: 3,
  INVALID_PASSWORD: 4,
  INVALID_ES_USER: 5,
  ES_NOT_FOUND: 6
};
export var esrestSoftwareErrors = {
  TECHNICAL_ERROR: 1,
  FUNCTIONAL_ERROR: 2
};
export var jwtErrors = {
  MISSING_CLAIMS: 1,
  INVALID_SIGNATURE: 2,
  INVALID_TOKEN: 3,
  EXPIRED_TOKEN: 4,
  EMPTY_TOKEN: 5,
  INVALID_USER_INFOS: 6
};
export var vitaleErrors = {
  ERR_INCONNUE: 10,
  ERR_CARTE_ABSENTE: 11,
  ERR_CARTE: 12,
  ERR_LECTEUR: 13,
  ERR_CARTE_INCONNUE: 15,
  ERR_CARTE_MUETTE: 17,
  ERR_LOGICIEL: 18,
  ERR_GALSS: 20,
  ERR_SESSION_OUVERTE: 21,
  ERR_EXCLUSIVITE: 23,
  ERR_TIMEOUT: 25,
  ERR_SESSION_INCONNUE: 26,
  ERR_CPS_RETIREE: 30,
  ERR_VALIDITE_CPS: 36,
  ERR_CARTE_INCORRECTE: 37,
  ERR_CARTE_INVALIDE: 38,
  ERR_CARTE_BLOQUEE: 39,
  ERR_CARTE_SATUREE: 40,
  ERR_PINCODE_INACTIF: 41,
  ERR_CARTE_TEST: 42,
  ERR_APICPS: 50,
  ERR_APICPS_NON_CHARGEE: 51,
  ERR_PARAM_NULL: 52,
  ERR_PARAM_ADRESSE: 53,
  ERR_PARAM_VALEUR: 54,
  ERR_PARAM_TAILLE: 55,
  ERR_INIT_FICHIER: 32,
  ERR_INIT_MODE: 33,
  ERR_INIT_TIMER: 34,
  ERR_INIT_RESSOURCE_VIT: 31,
  ERR_INIT_RESSOURCE_CPS: 35,
  ERR_INIT_TABLE: 47,
  ERR_VERSION_TABLE: 45,
  ERR_STRUCTURE_TABLE: 44,
  ERR_INIT_CSV: 43,
  ERR_STRUCTURE_CSV: 48,
  ERR_INIT_HAB: 56,
  ERR_VERSION_HAB: 57,
  ERR_STRUCTURE_HAB: 58,
  ERR_INIT_SEDICA: 59,
  ERR_TIMER_CTRL: 60,
  ERR_TIMER_INAC: 61,
  ERR_DETECT_AUTO: 70,
  WAR_DONNEES_ADM: 104
};
export var apCvErrors = {
  WRONG_CARD_TYPES: 1
};
export var remoteErrors = {
  UNKNONW_ACTION: 'UnknownAction',
  INVALID_FORMAT: 'InvalidFormat',
  NO_PS_LOGGED_IN: 'NoHPConnected',
  INVALID_INS: 'InvalidINS',
  REJECTED_BY_USER: 'RejectedByUser',
  NOT_ENOUGH_PARAMS: 'NotEnoughParameters',
  DMP_FOUND: 'DmpAlreadyExist',
  DMP_ERROR: 'DMPError',
  REFUSED_ACTION: 'RefusedAction',
  DMP_NOT_CONNECTED: 'DmpNotConnected'
};
export var dmpToRemoteErrors = _defineProperty(_defineProperty(_defineProperty({}, dmpErrors.DMPFound, remoteErrors.DMP_FOUND), dmpErrors.DMPTooManyResult, remoteErrors.DMP_ERROR), dmpErrors.DMPIdentityCertificationFailure, remoteErrors.DMP_ERROR);
export var apiToRemoteErrors = _defineProperty(_defineProperty({}, apiErrors.INVALID_PARAMETER, remoteErrors.INVALID_FORMAT), apiErrors.INVALID_ins, remoteErrors.INVALID_INS);
export var createError = function createError(errorType, errorCode) {
  return {
    i_apiErrorType: errorType,
    i_apiErrorCode: errorCode
  };
};
export var dpErrors = {
  DP_DELETED: 5,
  DP_REFUSED: 6,
  NO_DP: 7
};