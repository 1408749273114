import PropTypes      from 'prop-types';
import React          from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip        from 'react-bootstrap/Tooltip';

export default function TitleTooltip({
  id, text, children, placement, showTooltip,
}) {
  if (!showTooltip) {
    return children;
  }
  
  return (
    <OverlayTrigger
      key={`overlay-${id}`}
      delay={{ show: 500, hide: 100 }}
      placement={placement}
      overlay={(
        <Tooltip id={`tooltip-${id}`}><span style={{ whiteSpace: 'pre-line' }}>{text}</span></Tooltip>
      )}
    >
      {children}
    </OverlayTrigger>
  );
}

TitleTooltip.propTypes = {
  id         : PropTypes.string.isRequired,
  children   : PropTypes.node.isRequired,
  text       : PropTypes.node.isRequired,
  placement  : PropTypes.string,
  showTooltip: PropTypes.bool,
};

TitleTooltip.defaultProps = {
  placement  : 'auto',
  showTooltip: true,
};
