import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import merge from 'lodash.merge';
import { apiSections, dmpconnectInitActionConstants } from '../constants';
var initialState = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, apiSections.INTEROPCODES, {}), apiSections.DMP_PARAMETERS, {}), apiSections.NOS_CODES, {}), apiSections.CISIS_CODES, {});
function dmpconnectInteropCodes() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case dmpconnectInitActionConstants.DMPC_SET_INTEROP_CODES:
      return merge({}, state, _defineProperty({}, apiSections.INTEROPCODES, action.codes));
    case dmpconnectInitActionConstants.DMPC_SET_DMP_PARAMETERS:
      return merge({}, state, _defineProperty({}, apiSections.DMP_PARAMETERS, action.params));
    case dmpconnectInitActionConstants.DMPC_SET_NOS_CODES:
      return merge({}, state, _defineProperty({}, apiSections.NOS_CODES, action.codes));
    case dmpconnectInitActionConstants.DMPC_SET_CISIS_CODES:
      return merge({}, state, _defineProperty({}, apiSections.CISIS_CODES, action.codes));
    case dmpconnectInitActionConstants.DMPC_SET_ATC_IMMUNIZATION_CODES:
      return merge({}, state, _defineProperty({}, apiSections.ATC_IMMUNIZATION_CODES, action.codes));
    default:
      return state;
  }
}
export default dmpconnectInteropCodes;