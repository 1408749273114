import { push }             from 'connected-react-router';
import { initApplication }  from 'dmpconnectjsapp-base/actions';
import { resetApplication } from '../actions';

export const errorActions = {
  REINITIALIZE:       'REINITIALIZE',
  CLOSE_SESSION:      'CLOSE_SESSION',
  LOGIN:              'LOGIN',
  REFRESH_PAGE:       'REFRESH_PAGE',
  GO_TO_DASHBOARD:    'GO_TO_DASHBOARD',
  GO_TO_DP_DASHBOARD: 'GO_TO_DP_DASHBOARD',
  READ_VITALE_CARD:   'READ_VITALE_CARD',
  NONE:               'NONE',
};

export const errorActionsMessages = {
  [errorActions.REINITIALIZE]:       'Réinitialiser',
  [errorActions.CLOSE_SESSION]:      'Redémarrer l\'application',
  [errorActions.LOGIN]:              'Se reconnecter',
  [errorActions.REFRESH_PAGE]:       'Rafraîchir la page',
  [errorActions.GO_TO_DASHBOARD]:    'Retourner au dashboard',
  [errorActions.GO_TO_DP_DASHBOARD]: 'Retourner au dashboard',
  [errorActions.READ_VITALE_CARD]:   'Lire la carte vitale',
  [errorActions.NONE]:               'OK',
};

export const executeErrorAction = (errorDescription, dispatch) => {
  switch (errorDescription.action) {
    case errorActions.REINITIALIZE:

      dispatch(resetApplication());
      window.location.href = '/';
      // dispatch(logout());
      break;
    case errorActions.LOGIN:

      window.location.href = '/';
      // dispatch(logout());
      break;
    case errorActions.CLOSE_SESSION:
      window.location.href = '/';
      // dispatch(logout());
      break;
    case errorActions.REFRESH_PAGE:
      dispatch(initApplication());
      break;
    case errorActions.GO_TO_DASHBOARD:
      dispatch(push('/home/'));
      break;
    case errorActions.GO_TO_DP_DASHBOARD:
      dispatch(push('/home/'));
      break;
    case errorActions.READ_VITALE_CARD:
      dispatch(push('/home/vitale/reset'));
      break;
    default:
      break;
  }
};
