import getCommandAction                         from 'dmpconnectjsapp-base/actions/config/generateCommands';
import { dmpconnectActionConstants }            from 'dmpconnectjsapp-base/constants';
import { dmpconnectApplicationActionConstants } from '../constants';

export const getAction = (
  commandName,
  section,
  values,
  context,
) => (async (dispatch, getState) => {
  // check the token if needed

  return dispatch(getCommandAction(
    commandName,
    section,
    values,
    {
      ...context
    },
  ));
});

export const readCpxProcess = (pinCode, readerNumber, doLogin = true, forceLoginStepTwo = false) => ({
  type: dmpconnectActionConstants.DMPC_READ_CPX_PROCESS,
  pinCode,
  readerNumber,
  doLogin,
  forceLoginStepTwo,
});

export const selectINS = (ins) => {
  let tmpIns = ins;
  if (ins === undefined || ins === '' || ins === 0) {
    tmpIns = '-1';
  }

  return {
    type: dmpconnectActionConstants.DMPC_SELECT_INS,
    ins:  tmpIns,
  };
};

export const selectCertifiedIdentity = subSection => ({
  type:              dmpconnectActionConstants.DMPC_SELECT_CERTIFIED_ID,
  certifiedIdentity: subSection,
});

export const requestPcscReaders = synchronous => ({
  type: dmpconnectActionConstants.DMPC_REQUES_PCSC_READERS,
  synchronous,
});

export const getInsFromVitaleCard = action => ({
  type: dmpconnectApplicationActionConstants.DMPC_GET_INS_FROM_VITALE_CARD,
  ...action,
});

export const checkAndGetINSiIdentity = action => ({
  type: dmpconnectApplicationActionConstants.DMPC_CHECK_AND_GET_INS,
  ...action,
});
