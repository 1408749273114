import base64 from 'base-64';
import utf8   from 'utf8';
import UTIF   from 'utif';

export const hashCode = (string) => {
  let hash = 0;

  for (let i = 0; i < string.length; i += 1) {
    const char = string.charCodeAt(i);
    hash       = ((hash << 5) - hash) + char;
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Decode unicode
 * @param str
 * @returns {*|string|number[]|Promise<void>}
 */
export const b64DecodeUnicode = (str) => {
  const data  = str.replace(/[\n\r]+/g, '');
  const bytes = base64.decode(data);
  try {
    return utf8.decode(bytes);
  } catch (error) {
    return bytes;
  }
};

export const b64EncodeUnicode = (str) => {
  const bytes = utf8.encode(str);
  try {
    return base64.encode(bytes);
  } catch (error) {
    return bytes;
  }
};


export const base64ToArrayBuffer = (base64ToBufferized) => {
  const binaryString = b64DecodeUnicode(base64ToBufferized);
  const len          = binaryString.length;
  const bytes        = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};


/**
 * Taken from https://github.com/photopea/UTIF.js
 * TIFF arrayBuffer to PNG base64
 * @param arrayBuffer
 * @returns {string}
 */
export const tiffToPng = (arrayBuffer) => {
  const ifds = UTIF.decode(arrayBuffer);
  let vsns   = ifds;
  let ma     = 0;
  let
    page     = vsns[0];
  if (ifds[0].subIFD) vsns = vsns.concat(ifds[0].subIFD);
  for (let i = 0; i < vsns.length; i++) {
    const img = vsns[i];
    if (img.t258 == null || img.t258.length < 3) continue;
    const ar = img.t256 * img.t257;
    if (ar > ma) {
      ma   = ar;
      page = img;
    }
  }
  UTIF.decodeImage(arrayBuffer, page, ifds);
  const rgba = UTIF.toRGBA8(page);
  const w    = page.width;
  const
    h        = page.height;
  const cnv  = document.createElement('canvas');
  cnv.width  = w;
  cnv.height = h;
  const ctx  = cnv.getContext('2d');
  const
    imgd     = ctx.createImageData(w, h);
  for (let i = 0; i < rgba.length; i++) imgd.data[i] = rgba[i];
  ctx.putImageData(imgd, 0, 0);
  return cnv.toDataURL();
};

export const generateRandomId = len => Math.random().toString(36).substr(2, len);

export function generateId(len) {
  // const arr = new Uint8Array((len || 40) / 2);
  // window.crypto.getRandomValues(arr);
  // return Array.from(arr, dec2hex).join('');

  return `${Math.random().toString(36).substr(2, 9)}-${Math.random().toString(36).substr(2, 9)}`;
}

export function generateUniqueId() {
  return `${generateRandomId(8)}-${generateRandomId(4)}-${generateRandomId(4)}-${generateRandomId(4)}-${generateRandomId(12)}`;
}

export function objectToQueryParams(params) {
  return Object.entries(params).map(([key, value]) => {
    if (value) {
      return `${key}=${encodeURIComponent(value)}`;
    }

    return undefined;
  }).join('&');
}

export function getObjectKeyFromPath(key, obj) {
  return key.split('.').reduce((a, b) => a && a[b], obj);
}

export function mergeSearchAndHash(search, hash) {
  const toMerge = [];
  if (search) toMerge.push(search);
  if (hash) toMerge.push(hash);
  return toMerge.join('&');
}

export const isUrlValid = url => url.match(/http(s)?:\/\/.(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(\.[a-z]{2,6}\b)?([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);

export const isEmailValid = (email) => {
//  We get a more practical implementation of RFC 5322 if we omit IP addresses,
//  domain-specific addresses, the syntax using double quotes and square brackets.
//  It will still match 99.99% of all email addresses in actual use today.
//
// [a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@
//   (?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?

  const regex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  return regex.test((email || '').toLowerCase());
};

export const objectKeysToLowerCase = function (input) {
  if (typeof input !== 'object') return input;
  if (Array.isArray(input)) return input.map(objectKeysToLowerCase);
  return Object.keys(input).reduce((newObj, key) => {
    const val    = input[key];
    const newVal = (typeof val === 'object') && val !== null ? objectKeysToLowerCase(val) : val;
    Object.assign(newObj, { [key.toLowerCase()]: newVal });
    return newObj;
  }, {});
};

export const humanFileSize = function (size) {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / (1024 ** i)).toFixed(2) * 1} ${['o', 'ko', 'Mo', 'Go', 'To'][i]}`;
};

export const sortDesc = (a, b) => {
  if (a < b) return 1;
  if (a > b) return -1;
  return 1;
};

export const objectWithoutTheseKeys = (object, keys) => Object.entries(object).reduce((result, [key, value]) => {
  if (!keys.includes(key)) {
    return { ...result, [key]: value };
  }
  return result;
}, {});

export const getCurrentPathname = () => {
  const parsedUrl = new URL(window.location.href);
  return parsedUrl.pathname;
};

export const compareArrays = (a, b) => a.length === b.length && a.every((element, index) => element === b[index]);

export const zeroPad = (num, places) => String(num).padStart(places, '0')
