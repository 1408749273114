import { dmpCommandSuccessContextualizedType } from 'dmpconnectjsapp-base/actions';
import commands                                from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections }                         from 'dmpconnectjsapp-base/constants';
import { put, take }                           from 'redux-saga/effects';
import { getAction }                           from '../actions';

export const hardResetApplication = function* () {
  yield put(getAction(
    commands.clearPersistantData,
    apiSections.CLEAR_PERSISTANT_DATA,
    undefined,
    { contextExtra: { refresh: false } },
  ));

  yield take(dmpCommandSuccessContextualizedType(apiSections.CLEAR_PERSISTANT_DATA));

  localStorage.clear();
  indexedDB.deleteDatabase('efficience_commands');
  window.location.href = '/';
};
