import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _toConsumableArray from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _objectWithoutProperties from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
import _slicedToArray from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import { apiSections } from '../../constants';
import { dpEtabTypesLabel, ndpOrigin } from '../../constants/dp';
import { cipToUcd } from '../../utils/cipToUcd';
import { extractDate } from '../accessors';
import { isReady } from '../common';
export var getNdpOrigin = function getNdpOrigin(vitaleIndex) {
  return vitaleIndex === -1 ? ndpOrigin.IDENTITY : ndpOrigin.VITALE;
};
export var splitNdp = function splitNdp(ndp) {
  var _ndp$split = ndp.split('|'),
    _ndp$split2 = _slicedToArray(_ndp$split, 6),
    name = _ndp$split2[0],
    given = _ndp$split2[1],
    birthDay = _ndp$split2[2],
    birthRank = _ndp$split2[3],
    vitaleCardSerialNumber = _ndp$split2[4],
    key = _ndp$split2[5];
  return {
    name: name,
    given: given,
    birthDay: birthDay,
    birthRank: birthRank,
    vitaleCardSerialNumber: vitaleCardSerialNumber,
    key: key
  };
};
export var formatStatusResults = function formatStatusResults(enregistrements) {
  return enregistrements.map(function (enr) {
    return _objectSpread({}, splitNdp(enr.ndp), enr);
  });
};
export var addUcdToDispensations = function addUcdToDispensations(dispensations) {
  return dispensations.map(function (dispensation) {
    var Products = dispensation.Products,
      restDispensation = _objectWithoutProperties(dispensation, ["Products"]);
    var products = Products.map(function (product) {
      var _product$Identificati = product.Identification,
        s_cipCode = _product$Identificati.s_cipCode,
        s_ucdCode = _product$Identificati.s_ucdCode,
        restId = _objectWithoutProperties(_product$Identificati, ["s_cipCode", "s_ucdCode"]),
        i_amount = product.i_amount,
        i_ucdAmount = product.i_ucdAmount,
        restProduct = _objectWithoutProperties(product, ["Identification", "i_amount", "i_ucdAmount"]);
      var ucdCode = s_ucdCode;
      var ucdAmount = i_ucdAmount;
      if (!ucdCode) {
        var ucd = cipToUcd.find(function (c) {
          return [c.CIP7, c.CIP13].includes(s_cipCode);
        });
        if (ucd) {
          ucdCode = ucd.UCD7;
          ucdAmount = ucd.ucdQtyMultiplicator * i_amount;
        }
      }
      return _objectSpread({}, restProduct, {
        Identification: _objectSpread({}, restId, {
          s_cipCode: s_cipCode,
          s_ucdCode: ucdCode
        }),
        i_amount: i_amount,
        i_ucdAmount: ucdAmount
      });
    });
    return _objectSpread({
      Products: products
    }, restDispensation);
  });
};
export var flattenDispensiations = function flattenDispensiations(dispensations) {
  return addUcdToDispensations(dispensations).reduce(function (result, current) {
    var products = current.Products.map(function (product) {
      var codes = cipToUcd.find(function (c) {
        return [c.CIP7, c.CIP13].includes(product.Identification.s_cipCode);
      });
      var dmpcjsCip7;
      var dmpcjsCip13;
      if (product.Identification.s_cipCode.length === 7) {
        dmpcjsCip7 = product.Identification.s_cipCode;
        dmpcjsCip13 = undefined;
      } else if (product.Identification.s_cipCode.length === 13) {
        dmpcjsCip13 = product.Identification.s_cipCode;
        dmpcjsCip7 = undefined;
      }
      return {
        organizationType: dpEtabTypesLabel[current.i_structureType],
        manufacturedProduct: product.Identification.s_name,
        cipCode7: codes ? codes.CIP7 : dmpcjsCip7,
        cipCodeJS: product.Identification.s_cipCode,
        cipCode13: codes ? codes.CIP13 : dmpcjsCip13,
        cipQuantity: product.i_amount,
        ucdCode: product.Identification.s_ucdCode,
        ucdQty: product.i_ucdAmount,
        posology: product.s_dosage || '',
        rectification: product.s_pharmacistCorrection || '',
        date: extractDate(current.s_date, 'YYYYMMDDHHmmss')
      };
    });
    return [].concat(_toConsumableArray(result), _toConsumableArray(products));
  }, []);
};
export var updateDpSearchResults = function updateDpSearchResults(state, action) {
  var searchResults = state[apiSections.DP_SEARCH_RECORD];
  if (isReady(searchResults)) {
    var _searchResults$Answer = searchResults.Answer,
      _searchResults$Answer2 = _searchResults$Answer === void 0 ? {} : _searchResults$Answer,
      enregistrements = _searchResults$Answer2.enregistrements,
      restAnswer = _objectWithoutProperties(_searchResults$Answer2, ["enregistrements"]),
      rest = _objectWithoutProperties(searchResults, ["Answer"]);
    var newEnregistrements = _toConsumableArray(enregistrements);
    var _action$data = action.data,
      Answer = _action$data.Answer,
      i_requestCode = _action$data.i_requestCode,
      s_ndp = action.command.s_ndp;
    if (i_requestCode === 200) {
      newEnregistrements = newEnregistrements.map(function (enr) {
        return enr.ndp === Answer.ndp ? _objectSpread({}, Answer) : enr;
      });
    } else if (i_requestCode === 204) {
      newEnregistrements = newEnregistrements.filter(function (enr) {
        return enr.ndp !== s_ndp;
      });
    }
    return _objectSpread({}, state, _defineProperty({}, apiSections.DP_SEARCH_RECORD, _objectSpread({}, rest, {
      Answer: _objectSpread({}, restAnswer, {
        enregistrements: newEnregistrements
      })
    })));
  }
  return state;
};