import { apiSections }                  from 'dmpconnectjsapp-base/constants';
import { getApiType }                   from 'dmpconnectjsapp-base/helpers/accessors';
import { hasError, isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import PropTypes                        from 'prop-types';
import React                            from 'react';
import Button                           from 'react-bootstrap/Button';
import { connect }                      from 'react-redux';
import { Link }                         from 'react-router-dom';
import { getLogLevels }                 from '../../../dmpconnect/actions';
import { API_TYPES }                    from '../../../dmpconnect/constants';
import { areLogsDetailed }              from '../../../dmpconnect/helpers/logs';
import { formatVersion }                from '../../../dmpconnect/utils/version';
import env                              from '../../../envVariables';
import About                            from '../Header/About';
import TitleTooltip                     from '../TitleTooltip';

function Footer({
                  apiType, connectorVersion, dispatch, logLevelSection,
                }) {
  React.useEffect(() => {
    if (apiType !== API_TYPES.REST && !isReady(logLevelSection) && !isLoading(logLevelSection) && !hasError(logLevelSection)) {
      dispatch(getLogLevels());
    }
  }, [isReady(logLevelSection), isLoading(logLevelSection), hasError(logLevelSection)]);

  return (
    <div id="footer">
      <div className="text-center">
          <span>
            Efficience Vitale Reader version
            {' '}
            {formatVersion(env.REACT_APP_VERSION)}
          </span>
        {' - '}
        <span>
            {apiType === API_TYPES.REST && 'DmpConnect-ES REST'}
          {[API_TYPES.WS, API_TYPES.JSPOST].includes(apiType) && 'DmpConnectJS'}
          {apiType === API_TYPES.JSON && 'DmpConnect-JSON'}
          {apiType !== API_TYPES.REST && connectorVersion ? ` version ${connectorVersion}` : ''}
          </span>
        {' - '}
        <span>
            Copyright 2000-2180, icanopée. Tous droits réservés
          </span>
        {' - '}
        <a
          href="https://www.icanopee.fr/politique-de-confidentialite/"
          target="_blank"
          rel="noopener noreferrer"
          className="typography-small-link"
        >
          Mentions légales & politique de confidentialité
        </a>
        {' - '}
        <About/>

        {areLogsDetailed(logLevelSection) && (
          <>
            {' - '}
            <TitleTooltip
              id="logs-level-tooltip"
              text={(
                <>
                  Les journaux détaillés sont activés.
                  <br/>
                  Ils permettent de mieux diagnostiquer les problèmes mais peuvent ralentir l&apos;application.
                </>
              )}
            >
              <Button
                className="btn-unstyled typography-small-link btn btn-link"
                variant="link"
                to={'/logs'}
              >
                Diagnostics activés
              </Button>
            </TitleTooltip>
          </>
        )}

        {Number(env.REACT_APP_FOOTER_LOGS_LINK) === 1 && (
          <>
            {' - '}
            <Button
              className="btn-unstyled typography-small-link btn btn-link"
              variant="link"
              as={Link}
              to="/logs"
            >
              <i className="la la-tools"/>
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

Footer.defaultProps = {
  connectorVersion: null,
  logLevelSection:  null,
};

Footer.propTypes = {
  dispatch:         PropTypes.func.isRequired,
  apiType:          PropTypes.string.isRequired,
  connectorVersion: PropTypes.string,
  logLevelSection:  PropTypes.object,
};

function mapStateToProps(state) {
  const {
          dmpconnect: {
                        [apiSections.SESSION_SECTION]: {
                                                         s_serviceVersion,
                                                       },
                        [apiSections.GET_LOG_LEVELS]:  logLevelSection,
                      } = {},
        } = state;

  return {
    apiType:          getApiType(state),
    connectorVersion: s_serviceVersion,
    logLevelSection,
  };
}

export default connect(mapStateToProps)(Footer);
