import { dmpconnectActionConstants } from '../constants';
export var sendCommand = function sendCommand(command, context) {
  return {
    type: dmpconnectActionConstants.DMPC_SEND_COMMAND,
    command: command,
    context: context
  };
};
export var cancelCommand = function cancelCommand(section, subSection) {
  return {
    type: dmpconnectActionConstants.DMPC_CANCEL_COMMAND,
    context: {
      section: section,
      subSection: subSection
    }
  };
};
export var dmpSynchronousCommandFailure = function dmpSynchronousCommandFailure(data, context, command) {
  var silentError = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return {
    type: dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_FAILURE,
    data: data,
    command: command,
    context: context,
    silentError: silentError
  };
};
export var dmpSynchronousCommandSuccess = function dmpSynchronousCommandSuccess(data, context, command) {
  return {
    type: dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_SUCCESS,
    data: data,
    command: command,
    context: context
  };
};
export var dmpCommandFailure = function dmpCommandFailure(data, context, command) {
  var silentError = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return {
    type: dmpconnectActionConstants.DMPC_COMMAND_FAILURE,
    data: data,
    command: command,
    context: context,
    silentError: silentError
  };
};
export var dmpCommandTooLong = function dmpCommandTooLong(context, command) {
  return {
    type: dmpconnectActionConstants.DMPC_COMMAND_TOO_LONG,
    command: command,
    context: context
  };
};
export var dmpCommandStopped = function dmpCommandStopped(context) {
  return {
    type: dmpconnectActionConstants.DMPC_COMMAND_STOP,
    context: context
  };
};
export var dmpCommandStartContextualizedType = function dmpCommandStartContextualizedType(section) {
  return "DMPC_COMMAND_".concat(section.toUpperCase(), "_START");
};
export var dmpCommandTooLongContextualizedType = function dmpCommandTooLongContextualizedType(section) {
  return "DMPC_COMMAND_".concat(section.toUpperCase(), "_TOO_LONG");
};
export var dmpCommandStartContextualized = function dmpCommandStartContextualized(context, command) {
  return {
    type: dmpCommandStartContextualizedType(context.section),
    command: command,
    context: context
  };
};
export var dmpCommandTooLongContextualized = function dmpCommandTooLongContextualized(context, command) {
  return {
    type: dmpCommandTooLongContextualizedType(context.section),
    command: command,
    context: context
  };
};
export var dmpCommandFailureContextualizedType = function dmpCommandFailureContextualizedType(section) {
  return "DMPC_COMMAND_".concat(section.toUpperCase(), "_FAILURE");
};
export var dmpCommandSuccessContextualizedType = function dmpCommandSuccessContextualizedType(section) {
  return "DMPC_COMMAND_".concat(section.toUpperCase(), "_SUCCESS");
};
export var dmpCommandFailureContextualized = function dmpCommandFailureContextualized(data, context, command) {
  return {
    type: dmpCommandFailureContextualizedType(context.section),
    data: data,
    command: command,
    context: context
  };
};
export var dmpCommandSuccess = function dmpCommandSuccess(data, context, command) {
  return {
    type: dmpconnectActionConstants.DMPC_COMMAND_SUCCESS,
    data: data,
    command: command,
    context: context
  };
};
export var dmpCommandSuccessContextualized = function dmpCommandSuccessContextualized(data, context, command) {
  return {
    type: dmpCommandSuccessContextualizedType(context.section),
    data: data,
    command: command,
    context: context
  };
};
export var dmpRemoteCommandFailure = function dmpRemoteCommandFailure(data, context, command) {
  var silentError = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return {
    type: dmpconnectActionConstants.DMPC_REMOTE_COMMAND_FAILURE,
    data: data,
    command: command,
    context: context,
    silentError: silentError
  };
};
export var dmpRemoteCommandStart = function dmpRemoteCommandStart(context, command) {
  return {
    type: 'DMPC_REMOTE_COMMAND_START',
    command: command,
    context: context
  };
};
export var dmpRemoteCommandFailureContextualizedType = function dmpRemoteCommandFailureContextualizedType(section) {
  return "DMPC_REMOTE_COMMAND_".concat(section.toUpperCase(), "_FAILURE");
};
export var dmpRemoteCommandSuccessContextualizedType = function dmpRemoteCommandSuccessContextualizedType(section) {
  return "DMPC_REMOTE_COMMAND_".concat(section.toUpperCase(), "_SUCCESS");
};
export var dmpRemoteCommandFailureContextualized = function dmpRemoteCommandFailureContextualized(data, context, command) {
  return {
    type: dmpRemoteCommandFailureContextualizedType(context.section),
    data: data,
    command: command,
    context: context
  };
};
export var dmpRemoteCommandSuccess = function dmpRemoteCommandSuccess(data, context, command) {
  return {
    type: dmpconnectActionConstants.DMPC_REMOTE_COMMAND_SUCCESS,
    data: data,
    command: command,
    context: context
  };
};
export var dmpRemoteCommandSuccessContextualized = function dmpRemoteCommandSuccessContextualized(data, context, command) {
  return {
    type: dmpRemoteCommandSuccessContextualizedType(context.section),
    data: data,
    command: command,
    context: context
  };
};
export var clearSection = function clearSection(section) {
  return {
    type: dmpconnectActionConstants.DMPC_CLEAR_SECTION,
    section: section
  };
};
export var clearSectionResults = function clearSectionResults(section) {
  return {
    type: dmpconnectActionConstants.DMPC_CLEAR_SECTION_RESULTS,
    section: section
  };
};
export var clearSubSection = function clearSubSection(section, subSection) {
  return {
    type: dmpconnectActionConstants.DMPC_CLEAR_SUB_SECTION,
    section: section,
    subSection: subSection
  };
};
export var clearData = function clearData() {
  return {
    type: dmpconnectActionConstants.DMPC_CLEAR_DATA
  };
};
export var isDcParamsRegistered = function isDcParamsRegistered(dcParams) {
  return {
    type: dmpconnectActionConstants.DMPC_SEND_HTTP_COMMAND,
    endpoint: '/remotecommand/isDcParameterRegistered',
    command: {
      s_dcparameters64: dcParams || undefined
    },
    context: {
      section: apiSections.IS_REGISTERED_DC_PARAMS
    },
    silentError: true
  };
};
export var registerDcParams = function registerDcParams(dcParams) {
  return {
    type: dmpconnectActionConstants.DMPC_SEND_HTTP_COMMAND,
    endpoint: '/remotecommand/registerDcParameter',
    command: {
      s_dcparameters64: dcParams || undefined
    },
    context: {
      section: apiSections.REGISTER_DC_PARAMS
    }
    // silentError: true,
  };
};
export var unRegisterDcParams = function unRegisterDcParams(dcParams) {
  return {
    type: dmpconnectActionConstants.DMPC_SEND_HTTP_COMMAND,
    endpoint: '/remotecommand/unregisterDcParameter',
    command: {
      s_dcparameters64: dcParams || undefined
    },
    context: {
      section: apiSections.UNREGISTER_DC_PARAMS
    }
    // silentError: true,
  };
};
export var clearSession = function clearSession() {
  return {
    type: dmpconnectActionConstants.DMPC_CLEAR_SESSION
  };
};
export var readCpxProcess = function readCpxProcess(pinCode, readerNumber) {
  var doLogin = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return {
    type: dmpconnectActionConstants.DMPC_READ_CPX_PROCESS,
    pinCode: pinCode,
    readerNumber: readerNumber,
    doLogin: doLogin
  };
};
export var initALD = function initALD() {
  return {
    type: dmpconnectActionConstants.DMPC_INIT_ALD
  };
};
export var initALDOk = function initALDOk() {
  return {
    type: dmpconnectActionConstants.DMPC_INIT_ALD_OK
  };
};