import { dmpconnectConfigurationActionConstants } from '../constants';
export var setUserConfiguration = function setUserConfiguration(cpxId, config) {
  var persist = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_SET_USER_CONFIGURATION,
    cpxId: cpxId,
    config: config,
    persist: persist
  };
};
export var resetGlobalConfiguration = function resetGlobalConfiguration(payload) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_RESET_GLOBAL_CONFIGURATION_FROM_ENV,
    payload: payload
  };
};
export var setGlobalConfiguration = function setGlobalConfiguration(key, value) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_SET_GLOBAL_CONFIGURATION,
    key: key,
    value: value
  };
};
export var setAppConfiguration = function setAppConfiguration(key, value) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_SET_GLOBAL_CONFIGURATION,
    key: key,
    value: value
  };
};
export var resetESConfiguration = function resetESConfiguration(payload) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_RESET_ES_CONFIGURATION_FROM_ENV,
    payload: payload
  };
};
export var setESConfiguration = function setESConfiguration(key, value) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_SET_ES_CONFIGURATION,
    key: key,
    value: value
  };
};
export var resetDpConfiguration = function resetDpConfiguration(payload) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_RESET_DP_CONFIGURATION_FROM_ENV,
    payload: payload
  };
};
export var setDpConfiguration = function setDpConfiguration(key, value) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_SET_DP_CONFIGURATION,
    key: key,
    value: value
  };
};
export var setTseConfiguration = function setTseConfiguration(key, value) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_SET_TSE_CONFIGURATION,
    key: key,
    value: value
  };
};
export var resetINSiConfiguration = function resetINSiConfiguration(payload) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_RESET_INSI_CONFIGURATION_FROM_ENV,
    payload: payload
  };
};
export var setINSiConfiguration = function setINSiConfiguration(key, value) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_SET_INSI_CONFIGURATION,
    key: key,
    value: value
  };
};
export var resetALDiConfiguration = function resetALDiConfiguration(payload) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_RESET_ALDI_CONFIGURATION_FROM_ENV,
    payload: payload
  };
};
export var setALDiConfiguration = function setALDiConfiguration(key, value) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_SET_ALDI_CONFIGURATION,
    key: key,
    value: value
  };
};
export var setPersistedConnectorConfiguration = function setPersistedConnectorConfiguration(key, value) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_CONNECTOR_CONFIGURATION,
    key: key,
    value: value
  };
};
export var resetApCvConfiguration = function resetApCvConfiguration(payload) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_RESET_APCV_CONFIGURATION_FROM_ENV,
    payload: payload
  };
};
export var setApCvConfiguration = function setApCvConfiguration(key, value) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_SET_APCV_CONFIGURATION,
    key: key,
    value: value
  };
};
export var resetMssConfiguration = function resetMssConfiguration(payload) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_RESET_MSS_CONFIGURATION_FROM_ENV,
    payload: payload
  };
};
export var setMssConfiguration = function setMssConfiguration(key, value) {
  return {
    type: dmpconnectConfigurationActionConstants.DMPC_SET_MSS_CONFIGURATION,
    key: key,
    value: value
  };
};