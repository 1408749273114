import { setModalError } from 'dmpconnectjsapp-base/actions';
import {
  createError,
  errorTypes,
  softwareErrors
}                        from 'dmpconnectjsapp-base/errors';
import {
  handleAsynchronousCommand,
  handleHTTPCommand,
  handleHTTPSynchronousCommand,
  handleSynchronousCommand,
}                        from 'dmpconnectjsapp-base/sagas/connectorSagas';
import {
  handleJS2LightSequence,
}                        from 'dmpconnectjsapp-base/sagas/initializationSagas';
import {
  handleMonitoring,
}                        from 'dmpconnectjsapp-base/sagas/monitoringSagas';

import { REHYDRATE }                            from 'redux-persist/lib/constants';
import { all, call, put, spawn, take, }         from 'redux-saga/effects';
import { mapStackTrace }                        from 'sourcemapped-stacktrace';
import { handleCallbacks }                      from '.';
import { storages }                             from '../../reducers';
import { createErrorDetails, createModalError } from '../errors';
import { handleDmpconnectErrors }               from './connectorSagas';
import { handleLocationChange }                 from './locationSaga';
import { handleLogsCallbacks }                  from './logsSaga';

const mapStackTracePromise = e => new Promise((resolve, reject) => {
  mapStackTrace(e.stack, mappedStack => resolve(mappedStack));
});

const deleteLocalStorageIfIndexedDBOK = (action, keyToCheck, versionToCheck) => {
  const {
          key,
          payload: {
                     _persist: { version } = {},
                   } = {},
        } = action;

  if (key === keyToCheck && Number(version) === Number(versionToCheck)) {
    localStorage.removeItem(`persist:${keyToCheck}`);
  }
};

export default function* rootSaga() {
  if (storages && storages.length > 0) {
    let rehydrated           = false;
    const rehydratedStorages = [];
    while (rehydrated === false) {
      const action = yield take(REHYDRATE); // Wait for rehydrate to prevent sagas from running with empty store
      rehydratedStorages.push(action.key);

      deleteLocalStorageIfIndexedDBOK(action, 'efficience_commands', 2);
      deleteLocalStorageIfIndexedDBOK(action, 'efficience_interop_codes', 2);

      if (storages.every(s => rehydratedStorages.includes(s))) {
        rehydrated = true;
      }
    }
  }


  const sagas = [
    { saga: handleCallbacks },
    { saga: handleSynchronousCommand },
    { saga: handleAsynchronousCommand },
    { saga: handleHTTPCommand },
    { saga: handleHTTPSynchronousCommand },
    { saga: handleDmpconnectErrors },
    { saga: handleJS2LightSequence }, // séquence d'initialisation JS
    { saga: handleLocationChange },
    { saga: handleMonitoring, arg: 'vitale' },
    { saga: handleLogsCallbacks },
  ];

  yield all(sagas.map(({ saga, arg }) => spawn(function* () {
    while (true) {
      try {
        yield call(saga, arg);
        break;
      } catch (e) {
        const mappedStack = yield call(mapStackTracePromise, e);

        // console.error('unexpected error', e);
        const errorModal = createError(
          errorTypes.SoftwareErrors,
          softwareErrors.UNEXPECTED_ERROR,
        );

        const details = [createErrorDetails('Error', {
          name:     e.name,
          message:  e.message,
          toString: e.toString(),
          stack:    mappedStack.join('\n'),
        })];
        yield put(setModalError(createModalError(errorModal, details)));
      }
    }
  })));
}
