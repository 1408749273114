import { dmpCommandSuccessContextualizedType }     from 'dmpconnectjsapp-base/actions';
import { apiSections }                             from 'dmpconnectjsapp-base/constants';
import merge                                       from 'lodash.merge';
import env                                         from '../../envVariables';
import { dmpconnectConfigurationActionConstants, } from '../constants';

const initialStateFromEnv = {
  saasToken:                            env.REACT_APP_SAAS_TOKEN || null,
  vitaleAutoRead:                       Number(env.REACT_APP_VITALE_AUTO_READ) === 1,
  hideConfig:                           Number(env.REACT_APP_HIDE_CONFIG) === 1,
  hideFooter:                           Number(env.REACT_APP_HIDE_FOOTER) === 1,
  missingConnectorErrorMessage:         env.REACT_APP_MISSING_CONNECTOR_ERROR_MESSAGE,
  missingConnectorErrorMessageIsBase64: Number(env.REACT_APP_MISSING_CONNECTOR_ERROR_MESSAGE_IS_BASE64) === 1,

  dmpConnectLogLevel:   Number(env.REACT_APP_DEFAULT_DMPC_LOG_LEVEL),
  dmpConnectJsLogLevel: Number(env.REACT_APP_DEFAULT_DMPCJS_LOG_LEVEL),

  vitalePatientExportMethod:           env.REACT_APP_VITALE_PATIENT_EXPORT_METHOD,
  vitalePatientExportURL:              env.REACT_APP_VITALE_PATIENT_EXPORT_URL,
  vitalePatientExportArgsMapper:       env.REACT_APP_VITALE_PATIENT_EXPORT_ARGS_MAPPER,
  vitalePatientExportLabel:            env.REACT_APP_VITALE_PATIENT_EXPORT_LABEL,
  vitalePatientExportExtraArgsFromURL: env.REACT_APP_VITALE_PATIENT_EXPORT_EXTRA_ARGS_FROM_URL,
};

const initialState = {


  validatedSaasTokens: [],
  envCheckSum:         null,

  ...initialStateFromEnv,
};


export function dmpConnectPersistedAppConfiguration(state = initialState, action) {
  switch (action.type) {
    case dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_CONFIGURATION:
      return merge({}, state, { [action.key]: action.value });
    case dmpconnectConfigurationActionConstants.DMPC_RESET_PERSIST_APP_CONFIGURATION_FROM_ENV:
      return merge({}, state, initialStateFromEnv);
    case dmpCommandSuccessContextualizedType(apiSections.SET_LOG_LEVELS):
      return merge({}, state, {
        dmpConnectLogLevel:   action.command.i_dmpConnectLogLevel,
        dmpConnectJsLogLevel: action.command.i_dmpConnectJsLogLevel,
      });
    default:
      return state;
  }
}
