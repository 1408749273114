import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
import base64 from 'base-64';
import moment from 'moment';
import { mssReceiptNotificationTypes, structureIdTypes, validateINSiOIDS } from '../../constants';
import { documentVisibilityStatuses } from '../../helpers/findDocuments';
import { esLoginTypes } from '../../reducers/dmpconnectESConfiguration';
import { b64EncodeUnicode } from '../../utils/dataUtils';
export var formatOpenSessionParams = function formatOpenSessionParams(_ref) {
  var esConfig64 = _ref.esConfig64,
    esMssConfig64 = _ref.esMssConfig64,
    esMssImapConfig64 = _ref.esMssImapConfig64,
    esMssSmtpConfig64 = _ref.esMssSmtpConfig64,
    esMssWsConfig64 = _ref.esMssWsConfig64,
    dcParams = _ref.dcParams,
    tseWhoami = _ref.tseWhoami,
    esId = _ref.esId,
    prodMode = _ref.prodMode,
    _ref$applicationId = _ref.applicationId,
    applicationId = _ref$applicationId === void 0 ? 'efficience' : _ref$applicationId,
    _ref$sessionTimeout = _ref.sessionTimeout,
    sessionTimeout = _ref$sessionTimeout === void 0 ? 10800 : _ref$sessionTimeout,
    licensingGroupId = _ref.licensingGroupId;
  return {
    i_timeoutInSeconds: sessionTimeout,
    MssParameters: {
      s_numHomologation: 'icanopee_dmpcjs_msssample',
      s_producerAddress: '1 rue de la gaiete\n01234 Bon espoir\nTel : 01 02 03 04 05',
      s_softwareDetails: 'DmpConnect-JS (MSS) de la societe Icanopee (contact contact@icanopee.fr)'
    },
    s_esConfig64: esConfig64 || undefined,
    s_esMssConfig64: esMssConfig64 || undefined,
    s_esMssImapConfig64: esMssImapConfig64 || undefined,
    s_esMssSmtpConfig64: esMssSmtpConfig64 || undefined,
    s_esMssWsConfig64: esMssWsConfig64 || undefined,
    s_esId: esId || undefined,
    s_dcparameters64: dcParams || undefined,
    s_tseCredentials: tseWhoami || undefined,
    s_applicationId: applicationId,
    i_getDcparamInformations: 1,
    s_updateCheckChannel: prodMode === true ? 'prod' : 'dev',
    i_disableUpdateCheck: 0,
    s_licensingGroupId: licensingGroupId
  };
};
export var formatGetCardParams = function formatGetCardParams(readerNumber, readerName) {
  return {
    i_usePcsc: 1,
    i_readerNumber: Number(readerNumber),
    s_readerName: readerName || ''
  };
};
export var formatGetNfcCardParams = function formatGetNfcCardParams(readerNumber) {
  return {
    i_readerNumber: Number(readerNumber),
    s_readerName: '' // Currently ignored.
  };
};
var getPracticeLocation = function getPracticeLocation(_ref2) {
  var cpsPracticeLocationIndice = _ref2.cpsPracticeLocationIndice,
    practiceSetting = _ref2.practiceSetting,
    customPracticeLocationStructureName = _ref2.customPracticeLocationStructureName,
    customPracticeLocationStructureIdType = _ref2.customPracticeLocationStructureIdType,
    customPracticeLocationStructureId = _ref2.customPracticeLocationStructureId,
    customPracticeLocationActivitySector = _ref2.customPracticeLocationActivitySector;
  var PracticeLocation = undefined;
  if (cpsPracticeLocationIndice === null) {
    var structureType = structureIdTypes[customPracticeLocationStructureIdType];
    if (structureType) {
      PracticeLocation = {
        s_practiceLocationName: customPracticeLocationStructureName,
        s_practiceLocationStructureId: "".concat(structureType.firstDigit).concat(customPracticeLocationStructureId),
        s_practiceLocationActivitySector: customPracticeLocationActivitySector,
        s_practiceLocationPracticeSettings: practiceSetting
      };
    }
  }
  return PracticeLocation;
};
export var formatCreateDmpConnector = function formatCreateDmpConnector(pinCode, serverName, practiceSetting, cpsPracticeLocationIndice, customPracticeLocationStructureName, customPracticeLocationStructureIdType, customPracticeLocationStructureId, customPracticeLocationActivitySector, speciality) {
  var params = {
    s_pinCode: pinCode,
    s_dmpUrl: serverName,
    i_transactionsTimeout: 3600,
    s_practiceSetting: practiceSetting,
    i_cpsPracticeLocationIndice: Number(cpsPracticeLocationIndice || 0),
    PracticeLocation: getPracticeLocation({
      cpsPracticeLocationIndice: cpsPracticeLocationIndice,
      practiceSetting: practiceSetting,
      customPracticeLocationStructureName: customPracticeLocationStructureName,
      customPracticeLocationStructureIdType: customPracticeLocationStructureIdType,
      customPracticeLocationStructureId: customPracticeLocationStructureId,
      customPracticeLocationActivitySector: customPracticeLocationActivitySector
    })
  };
  if (speciality) {
    params = _objectSpread({}, params, {
      Hp: {
        s_specialty: speciality
      }
    });
  }
  return params;
};
export var formatCreateTLSiConnector = function formatCreateTLSiConnector(pinCode, serverName, practiceSetting, cpsPracticeLocationIndice, cpxLoginType, customPracticeLocationStructureName, customPracticeLocationStructureIdType, customPracticeLocationStructureId, customPracticeLocationActivitySector) {
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, cpxLoginType === 'OPENID' ? 's_authenticationToken' : 's_pinCode', pinCode), "s_tlsiUrl", serverName), "i_transactionsTimeout", 3600), "s_practiceSetting", practiceSetting), "i_cpsPracticeLocationIndice", Number(cpsPracticeLocationIndice || 0)), "PracticeLocation", getPracticeLocation({
    cpsPracticeLocationIndice: cpsPracticeLocationIndice,
    practiceSetting: practiceSetting,
    customPracticeLocationStructureName: customPracticeLocationStructureName,
    customPracticeLocationStructureIdType: customPracticeLocationStructureIdType,
    customPracticeLocationStructureId: customPracticeLocationStructureId,
    customPracticeLocationActivitySector: customPracticeLocationActivitySector
  }));
};
export var formatCreateEsTLSiConnector = function formatCreateEsTLSiConnector(_ref4) {
  var serverName = _ref4.serverName,
    practiceLocationName = _ref4.practiceLocationName,
    activitySector = _ref4.activitySector,
    practiceSetting = _ref4.practiceSetting,
    hpName = _ref4.hpName,
    hpGiven = _ref4.hpGiven,
    hpProfession = _ref4.hpProfession,
    hpProfessionOid = _ref4.hpProfessionOid,
    hpSpeciality = _ref4.hpSpeciality,
    hpInternalId = _ref4.hpInternalId,
    hpAuthenticationMode = _ref4.hpAuthenticationMode;
  return {
    s_tlsiUrl: serverName,
    i_transactionsTimeout: 3600,
    PracticeLocation: {
      s_practiceLocationName: practiceLocationName,
      s_practiceLocationActivitySector: activitySector,
      s_practiceLocationPracticeSettings: practiceSetting
    },
    Hp: {
      s_hpName: hpName,
      s_hpGiven: hpGiven,
      s_hpProfession: hpProfession,
      s_hpProfessionOid: hpProfessionOid,
      s_hpSpeciality: hpSpeciality,
      s_hpInternalId: hpInternalId,
      i_hpAuthenticationMode: hpAuthenticationMode
    }
  };
};
export var formatCreateDpConnector = function formatCreateDpConnector(pinCode, serverUrl, practiceSetting, cpsPracticeLocationIndice, cpxLoginType) {
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, cpxLoginType === 'OPENID' ? 's_authenticationToken' : 's_pinCode', pinCode), "s_dpUrl", serverUrl), "i_transactionsTimeout", 25), "s_practiceSetting", practiceSetting), "i_getConnectionHandle", 1), "i_cpsPracticeLocationIndice", Number(cpsPracticeLocationIndice));
};
export var formatCreateApCvConnector = function formatCreateApCvConnector(pinCode, serverUrl, practiceSetting, cpsPracticeLocationIndice, cpxLoginType) {
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, cpxLoginType === 'OPENID' ? 's_authenticationToken' : 's_pinCode', pinCode), "s_apcvUrl", serverUrl), "i_transactionsTimeout", 3600), "s_practiceSetting", practiceSetting), "i_getConnectionHandle", 1), "i_cpsPracticeLocationIndice", Number(cpsPracticeLocationIndice));
};
export var formatCreateEsDmpConnector = function formatCreateEsDmpConnector(_ref7) {
  var loginType = _ref7.loginType,
    serverName = _ref7.serverName,
    practiceLocationName = _ref7.practiceLocationName,
    activitySector = _ref7.activitySector,
    practiceSetting = _ref7.practiceSetting,
    hpName = _ref7.hpName,
    hpGiven = _ref7.hpGiven,
    hpProfession = _ref7.hpProfession,
    hpProfessionOid = _ref7.hpProfessionOid,
    hpSpeciality = _ref7.hpSpeciality,
    hpInternalId = _ref7.hpInternalId,
    hpAuthenticationMode = _ref7.hpAuthenticationMode,
    login = _ref7.login,
    password = _ref7.password;
  if (loginType === esLoginTypes.CONNECTOR_LOGIN_PASSWD) {
    return {
      s_dmpUrl: serverName,
      i_transactionsTimeout: 3600,
      s_login: login,
      s_password: password,
      s_passwordSha1: password,
      s_practiceSetting: practiceSetting
    };
  }
  return {
    s_dmpUrl: serverName,
    i_transactionsTimeout: 3600,
    PracticeLocation: {
      s_practiceLocationName: practiceLocationName,
      s_practiceLocationActivitySector: activitySector,
      s_practiceLocationPracticeSettings: practiceSetting
    },
    Hp: {
      s_hpName: hpName,
      s_hpGiven: hpGiven,
      s_hpProfession: hpProfession,
      s_hpProfessionOid: hpProfessionOid,
      s_hpSpeciality: hpSpeciality,
      s_hpInternalId: hpInternalId,
      i_hpAuthenticationMode: hpAuthenticationMode
    }
  };
};
export var formatGetAccessibleDMPListParams = function formatGetAccessibleDMPListParams(_ref8) {
  var type = _ref8.type,
    date = _ref8.date,
    psOwnerId = _ref8.psOwnerId;
  return {
    s_type: type,
    s_date: date,
    s_psOwnerId: psOwnerId
  };
};
export var formatGetCertifiedIdentityParams = function formatGetCertifiedIdentityParams(nirOd, nirIndividu, birthDate, birthRank) {
  return {
    s_nirOD: nirOd,
    s_nirIndividu: nirIndividu,
    s_birthDate: birthDate,
    i_birthRank: birthRank
  };
};
export var formatUpdateUserDmpAccessAuthorizationParams = function formatUpdateUserDmpAccessAuthorizationParams(ins, action, treatingPhysician) {
  return {
    s_ins: ins,
    i_setTreatingPhysician: treatingPhysician,
    i_action: action
  };
};
export var formatUpdateUserDmpAccessAuthorizationRestParams = function formatUpdateUserDmpAccessAuthorizationRestParams(_ref9) {
  var ins = _ref9.ins,
    action = _ref9.action;
  return {
    s_ins: ins,
    i_action: action
  };
};
export var formatUpdateDocumentVisibilityByUniqueId = function formatUpdateDocumentVisibilityByUniqueId(uniqueId, uuid, newVisibility, healthcareSettings, ins) {
  return {
    s_ins: ins,
    s_documentUniqueId: uniqueId,
    s_documentUuid: uuid,
    s_healthCareSettings: healthcareSettings,
    i_newVisibility: newVisibility
  };
};
export var formatSetDMPAccessModeParams = function formatSetDMPAccessModeParams(accessMode, reason) {
  return {
    i_accessMode: Number(accessMode),
    s_reason: reason
  };
};
export var formatGetDocumentContentByUniqueIdParams = function formatGetDocumentContentByUniqueIdParams(ins, uniqueId, uuid) {
  return {
    s_documentUniqueId: uniqueId,
    s_documentUuid: uuid,
    s_ins: ins,
    i_getCdaHeaders: 1
  };
};
export var formatGetDocumentsContentByUniqueIdParams = function formatGetDocumentsContentByUniqueIdParams(ins, uniqueIds, uuids) {
  return {
    UniqueIds: uniqueIds,
    Uuids: uuids,
    s_ins: ins
  };
};
export var formatDeleteDocumentParams = function formatDeleteDocumentParams(uniqueId, uuid, ins, healthcareSetting, submitEngine) {
  return {
    s_uniqueId: uniqueId,
    s_uuid: uuid,
    s_ins: ins,
    s_healthCareSettings: healthcareSetting || 'SA07',
    s_submitEngine: submitEngine
  };
};
export var formatDeleteDocumentRestParams = function formatDeleteDocumentRestParams(_ref10) {
  var uniqueId = _ref10.uniqueId,
    ins = _ref10.ins,
    healthcareSetting = _ref10.healthcareSetting;
  return {
    s_documentUniqueId: uniqueId,
    s_ins: ins,
    s_healthcareSetting: healthcareSetting
  };
};
export var formatUpdateDocumentStatusByUniqueId = function formatUpdateDocumentStatusByUniqueId(uniqueId, uuid, healthcareSetting, ins) {
  return {
    s_ins: ins,
    s_documentUniqueId: uniqueId,
    s_documentUuid: uuid,
    s_healthCareSettings: healthcareSetting
  };
};
export var formatSendDocumentParams = function formatSendDocumentParams(ins, base64Content, title, description, category, visibility, format, healthcareSetting, creationDate, replacedDocumentId, eventCodes, Informants, Performer, TreatingPhysician, AdditionalAuthors, IntendedRecipients, retrieveDocumentUuid, serviceStartDate, serviceStopDate, forceSchematronsValidation, ignorePdfA1Transparency, disabledPdfA1Conversion, versionNumber, setIdRoot, setIdExtension, uniqueId, Identity, transcodeTypeCode, stylesheetBase64, AdditionalPatientIdentifiers, getDocumentContent, structuredBody) {
  var hourPart = moment.utc().format('HH:mm:00');
  return {
    i_getDocumentContent: getDocumentContent === true ? 1 : 0,
    i_forceSchematronsValidation: forceSchematronsValidation === true ? 1 : 0,
    i_pdfa1IgnoreTransparency: ignorePdfA1Transparency === true ? 1 : 0,
    i_disablePdfa1Conversion: disabledPdfA1Conversion === true ? 1 : 0,
    s_ins: ins,
    s_documentOid: uniqueId,
    s_fileContentBase64: base64Content,
    StructuredBody: structuredBody,
    s_stylesheetInBase64: stylesheetBase64,
    s_documentTitle: title,
    s_documentDescription: description,
    s_documentCategory: category,
    i_documentVisibility: visibility,
    i_documentFormat: format,
    s_healthcareSetting: healthcareSetting,
    s_replacedDocumentUniqueId: replacedDocumentId,
    i_transcodeTypeCode: Number(transcodeTypeCode),
    i_retrieveDocumentUuid: retrieveDocumentUuid,
    s_documentVersionNumber: versionNumber,
    s_documentSetIdRoot: setIdRoot,
    s_documentSetIdExtension: setIdExtension,
    s_creationDate: moment.utc("".concat(creationDate, " ").concat(hourPart), ['DD/MM/YYYY HH:mm:ss']).local().format('YYYYMMDDHHmmssZZ'),
    s_serviceStartDate: moment.utc("".concat(serviceStartDate || creationDate, " ").concat(hourPart), ['DD/MM/YYYY HH:mm:ss']).local().format('YYYYMMDDHHmmssZZ'),
    s_serviceStopDate: moment.utc("".concat(serviceStopDate || creationDate, " ").concat(hourPart), ['DD/MM/YYYY HH:mm:ss']).local().format('YYYYMMDDHHmmssZZ'),
    s_eventCodes: eventCodes && eventCodes.length > 0 ? eventCodes.map(function (eventCode) {
      return {
        s_code: eventCode.code,
        s_classification: eventCode.classification,
        s_description: eventCode.description
      };
    }) : undefined,
    Informants: Informants,
    Performer: Performer,
    TreatingPhysician: TreatingPhysician,
    AdditionalAuthors: AdditionalAuthors,
    IntendedRecipients: IntendedRecipients,
    Identity: Identity,
    AdditionalPatientIdentifiers: AdditionalPatientIdentifiers ? AdditionalPatientIdentifiers.map(function (_ref11) {
      var patientIdentifierRootOid = _ref11.patientIdentifierRootOid,
        patientIdentifier = _ref11.patientIdentifier;
      return {
        s_root: patientIdentifierRootOid,
        s_extension: patientIdentifier
      };
    }) : undefined
  };
};
export var formatSendDocumentRestParams = function formatSendDocumentRestParams(esUser, ins, base64Content, title, description, category, visibility, format, healthcareSetting, creationDate, replacedDocumentId, eventCodes, Informants, Performer, TreatingPhysician, AdditionalAuthors, IntendedRecipients, retrieveDocumentUuid, serviceStartDate, serviceStopDate, forceSchematronsValidation, ignorePdfA1Transparency, disabledPdfA1Conversion, versionNumber, setIdRoot, setIdExtension, uniqueId, Identity, transcodeTypecode, stylesheetBase64, submitEngine, AdditionalPatientIdentifiers, getDocumentContent) {
  var hourPart = moment.utc().format('HH:mm:00');
  return {
    s_ins: ins,
    i_getDocumentContent: getDocumentContent === true ? 1 : 0,
    i_transcodeTypeCode: Number(transcodeTypecode),
    i_forceSchematronsValidation: forceSchematronsValidation === true ? 1 : 0,
    i_pdfa1IgnoreTransparency: ignorePdfA1Transparency === true ? 1 : 0,
    i_disablePdfa1Conversion: disabledPdfA1Conversion === true ? 1 : 0,
    i_retrieveDocumentUuid: retrieveDocumentUuid,
    Documents: [{
      s_oid: uniqueId,
      s_title: title,
      s_description: description,
      s_category: category,
      i_visibility: visibility,
      i_format: format,
      s_replacedDocumentUniqueId: replacedDocumentId,
      s_versionNumber: versionNumber,
      s_setIdRoot: setIdRoot,
      s_setIdExtension: setIdExtension,
      s_contentInBase64: base64Content,
      s_stylesheetInBase64: stylesheetBase64,
      s_creationDate: moment.utc("".concat(creationDate, " ").concat(hourPart), ['DD/MM/YYYY HH:mm:ss']).local().format('YYYYMMDDHHmmssZZ'),
      s_serviceStartDate: moment.utc("".concat(serviceStartDate || creationDate, " ").concat(hourPart), ['DD/MM/YYYY HH:mm:ss']).local().format('YYYYMMDDHHmmssZZ'),
      s_serviceStopDate: moment.utc("".concat(serviceStopDate || creationDate, " ").concat(hourPart), ['DD/MM/YYYY HH:mm:ss']).local().format('YYYYMMDDHHmmssZZ'),
      Authors: [{
        s_name: esUser.hpName,
        s_given: esUser.hpGiven,
        s_codeProfession: esUser.hpProfession,
        s_professionOid: esUser.hpProfessionOid,
        s_codeSpecialty: esUser.hpSpeciality,
        s_internalId: esUser.hpInternalId,
        i_authenticationMode: Number(esUser.hpAuthenticationMode)
      }],
      EventCodes: eventCodes && eventCodes.length > 0 ? eventCodes.map(function (eventCode) {
        return {
          s_code: eventCode.code,
          s_classification: eventCode.classification,
          s_description: eventCode.description
        };
      }) : undefined,
      Informants: Informants,
      Performer: Performer ? {
        HP: Performer.Hp,
        i_role: 2
      } : {
        HP: {
          s_name: esUser.hpName,
          s_given: esUser.hpGiven,
          s_codeProfession: esUser.hpProfession,
          s_professionOid: esUser.hpProfessionOid,
          s_codeSpecialty: esUser.hpSpeciality,
          s_internalId: esUser.hpInternalId,
          i_authenticationMode: Number(esUser.hpAuthenticationMode)
        },
        i_role: 2
      },
      TreatingPhysician: TreatingPhysician,
      AdditionalAuthors: AdditionalAuthors,
      IntendedRecipients: IntendedRecipients
    }],
    s_healthcareSetting: healthcareSetting,
    Identity: Identity,
    s_submitEngine: submitEngine,
    AdditionalPatientIdentifiers: AdditionalPatientIdentifiers ? AdditionalPatientIdentifiers.map(function (_ref12) {
      var patientIdentifierRootOid = _ref12.patientIdentifierRootOid,
        patientIdentifier = _ref12.patientIdentifier;
      return {
        s_root: patientIdentifierRootOid,
        s_extension: patientIdentifier
      };
    }) : undefined
  };
};
export var formatGetOtpChannelValueParams = function formatGetOtpChannelValueParams(channelType, ins) {
  return {
    i_otpChannelType: channelType,
    s_ins: ins
  };
};
export var formatgetPatientWebPdfParams = function formatgetPatientWebPdfParams(_ref13) {
  var ins = _ref13.ins,
    mobilePhone = _ref13.mobilePhone,
    mail = _ref13.mail;
  return {
    s_otpPhone: mobilePhone,
    s_otpEmail: mail,
    s_ins: ins,
    i_returnAsFile: 0
  };
};
export var formatgetPatientWebPdfParamsRest = function formatgetPatientWebPdfParamsRest(_ref14) {
  var ins = _ref14.ins,
    mobilePhone = _ref14.mobilePhone,
    mail = _ref14.mail;
  return {
    s_otpPhone: mobilePhone,
    s_otpEmail: mail,
    s_ins: ins
  };
};
export var formatcreateAcknowledgementPdf = function formatcreateAcknowledgementPdf(_ref15) {
  var ins = _ref15.ins,
    CivilStatus = _ref15.CivilStatus;
  return {
    CivilStatus: CivilStatus,
    s_ins: ins,
    i_returnAsFile: 0
  };
};
export var formatCloseDMPParams = function formatCloseDMPParams(ins, values) {
  return {
    s_ins: ins,
    s_name: values.name ? values.name : '',
    s_given: values.givenName ? values.givenName : '',
    s_reason: values.reason ? values.reason : ''
  };
};
export var formatCreateDMPParams = function formatCreateDMPParams(ins, values) {
  return {
    DmpAdministrativeData: {
      PatientData: {
        s_ins: ins,
        ExtendedCivilStatus: {
          CivilStatus: {
            i_gender: values.gender ? Number(values.gender) : 1,
            s_name: values.name ? values.name : '',
            s_given: values.givenName ? values.givenName : ''
          },
          s_birthName: values.birthName ? values.birthName : '',
          s_birthDay: values.birthday ? moment.utc(values.birthday, ['DD/MM/YYYY'], true).format('YYYYMMDD') : '',
          s_birthCountry: values.birthCountry ? values.birthCountry : '',
          i_sex: values.sex ? Number(values.sex) : 1
        },
        ExtendedContactDetails: {
          ContactDetails: {
            s_mobilePhone: values.mobilePhone ? values.mobilePhone.replace(/ /g, '') : '',
            s_landlinePhone: values.landlinePhone ? values.landlinePhone.replace(/ /g, '') : '',
            s_mail: values.mail ? values.mail : '',
            s_address: values.address ? values.address : '',
            s_addressComplement: values.addressComplement ? values.addressComplement : '',
            s_postalCode: values.postalCode ? values.postalCode : '',
            s_city: values.city ? values.city : ''
          },
          s_country: values.country ? values.country : ''
        }
      },
      PatientOppositionsStatus: {
        i_brisDeGlaceOpposition: values.brisDeGlaceOpposition ? Number(values.brisDeGlaceOpposition) : 0,
        i_centre15Opposition: values.centre15Opposition ? Number(values.centre15Opposition) : 0
      },
      i_guardian: values.guardianRole > 0 ? 2 : 1,
      GuardianData: {
        i_role: values.guardianRole > 0 && values.guardianRole ? Number(values.guardianRole) : 0,
        CivilStatus: {
          i_gender: values.guardianRole > 0 && values.guardianGender ? Number(values.guardianGender) : 1,
          s_name: values.guardianRole > 0 && values.guardianName ? values.guardianName : '',
          s_given: values.guardianRole > 0 && values.guardianGivenName ? values.guardianGivenName : ''
        },
        ContactDetails: {
          s_mobilePhone: values.guardianRole > 0 && values.guardianMobilePhone ? values.guardianMobilePhone.replace(/ /g, '') : '',
          s_landlinePhone: values.guardianRole > 0 && values.guardianLandlinePhone ? values.guardianLandlinePhone.replace(/ /g, '') : '',
          s_mail: values.guardianRole > 0 && values.guardianMail ? values.guardianMail : '',
          s_address: values.guardianRole > 0 && values.guardianAddress ? values.guardianAddress : '',
          s_addressComplement: values.guardianRole > 0 && values.guardianAddressComplement ? values.guardianAddressComplement : '',
          s_postalCode: values.guardianRole > 0 && values.guardianPostalCode ? values.guardianPostalCode : '',
          s_city: values.guardianRole > 0 && values.guardianCity ? values.guardianCity : ''
        }
      }
    },
    VitaleData: {
      s_name: values.vitaleName ? values.vitaleName : '',
      s_birthName: values.vitaleBirthName ? values.vitaleBirthName : '',
      s_given: values.vitaleGiven ? values.vitaleGiven : '',
      s_birthDay: values.vitaleBirthDay ? values.vitaleBirthDay : ''
    },
    createOTP: values.checkCreateOTP,
    isTreatingPhysician: values.isTreatingPhysician
  };
};
export var formatReactivateDMPParams = function formatReactivateDMPParams(ins, values) {
  return {
    s_ins: ins,
    i_gender: values.gender ? Number(values.gender) : 1,
    s_name: values.name ? values.name : '',
    s_given: values.givenName ? values.givenName : '',
    s_birthName: values.birthName ? values.birthName : '',
    s_birthDay: values.birthday ? moment.utc(values.birthday, ['DD/MM/YYYY'], true).format('YYYYMMDD') : '',
    s_birthCountry: values.birthCountry ? values.birthCountry : '',
    i_sex: values.sex ? Number(values.sex) : 1,
    s_mobilePhone: values.mobilePhone ? values.mobilePhone.replace(/ /g, '') : '',
    s_landlinePhone: values.landlinePhone ? values.landlinePhone.replace(/ /g, '') : '',
    s_mail: values.mail ? values.mail : '',
    s_address: values.address ? values.address : '',
    s_addressComplement: values.addressComplement ? values.addressComplement : '',
    s_postalCode: values.postalCode ? values.postalCode : '',
    s_city: values.city ? values.city : '',
    s_country: values.country ? values.country : '',
    isTreatingPhysician: values.isTreatingPhysician === true
  };
};

/**
 * returns findPatients command's formatted parameters
 * @param name
 * @param givenName
 * @param city
 * @param postalCode
 * @param birthday
 * @param sex
 * @param approxName
 * @param approxCity
 * @returns {{s_givenName: (*|string), s_city: (*|string), i_sex: (number), i_approxCity: (number), i_approxName: (number), s_birthday: (string|string), s_name: (*|string), s_postalCode: (*|string)}}
 */
export var formatFindPatientsParams = function formatFindPatientsParams(name, givenName, city, postalCode, birthday, sex, approxName, approxCity) {
  return {
    s_name: name || '',
    s_givenName: givenName || '',
    s_city: city || '',
    s_postalCode: postalCode || '',
    s_birthday: birthday ? moment.utc(birthday, ['DD/MM/YYYY'], true).format('YYYYMMDD') : '',
    i_sex: sex ? Number(sex) : 1,
    i_approxName: approxName ? Number(approxName) : 0,
    i_approxCity: approxCity ? Number(approxCity) : 0
  };
};
export var formatInteropCodesParams = function formatInteropCodesParams(codeIds) {
  return {
    s_codeIds: codeIds
  };
};
export var formatSetPersistantDataParams = function formatSetPersistantDataParams(data) {
  return {
    s_persistantData64: data
  };
};
export var formatObjectParameterIntParams = function formatObjectParameterIntParams(parameter, object) {
  return {
    i_parameter: parameter,
    i_object: object
  };
};
export var formatGetMssHpInfosParams = function formatGetMssHpInfosParams(name, given, rpps, specialty, organisation) {
  return {
    s_searchName: name,
    s_searchGiven: given,
    s_searchRPPS: rpps,
    s_searchSpecialty: specialty,
    s_searchOrganization: organisation
  };
};
export var formatPerformMssAuthentication = function formatPerformMssAuthentication(rpps) {
  return {
    s_idNatPs: rpps,
    i_useEsAuthentication: 1
  };
};
export var formatSendMssRequest = function formatSendMssRequest(requestPath, requestBodyInBase64) {
  return {
    s_requestPath: requestPath,
    s_requestBodyInBase64: requestBodyInBase64
  };
};
export var formatGetEmailAdressFromMssWs = function formatGetEmailAdressFromMssWs(rpps) {
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:listEmails>' + '<userId>' + rpps + '</userId>' + '</ser:listEmails>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = base64.encode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Annuaire/soap/v1/listEmails', base64EncodedBody);
};
export var formatGetFoldersFromMssWs = function formatGetFoldersFromMssWs(email) {
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:listFolders>' + '<email>' + email + '</email>' + '</ser:listFolders>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = base64.encode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Folder/soap/v1/listFolders', base64EncodedBody);
};
export var formatCreateFolderMssWs = function formatCreateFolderMssWs(email, folderName, folderParentId) {
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:createFolder>' + '<email>' + email + '</email>' + '<folderName>' + folderName + '</folderName>' + '<folderParentId>' + (folderParentId || -1) + '</folderParentId>' + '</ser:createFolder>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = base64.encode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Folder/soap/v1/createFolder', base64EncodedBody);
};
export var formatDeleteFolderMssWs = function formatDeleteFolderMssWs(email, folderId) {
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:deleteFolder>' + '<email>' + email + '</email>' + '<folderId>' + folderId + '</folderId>' + '</ser:deleteFolder>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = base64.encode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Folder/soap/v1/deleteFolder', base64EncodedBody);
};
export var formatRenameFolderMssWs = function formatRenameFolderMssWs(email, folderId, newFolderName) {
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:renameFolder>' + '<email>' + email + '</email>' + '<folderId>' + folderId + '</folderId>' + '<newFolderName>' + newFolderName + '</newFolderName>' + '</ser:renameFolder>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = base64.encode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Folder/soap/v1/renameFolder', base64EncodedBody);
};
export var formatSearchMessagesFromMssWs = function formatSearchMessagesFromMssWs(email) {
  var folderId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var limit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1000;
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:searchMessages>' + "<email>".concat(email, "</email>") + '<searchCriteria>' + '  <html>1</html>';
  if (offset != null) {
    soapBody += "  <offset>".concat(offset, "</offset>");
  }
  if (limit != null) {
    soapBody += "  <limit>".concat(limit, "</limit>");
  }
  soapBody += '  <query>';
  if (folderId !== null) {
    soapBody += "    <folderId>".concat(folderId, "</folderId>");
  }
  soapBody += '  </query>' + '</searchCriteria>' + '</ser:searchMessages>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = base64.encode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Item/soap/v1/searchMessages', base64EncodedBody);
};
export var formatSyncMessagesFromMssWs = function formatSyncMessagesFromMssWs(email, folderId, token) {
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:syncMessages>' + "<email>".concat(email, "</email>") + '<html>true</html>';
  if (folderId !== null) {
    soapBody += "<folderId>".concat(folderId, "</folderId>");
  }
  if (token !== null) {
    soapBody += "<token>".concat(token, "</token>");
  }
  soapBody += '</ser:syncMessages>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = base64.encode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Item/soap/v1/syncMessages', base64EncodedBody);
};
export var formatUpdateMessagesFromMssWs = function formatUpdateMessagesFromMssWs(email, messageIds, operation) {
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:updateMessages>' + "<email>".concat(email, "</email>") + messageIds.map(function (id) {
    return "<messageIds>".concat(id, "</messageIds>");
  }).join() + "<operation>".concat(operation, "</operation>") + '</ser:updateMessages>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = base64.encode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Item/soap/v1/updateMessages', base64EncodedBody);
};
export var formatDownloadAttachementFromMssWs = function formatDownloadAttachementFromMssWs(email, messageId, part) {
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:downloadAttachment>' + "<email>".concat(email, "</email>") + "<messageId>".concat(messageId, "</messageId>") + "<part>".concat(part, "</part>") + '</ser:downloadAttachment>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = base64.encode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Attachment/soap/v1/downloadAttachment', base64EncodedBody);
};
export var formatMoveMssMessages = function formatMoveMssMessages(email, messageIds, destinationFolderId) {
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:moveMessages>' + "<email>".concat(email, "</email>") + messageIds.map(function (id) {
    return "<messageIds>".concat(id, "</messageIds>");
  }).join() + "<destinationFolderId>".concat(destinationFolderId, "</destinationFolderId>") + '</ser:moveMessages>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = base64.encode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Item/soap/v1/moveMessages', base64EncodedBody);
};
export var formatFullSearchMssMessages = function formatFullSearchMssMessages(email, folderId, from, to, subject, body, dateFrom, dateTo, hasAttachment) {
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:searchMessages>' + "<email>".concat(email, "</email>") + '<searchCriteria>' + '<query>' + "<folderId>".concat(folderId, "</folderId>") + (body ? "<content>".concat(body, "</content>") : '') + (subject ? "<subject>".concat(subject, "</subject>") : '') + (to ? "<to>".concat(to, "</to>") : '') + (from ? "<from>".concat(from, "</from>") : '') + (dateTo ? "<before>".concat(moment.utc(dateTo, 'DD/MM/YYYY').format('DD/MM/YYYY 23:59:59'), "</before>") : '') + (dateFrom ? "<after>".concat(moment.utc(dateFrom, 'DD/MM/YYYY').format('DD/MM/YYYY 00:00:00'), "</after>") : '') + '</query>' + '</searchCriteria>' + '</ser:searchMessages>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = base64.encode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Item/soap/v1/searchMessages', base64EncodedBody);
};
export var formatFullSearchMssMessagesIMAP = function formatFullSearchMssMessagesIMAP(email, folderId, from, to, subject, body, dateFrom, dateTo, hasAttachment) {
  var queries = [];
  if (from) {
    queries.push({
      s_type: 'from',
      s_value: from
    });
  }
  if (to) {
    queries.push({
      s_type: 'to',
      s_value: to
    });
  }
  if (dateFrom) {
    queries.push({
      s_type: 'sent_since',
      s_value: dateFrom
    });
  }
  if (dateTo) {
    queries.push({
      s_type: 'sent_before',
      s_value: moment.utc(dateTo, 'DD/MM/YYYY').add(1, 'day').format('DD/MM/YYYY')
    });
  }
  if (subject) {
    queries.push({
      s_type: 'subject_contains',
      s_value: subject
    });
  }
  if (body) {
    queries.push({
      s_type: 'body_contains',
      s_value: body
    });
  }
  return {
    s_login: email,
    s_folderName: folderId,
    Query: queries.length > 1 ? {
      s_type: 'boolean',
      s_value: 'and',
      Queries: queries
    } : _objectSpread({}, queries[0])
  };
};
export var formatSendMssEmail = function formatSendMssEmail(emailContent) {
  return _objectSpread({
    s_requestPath: '/mss-msg-services/services/Item/soap/v1/sendMessage'
  }, formatSendMssSmtpEmail(emailContent));
};
export var formatSendMssWebEmail = function formatSendMssWebEmail(emailContent) {
  var soapBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.msg.mss.asip.fr/">' + '<soapenv:Header/>' + '<soapenv:Body>' + '<ser:sendMessage>' + "<email>".concat(emailContent.sender, "</email>") + '<message>' + (emailContent.inReplyToMessageIds ? '<replyType>REPLIED</replyType>' : '') + (emailContent.references ? '<replyType>FORWARDED</replyType>' : '') + (emailContent.references || emailContent.inReplyToMessageIds ? "<messageTransferedId>".concat(emailContent.references || emailContent.inReplyToMessageIds, "</messageTransferedId>") : '') + '<addresses>' + "<email>".concat(emailContent.sender, "</email>") + (emailContent.senderWording ? "<name>".concat(emailContent.senderWording, "</name>") : '') + "<type>FROM</type>" + '</addresses>' + emailContent.recipients.split(';').map(function (address) {
    return "<addresses><email>".concat(address, "</email><type>TO</type></addresses>");
  }) + (emailContent.cc && emailContent.cc.length > 0 ? emailContent.cc.split(';').map(function (address) {
    return "<addresses><email>".concat(address, "</email><type>CC</type></addresses>");
  }) : '') + (emailContent.bcc && emailContent.bcc.length > 0 ? emailContent.bcc.split(';').map(function (address) {
    return "<addresses><email>".concat(address, "</email><type>BCC</type></addresses>");
  }) : '') + "<subject>".concat(emailContent.title, "</subject>") + "<isHtml>".concat(emailContent.isHtml, "</isHtml>")
  // + (
  //   emailContent.sendReceiptNotification && emailContent.receiptNotificationType === mssReceiptNotificationTypes.MDN
  //     ? `<DispositionNotificationTo>${emailContent.notificationReceivers}</DispositionNotificationTo>`
  //     : ''
  // )
  + (emailContent.sendReceiptNotification === true ? "<isAccuse>true</isAccuse>" : '') + "<body>".concat(emailContent.isHtml ? "<![CDATA[ ".concat(emailContent.messageContent, " ]]>") : emailContent.messageContent, "</body>") + (emailContent.attachments || '') + '</message>' + '</ser:sendMessage>' + '</soapenv:Body>' + '</soapenv:Envelope>';
  var base64EncodedBody = b64EncodeUnicode(soapBody);
  return formatSendMssRequest('/mss-msg-services/services/Item/soap/v1/sendMessage', base64EncodedBody);
};
export var formatGenerateMssAttachmentsRest = function formatGenerateMssAttachmentsRest(attachments, esUser) {
  return {
    s_ins: attachments.length > 0 ? attachments[0].patientIns : '',
    s_healthcareSetting: attachments.length > 0 ? attachments[0].healthcareSetting : '',
    Documents: attachments.length > 0 ? attachments.map(function (doc) {
      return {
        s_patientIns: doc.patientIns,
        s_oid: doc.uniqueId,
        s_stylesheetInBase64: doc.stylesheet,
        s_contentInBase64: doc.fileContentInBase64,
        s_title: doc.documentTitle,
        s_description: doc.documentDescription,
        s_category: doc.documentCategory,
        i_visibility: doc.documentVisibility || documentVisibilityStatuses.NORMAL,
        i_format: doc.documentFormat,
        s_healthcareSetting: doc.healthcareSetting,
        s_versionNumber: doc.versionNumber,
        s_setIdRoot: doc.setIdRoot,
        s_setIdExtension: doc.setIdExtension,
        s_replacedDocumentUniqueId: doc.replacedDocumentUniqueId,
        EventCodes: doc.eventCodes && doc.eventCodes.length > 0 ? doc.eventCodes.map(function (eventCode) {
          return {
            s_code: eventCode.code,
            s_classification: eventCode.classification,
            s_description: eventCode.description
          };
        }) : undefined,
        Informants: doc.informants,
        Authors: [{
          s_name: esUser.hpName,
          s_given: esUser.hpGiven,
          s_codeProfession: esUser.hpProfession,
          s_professionOid: esUser.hpProfessionOid,
          s_codeSpecialty: esUser.hpSpeciality,
          s_internalId: esUser.hpInternalId,
          i_authenticationMode: Number(esUser.hpAuthenticationMode)
        }],
        Performer: doc.performer || {
          HP: {
            s_name: esUser.hpName,
            s_given: esUser.hpGiven,
            s_codeProfession: esUser.hpProfession,
            s_professionOid: esUser.hpProfessionOid,
            s_codeSpecialty: esUser.hpSpeciality,
            s_internalId: esUser.hpInternalId,
            i_authenticationMode: Number(esUser.hpAuthenticationMode)
          },
          i_role: 2
        },
        TreatingPhysician: doc.treatingPhysician,
        AdditionalAuthors: doc.additionalAuthors,
        IntendedRecipients: doc.intendedRecipients,
        AdditionalPatientIdentifiers: doc.AdditionalPatientIdentifiers ? doc.AdditionalPatientIdentifiers.map(function (_ref16) {
          var patientIdentifierRootOid = _ref16.patientIdentifierRootOid,
            patientIdentifier = _ref16.patientIdentifier;
          return {
            s_root: patientIdentifierRootOid,
            s_extension: patientIdentifier
          };
        }) : undefined
      };
    }) : undefined
  };
};
export var formatGenerateMssAttachments = function formatGenerateMssAttachments(attachments) {
  return {
    Attachments: attachments.map(function (doc) {
      return {
        s_patientIns: doc.patientIns,
        s_oid: doc.uniqueId,
        s_stylesheetInBase64: doc.stylesheet,
        s_fileContentInBase64: doc.fileContentInBase64,
        s_documentTitle: doc.documentTitle,
        s_documentDescription: doc.documentDescription,
        s_documentCategory: doc.documentCategory,
        i_documentFormat: doc.documentFormat,
        i_documentVisibility: doc.documentVisibility || documentVisibilityStatuses.NORMAL,
        s_healthcareSetting: doc.healthcareSetting,
        s_versionNumber: doc.versionNumber,
        s_setIdRoot: doc.setIdRoot,
        s_setIdExtension: doc.setIdExtension,
        s_replacedDocumentUniqueId: doc.replacedDocumentUniqueId,
        EventCodes: doc.eventCodes && doc.eventCodes.length > 0 ? doc.eventCodes.map(function (eventCode) {
          return {
            s_code: eventCode.code,
            s_classification: eventCode.classification,
            s_description: eventCode.description
          };
        }) : undefined,
        Informants: doc.informants,
        Performer: doc.performer,
        TreatingPhysician: doc.treatingPhysician,
        AdditionalAuthors: doc.additionalAuthors,
        IntendedRecipients: doc.intendedRecipients,
        AdditionalPatientIdentifiers: doc.AdditionalPatientIdentifiers ? doc.AdditionalPatientIdentifiers.map(function (_ref17) {
          var patientIdentifierRootOid = _ref17.patientIdentifierRootOid,
            patientIdentifier = _ref17.patientIdentifier;
          return {
            s_root: patientIdentifierRootOid,
            s_extension: patientIdentifier
          };
        }) : undefined
      };
    })
  };
};
export var formatSendMssSmtpEmail = function formatSendMssSmtpEmail(emailContent) {
  var isREST = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var config = {
    s_login: emailContent.sender,
    s_senderEmail: emailContent.sender,
    s_recipient: emailContent.recipients,
    s_cc: emailContent.cc,
    s_bcc: emailContent.bcc,
    s_title: emailContent.title,
    s_messageContentInBase64: b64EncodeUnicode(emailContent.messageContent || ''),
    i_messageContentIsInHtml: emailContent.isHtml,
    s_returnReceiptTo: emailContent.sendReceiptNotification && emailContent.receiptNotificationType === mssReceiptNotificationTypes.DSN ? emailContent.notificationReceivers : undefined,
    s_dispositionNotificationTo: emailContent.sendReceiptNotification && emailContent.receiptNotificationType === mssReceiptNotificationTypes.MDN ? emailContent.notificationReceivers : undefined,
    s_inReplyToMessageIds: emailContent.inReplyToMessageIds,
    s_references: emailContent.references,
    s_senderWording: emailContent.senderWording,
    s_messageId: emailContent.messageId,
    s_replyTo: emailContent.replyTo,
    i_forceSchematronsValidation: [true, '1', 1].includes(emailContent.forceSchematronsValidation) ? 1 : 0,
    i_pdfa1IgnoreTransparency: [true, '1', 1].includes(emailContent.ignorePdfA1Transparency) ? 1 : 0,
    i_disablePdfa1Conversion: [true, '1', 1].includes(emailContent.disabledPdfA1Conversion) ? 1 : 0,
    i_disableIheXdmPdfTitlePage: [true, '1', 1].includes(emailContent.disableIheXdmPdfTitlePage) ? 1 : 0,
    i_disableIheXdmPdfDataMatrixBlock: [true, '1', 1].includes(emailContent.disableIheXdmPdfDataMatrixBlock) ? 1 : 0,
    RawDocuments: (emailContent.otherAttachments || []).map(function (att) {
      return {
        s_contentType: att.contentType,
        s_contentInBase64: att.fileContentInBase64,
        s_name: att.documentTitle
      };
    }),
    Identity: emailContent.Identity,
    i_getRawMessageSent: 1,
    i_insIsNotQualified: [true, '1', 1].includes(emailContent.insIsNotQualified) ? 1 : 0,
    AdditionalPatientIdentifiers: emailContent.AdditionalPatientIdentifiers,
    i_getDocumentContent: [true, '1', 1].includes(emailContent.getDocumentContent) ? 1 : 0,
    i_forbidRecipientAnswer: emailContent.endConversation === true ? 1 : undefined
  };
  if (isREST) {
    config = _objectSpread({}, config, formatGenerateMssAttachmentsRest(emailContent.attachments, emailContent.esUser));
  } else {
    config = _objectSpread({}, config, formatGenerateMssAttachments(emailContent.attachments));
  }
  return config;
};

// {
//    s_authenticationId
//    s_senderEmail
//    s_recipient
//    s_cc          (*)
//    s_bcc         (*)
//    s_title
//    s_messageContentInBase64
//    i_messageContentIsInHtml  (*)       -- Default: off
//    i_askReadAcknowledgement  (*)       -- Defautl: off.
//    Documents                 (**)      -- Same definition as /sendsubmissionset.
//    s_healthcareSetting       (**)
//    s_ins                     (**)      -- Or Ins{} structure.
// }

export var formatSendMssOtpAuthenticatedEmail = function formatSendMssOtpAuthenticatedEmail(emailContent, esUser) {
  var docs;
  if (emailContent.attachments.length > 0) {
    docs = formatGenerateMssAttachmentsRest(emailContent.attachments, esUser);
  }
  return _objectSpread({
    s_senderEmail: emailContent.sender,
    s_recipient: emailContent.recipients,
    s_cc: emailContent.cc,
    s_bcc: emailContent.bcc,
    s_title: emailContent.title,
    s_messageContentInBase64: b64EncodeUnicode(emailContent.messageContent),
    i_messageContentIsInHtml: emailContent.isHtml,
    i_askReadAcknowledgement: emailContent.sendReceiptNotification === true ? 1 : 0,
    s_ins: emailContent.attachments.length > 0 ? emailContent.attachments[0].patientIns : '',
    s_healthcareSetting: emailContent.attachments.length > 0 ? emailContent.attachments[0].healthcareSetting : ''
  }, docs, {
    otherAttachments: emailContent.otherAttachments,
    s_inReplyToMessageIds: emailContent.inReplyToMessageIds,
    s_references: emailContent.references,
    s_senderWording: emailContent.senderWording,
    s_messageId: emailContent.messageId,
    s_replyTo: emailContent.replyTo,
    // PDF
    i_forceSchematronsValidation: emailContent.forceSchematronsValidation === true ? 1 : 0,
    i_pdfa1IgnoreTransparency: emailContent.ignorePdfA1Transparency === true ? 1 : 0,
    i_disablePdfa1Conversion: emailContent.disabledPdfA1Conversion === true ? 1 : 0,
    i_disableIheXdmPdfTitlePage: emailContent.disableIheXdmPdfTitlePage === true ? 1 : 0,
    i_disableIheXdmPdfDataMatrixBlock: emailContent.disableIheXdmPdfDataMatrixBlock === true ? 1 : 0,
    Identity: emailContent.Identity,
    i_insIsNotQualified: Number(emailContent.insIsNotQualified || 0),
    AdditionalPatientIdentifiers: emailContent.AdditionalPatientIdentifiers
  });
};
export var formatGetALDFromVitaleCardParams = function formatGetALDFromVitaleCardParams(_ref18) {
  var vitaleIndex = _ref18.vitaleIndex,
    idam = _ref18.idam,
    numAm = _ref18.numAm,
    lpsName = _ref18.lpsName,
    lpsVersion = _ref18.lpsVersion,
    useApCvContext = _ref18.useApCvContext;
  return {
    LpsInfos: {
      s_idam: idam,
      s_instance: '550e8400-e29b-41d4-a716-446655440000',
      s_name: lpsName,
      s_numAM: numAm,
      s_version: lpsVersion
    },
    i_vitalePatientIndex: vitaleIndex,
    i_useApCvContext: useApCvContext
  };
};
export var formatGetINSFromVitaleCardParams = function formatGetINSFromVitaleCardParams(_ref19) {
  var vitaleIndex = _ref19.vitaleIndex,
    idam = _ref19.idam,
    numAm = _ref19.numAm,
    lpsName = _ref19.lpsName,
    lpsVersion = _ref19.lpsVersion,
    billingNumber = _ref19.billingNumber,
    getTransactionFrames = _ref19.getTransactionFrames;
  return {
    LpsInfos: {
      s_idam: idam,
      s_instance: '550e8400-e29b-41d4-a716-446655440000',
      s_name: lpsName,
      s_numAM: numAm,
      s_version: lpsVersion,
      s_billingNumber: billingNumber
    },
    i_vitalePatientIndex: vitaleIndex,
    i_getTransactionFrames: !!getTransactionFrames ? 1 : 0
  };
};
export var formatGetInsFromIdentityInformationParams = function formatGetInsFromIdentityInformationParams(_ref20) {
  var name = _ref20.name,
    given = _ref20.given,
    birthday = _ref20.birthday,
    birthplace = _ref20.birthplace,
    sex = _ref20.sex,
    idam = _ref20.idam,
    numAm = _ref20.numAm,
    lpsName = _ref20.lpsName,
    lpsVersion = _ref20.lpsVersion,
    billingNumber = _ref20.billingNumber,
    getTransactionFrames = _ref20.getTransactionFrames;
  return {
    LpsInfos: {
      s_idam: idam,
      s_instance: '550e8400-e29b-41d4-a716-446655440000',
      s_name: lpsName,
      s_numAM: numAm,
      s_version: lpsVersion,
      s_billingNumber: billingNumber
    },
    s_birthName: name.toUpperCase().replace(/\s+/g, ' '),
    s_given: given.toUpperCase().replace(/\s+/g, ' '),
    i_sex: sex,
    s_birthDate: moment.utc(birthday, ['YYYYMMDD', 'DD/MM/YYYY']).format('YYYY-MM-DD'),
    s_birthPlace: birthplace,
    i_getTransactionFrames: !!getTransactionFrames ? 1 : 0
  };
};
export var formatValidateFromINSiIdentity = function formatValidateFromINSiIdentity(_ref21) {
  var identity = _ref21.identity,
    idam = _ref21.idam,
    numAm = _ref21.numAm,
    lpsName = _ref21.lpsName,
    lpsVersion = _ref21.lpsVersion,
    billingNumber = _ref21.billingNumber,
    getTransactionFrames = _ref21.getTransactionFrames;
  var _identity$Identity = identity.Identity,
    _identity$Identity$In = _identity$Identity.Ins,
    s_key = _identity$Identity$In.s_key,
    s_oid = _identity$Identity$In.s_oid,
    s_value = _identity$Identity$In.s_value,
    i_sex = _identity$Identity.i_sex,
    s_birthDate = _identity$Identity.s_birthDate,
    s_birthGiven = _identity$Identity.s_birthGiven,
    s_birthName = _identity$Identity.s_birthName,
    s_birthPlace = _identity$Identity.s_birthPlace,
    s_given = _identity$Identity.s_given;
  return {
    LpsInfos: {
      s_idam: idam,
      s_instance: '550e8400-e29b-41d4-a716-446655440000',
      s_name: lpsName,
      s_numAM: numAm,
      s_version: lpsVersion,
      s_billingNumber: billingNumber
    },
    s_ins: s_value,
    s_key: s_key,
    s_birthName: s_birthName,
    s_given: s_birthGiven,
    i_sex: i_sex,
    s_birthDate: s_birthDate,
    s_birthPlace: s_birthPlace,
    s_oid: s_oid === validateINSiOIDS.NIA ? s_oid : validateINSiOIDS.NORMAL,
    i_getTransactionFrames: !!getTransactionFrames ? 1 : 0
  };
};
export var formatGetDp = function formatGetDp(ndp, origin) {
  var identity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
  var consultType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
  return {
    Patient: {
      Ndp: {
        s_ndp: ndp,
        i_ndpOrigin: origin
      },
      Identity: Object.keys(identity).length > 0 ? {
        // s_lastInsiCallDate: extractDate(identity.s_lastInsiCallDate, 'YYYY-MM-DD').format('YYYYMMDD'),
        s_lastInsiCallDate: identity.s_lastInsiCallDate,
        Ins: identity.Ins,
        s_birthName: identity.s_birthName,
        s_given: identity.s_given,
        s_birthGiven: identity.s_birthGiven,
        i_sex: identity.i_sex,
        s_birthDate: identity.s_birthDate,
        // s_birthDate: extractDate(identity.s_birthDate, 'YYYY-MM-DD').format('YYYYMMDD'),
        s_birthPlace: identity.s_birthPlace,
        History: identity.History || []
      } : undefined
    },
    i_consultType: consultType
  };
};
export var formatAddDispensation = function formatAddDispensation(_ref22) {
  var ndp = _ref22.ndp,
    origin = _ref22.origin,
    _ref22$identity = _ref22.identity,
    identity = _ref22$identity === void 0 ? {} : _ref22$identity,
    _ref22$consultType = _ref22.consultType,
    consultType = _ref22$consultType === void 0 ? 1 : _ref22$consultType,
    prescriptionType = _ref22.prescriptionType,
    _ref22$products = _ref22.products,
    products = _ref22$products === void 0 ? [] : _ref22$products;
  return {
    Patient: {
      Ndp: {
        s_ndp: ndp,
        i_ndpOrigin: origin
      },
      Identity: Object.keys(identity).length > 0 ? {
        // s_lastInsiCallDate: extractDate(identity.s_lastInsiCallDate, 'YYYY-MM-DD').format('YYYYMMDD'),
        s_lastInsiCallDate: identity.s_lastInsiCallDate,
        Ins: identity.Ins,
        s_birthName: identity.s_birthName,
        s_given: identity.s_given,
        s_birthGiven: identity.s_birthGiven,
        i_sex: identity.i_sex,
        s_birthDate: identity.s_birthDate,
        // s_birthDate: extractDate(identity.s_birthDate, 'YYYY-MM-DD').format('YYYYMMDD'),
        s_birthPlace: identity.s_birthPlace,
        History: identity.History || []
      } : undefined
    },
    i_consultType: consultType,
    Dispensations: [{
      i_prescriptionType: prescriptionType,
      s_prescriberCategory: '',
      Products: products.map(function (product) {
        return {
          Identification: {
            s_cipCode: product.cipCode,
            s_ucdCode: product.ucdCode || undefined,
            s_cisCode: product.cisCode || undefined,
            s_atcCode: product.atcCode || undefined,
            s_commonName: product.commonName || undefined,
            s_name: product.name
          },
          i_amount: product.amount,
          i_ucdAmount: product.ucdAmount || undefined
        };
      })
    }]
  };
};
export var formatAddOrUpdateDpRecord = function formatAddOrUpdateDpRecord(_ref23) {
  var ndp = _ref23.ndp,
    sex = _ref23.sex,
    quality = _ref23.quality,
    origin = _ref23.origin,
    expirationDate = _ref23.expirationDate;
  return {
    i_ndpOrigin: origin,
    i_sex: Number(sex),
    s_expirationDate: moment.utc(expirationDate, 'DD/MM/YYYY').format('DDMMYYYY'),
    s_ndp: ndp,
    s_quality: quality
  };
};
export var formatDeleteDpRecord = function formatDeleteDpRecord(_ref24) {
  var ndp = _ref24.ndp,
    origin = _ref24.origin;
  return {
    i_ndpOrigin: origin,
    s_ndp: ndp
  };
};
export var formatGetApCvContext = function formatGetApCvContext(_ref25) {
  var idam = _ref25.idam,
    numAm = _ref25.numAm,
    lpsName = _ref25.lpsName,
    lpsVersion = _ref25.lpsVersion,
    codeSpeAMO = _ref25.codeSpeAMO,
    billingNumber = _ref25.billingNumber,
    token = _ref25.token;
  return {
    LpsInfos: {
      s_billingNumber: billingNumber,
      s_codeSpeAMO: codeSpeAMO,
      s_idam: idam,
      s_instance: '550e8400-e29b-41d4-a716-446655440000',
      s_name: lpsName,
      s_numAM: numAm,
      s_version: lpsVersion
    },
    s_dataInBase64: token
  };
};
export var formatReleaseApCvContext = function formatReleaseApCvContext(_ref26) {
  var idam = _ref26.idam,
    numAm = _ref26.numAm,
    lpsName = _ref26.lpsName,
    lpsVersion = _ref26.lpsVersion,
    codeSpeAMO = _ref26.codeSpeAMO,
    billingNumber = _ref26.billingNumber;
  return {
    LpsInfos: {
      s_billingNumber: billingNumber,
      s_codeSpeAMO: codeSpeAMO,
      s_idam: idam,
      s_instance: '550e8400-e29b-41d4-a716-446655440000',
      s_name: lpsName,
      s_numAM: numAm,
      s_version: lpsVersion
    }
  };
};
export var formatInitiateMssOtpAuthentication = function formatInitiateMssOtpAuthentication(idNatPs, otpChannelType, password) {
  return {
    s_idNatPs: idNatPs,
    i_otpChannelType: otpChannelType,
    s_password: password
  };
};
export var formatFinalizeMssOtpAuthentication = function formatFinalizeMssOtpAuthentication(authenticationId, otp) {
  return {
    s_otpPassword: otp,
    s_authenticationId: authenticationId
  };
};
export var formatGetSubmissionSetDocuments = function formatGetSubmissionSetDocuments(ins, submissionSetId) {
  return {
    s_ins: ins,
    s_submissionSetId: submissionSetId
  };
};
export var formatGetDocumentSubmissionSetIdByUniqueId = function formatGetDocumentSubmissionSetIdByUniqueId(ins, uniqueId, uuid) {
  return {
    s_ins: ins,
    s_documentUniqueId: uniqueId,
    s_documentUuid: uuid
  };
};
var operationsToImapFlags = {
  READ: '\\Seen',
  UNREAD: ''
};
export var formatSearchFhirHealthDirectory = function formatSearchFhirHealthDirectory(_ref27) {
  var name = _ref27.name,
    given = _ref27.given,
    idNatPs = _ref27.idNatPs,
    professionCode = _ref27.professionCode,
    specialityCode = _ref27.specialityCode,
    city = _ref27.city,
    postalCode = _ref27.postalCode,
    organisationName = _ref27.organisationName;
  return {
    s_name: name,
    s_given: given,
    s_idNatPs: idNatPs,
    s_professionCode: professionCode,
    s_specialityCode: specialityCode,
    s_city: city,
    s_postalCode: postalCode,
    s_organisationName: organisationName
  };
};
export var formatPscAuthenticateUserCiba = function formatPscAuthenticateUserCiba(rpps, bindingMessage) {
  return {
    i_bindingMessage: Number(bindingMessage),
    s_idNatPs: rpps
  };
};