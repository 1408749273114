import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import merge from 'lodash.merge';
import { dmpconnectConfigurationActionConstants } from '../constants';
var initialState = {
  active: false,
  whoami: ''
};
export function dmpconnectTSEConfiguration() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case dmpconnectConfigurationActionConstants.DMPC_SET_TSE_CONFIGURATION:
      return merge({}, state, _defineProperty({}, action.key, action.value));
    default:
      return state;
  }
}
export default function getDmpconnectTSEConfigReducer(_ref) {
  var active = _ref.active,
    whoami = _ref.whoami;
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      active: active,
      whoami: whoami
    };
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return dmpconnectTSEConfiguration(state, action);
  };
}