export const routes = {
  logout: { path: '/logout' },
  home: { path: '/' },
  dmpSearch: { path: '/home/:page?/:extra?' },
  dp: { path: '/dp/:encodedNdp' },
  configuration: { path: '/configuration/:tab?' },
  debug: { path: '/debug' },
  dmpConfig: { path: '/dmp-config' },
  esConfig: { path: '/es-config' },
  tseConfig: { path: '/tse-config' },
  cpxConfig: { path: '/cpx-config' },
  meds: { path: '/dmp/:encodedIns/meds/:hrSection?/:atcCode?/:cipCode?' },
  hr: { path: '/dmp/:encodedIns/hr/:hrSection?/:atcCode?/:cipCode?' },
  vidalConfig: { path: '/vidal-config' },
  dmpDocument: { path: '/dmp/:encodedIns/document/:encodedUniqueUUid' },
  dmpDocuments: { path: '/dmp/:encodedIns/documents' },
  imagery: { path: '/dmp/:encodedIns/imagery' },
  dmpMobileLanding: { path: '/dmp/:encodedIns/menu' },
  dmpDocumentsCategory: { path: '/dmp/:encodedIns/documents/:typeCode' },
  dmpInformation: { path: '/dmp/:encodedIns/information' },
  dmpAdministration: { path: '/dmp/:encodedIns/administration/:tab?' },
  webAccessPDFViewer: { path: '/dmp/view_web_access/:action' },
  listview: { path: '/dmp/:encodedIns/parcours-soin/:yearIndex?/:monthIndex?/:dayIndex?' },
  searchDocs: { path: '/dmp/:encodedIns/search-docs' },
  aldVitale: { path: '/ald/:subSection' },
  aldDmp: { path: '/dmp/:encodedIns/ald' },
  mss: { path: '/mss/:mssFolderId?/:mssMessageId?' },
  mssConfig: { path: '/mss-config' },
  sentDocuments: { path: '/sentdocuments' },
  logs: { path: '/logs' },
  upload: { path: '/upload/:encodedIns' },
  pscInfo: { path: '/info' },
  openIdAuthCallback: { path: '/authcallback' },
};

export const exceptionRoutes = [
  routes.debug.path,
  routes.dmpConfig.path,
  routes.esConfig.path,
  routes.tseConfig.path,
  routes.openIdAuthCallback.path,
  '/silent-sso',
  '/login-token',
  '/bootstrap',
  '/nav',
  routes.logout.path,
  routes.vidalConfig.path,
  routes.logs.path,
  routes.pscInfo.path,
  routes.cpxConfig.path,
  routes.mssConfig.path,
];

export const doesNotTriggerInitApp = [
  routes.logout.path,
  routes.tseConfig.path,
  // routes.openIdAuthCallback.path,
];
