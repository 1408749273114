import {
  clearSection,
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
  resetApCvConfiguration,
  resetGlobalConfiguration,
  resetINSiConfiguration,
}                                                                                        from 'dmpconnectjsapp-base/actions';
import commands
                                                                                         from 'dmpconnectjsapp-base/actions/config/commands';
import {
  apiSections,
  dmpconnectActionConstants,
  dmpconnectInitActionConstants,
}                                                                                        from 'dmpconnectjsapp-base/constants';
import {
  getConfigurationValue,
}                                                                                        from 'dmpconnectjsapp-base/helpers/accessors';
import {
  handleCancelCommand,
}                                                                                        from 'dmpconnectjsapp-base/sagas/connectorSagas';
import { put, select, take, takeEvery, }                                                 from 'redux-saga/effects';
import {
  apCvConfigFromEnv,
  connectorConfigFromEnv,
  insiConfigFromEnv,
}                                                                                        from '../../reducers/configFromEnv';
import { getAction, resetPersistedAppConfigurationFromEnv, }                             from '../actions';
import { dmpconnectApplicationActionConstants, dmpconnectConfigurationActionConstants, } from '../constants';
import { forceApCvActivationAfterStartDate, }                                            from './apCvSagas';

import { hardResetApplication, } from './utilsSagas';

const handleRequestPcscReaders = function* () {

  yield put(getAction(
    commands.getPcscReaders,
    apiSections.PCSC_READERS_SECTION,
    null,
    { synchronous: true },
  ));
};

const getVitaleXmlSurcharged = ({ dmpConnectPersistedAppConfiguration }) => ({
  vitaleXmlContent: getConfigurationValue('vitaleXmlContent', dmpConnectPersistedAppConfiguration),
  vitaleXmlType:    getConfigurationValue('vitaleXmlType', dmpConnectPersistedAppConfiguration),
});

const readVitaleCardProcess = function* () {
  const { vitaleXmlContent, vitaleXmlType } = yield select(getVitaleXmlSurcharged);

  if (vitaleXmlContent) {
    yield put(getAction(
      commands.setVitaleXmlContent,
      'vitaleXmlContent',
      {
        s_xmlContent: vitaleXmlContent,
        i_cardType:   Number(vitaleXmlType || 3),
      },
    ));
    const setXml = yield take(
      [
        dmpCommandSuccessContextualizedType('vitaleXmlContent'),
        dmpCommandFailureContextualizedType('vitaleXmlContent'),
      ],
    );
    if (setXml.type === dmpCommandFailureContextualizedType('vitaleXmlContent')) {
      return;
    }
  }

  yield put(clearSection(apiSections.VITALE_CARD_SECTION));
  yield put(clearSection(apiSections.VITALE_DATA_SECTION));
  yield put(clearSection(apiSections.INS_FROM_VITALE_CARD));
  yield put(clearSection(apiSections.CERTIFIFIED_IDENTITY_SECTION));
  yield put(getAction(
    commands.readVitaleCard,
    apiSections.VITALE_CARD_SECTION,
    { i_getXmlContent: 1 },
  ));
};

const resetAllConfigHandler = function* () {
  yield put(resetGlobalConfiguration(connectorConfigFromEnv));
  yield put(resetINSiConfiguration(insiConfigFromEnv));
  yield put(resetApCvConfiguration(apCvConfigFromEnv));
  yield put(resetPersistedAppConfigurationFromEnv());
};

export const handleCallbacks = function* () {

  // yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.VITALE_SECTION), readVitaleCardAfterGetVitaleSuccess);
  yield takeEvery(dmpconnectActionConstants.DMPC_CANCEL_COMMAND, handleCancelCommand);

  yield takeEvery(dmpconnectActionConstants.DMPC_REQUES_PCSC_READERS, handleRequestPcscReaders);

  yield takeEvery(dmpconnectApplicationActionConstants.DMPC_READ_VITALE_PROCESS, readVitaleCardProcess);

  yield takeEvery(dmpconnectConfigurationActionConstants.DMPC_RESET_ALL_CONFIGURATION_FROM_ENV, resetAllConfigHandler);

  // APCV AUTO ACTIVATION APRES LA DATE CONFIGUREE
  yield takeEvery(dmpconnectInitActionConstants.DMPC_INIT_APPLICATION, forceApCvActivationAfterStartDate);

  yield takeEvery(dmpconnectApplicationActionConstants.HARD_RESET, hardResetApplication);

};
