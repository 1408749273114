import { LOCATION_CHANGE }                       from 'connected-react-router';
import {
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
}                                                from 'dmpconnectjsapp-base/actions';
import {
  apiSections
}                                                from 'dmpconnectjsapp-base/constants';
import merge                                     from 'lodash.merge';
import { dmpconnectApplicationActionConstants, } from '../constants';

const initialState = {
  urlProcessed:           false,
  saasTokenOk:            true,
  dashboardInitOk:        false,
  modalError:             null,
  vitaleCardSerialNumber: null,
  apCvContextToDestroy:   false,
  gettingLogs:            false,
  showLogsPopup:          false,
  logsPopupError:         undefined,
  logsPopupOnClose:       undefined,
};

export function dmpconnectApplication(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return { ...state, urlProcessed: action.payload.action === 'POP' ? false : state.urlProcessed };
    case dmpconnectApplicationActionConstants.DMPS_SET_URL_PROCESSED:
      return { ...state, urlProcessed: action.processed };
    case dmpconnectApplicationActionConstants.DMPC_RESET_APPLICATION:
      return initialState;
    case dmpconnectApplicationActionConstants.DMPC_SET_DMP_SEARCH_PANEL_OPENED:
      return merge({}, state, { dmpSearchOpenedPanel: action.panel });
    case dmpconnectApplicationActionConstants.DMPC_SET_MODAL_ERROR: {
      const { modalError, ...newState } = state;
      return merge({}, newState, { modalError: action.modalError });
    }
    case dmpconnectApplicationActionConstants.DMPC_CLEAR_MODAL_ERROR: {
      const { modalError, ...newState } = state;
      return { ...newState, modalError: null };
    }
    case dmpconnectApplicationActionConstants.DMPC_DASHBOARD_INIT:
      return merge({}, state, { dashboardInitOk: false });
    case dmpconnectApplicationActionConstants.DMPC_DASHBOARD_INIT_OK:
      return merge({}, state, { dashboardInitOk: action.status });
    case dmpconnectApplicationActionConstants.DMPC_SAAS_TOKEN_VALIDATED: {
      return merge({}, state, { saasTokenOk: action.validated });
    }
    case dmpconnectApplicationActionConstants.DMPC_SET_MODAL_CONFIRMATION: {
      return merge({}, state, { modalConfirmation: action.modalConfirmation });
    }
    case dmpconnectApplicationActionConstants.DMPC_SET_MODAL_INFORMATION: {
      return merge({}, state, { modalInformation: action.modalInformation });
    }
    case dmpconnectApplicationActionConstants.SET_VITALE_CARD_SERIAL_NUMBER: {
      return merge({}, state, { vitaleCardSerialNumber: action.serialNumber });
    }

    case dmpCommandSuccessContextualizedType(apiSections.GET_APCV_CONTEXT): {
      return merge({}, state, { apCvContextToDestroy: true });
    }
    case dmpCommandFailureContextualizedType(apiSections.GET_APCV_CONTEXT):
    case dmpCommandSuccessContextualizedType(apiSections.RELEASE_APCV_CONTEXT):
      return merge({}, state, { apCvContextToDestroy: false });

    case dmpconnectApplicationActionConstants.GOT_LOGS:
      return merge({}, state, { gettingLogs: false });
    case dmpconnectApplicationActionConstants.GET_LOGS:
      return merge({}, state, { gettingLogs: true });
    case dmpconnectApplicationActionConstants.SHOW_LOGS_POPUP:
      return merge({}, state, {
        showLogsPopup:    action.show,
        logsPopupError:   action.error,
        logsPopupOnClose: action.onClose,
      });

    case dmpconnectApplicationActionConstants.SET_FORCED_VITALE_READER:
      return { ...state, forcedVitaleReader: action.readerName };

    default:
      return state;
  }
}
