import base64 from 'base-64';
import mergeWith from 'lodash.mergewith';
import utf8 from 'utf8';
export function generateId(len) {
  return "".concat(Math.random().toString(36).substr(2, 9), "-").concat(Math.random().toString(36).substr(2, 9));
}
export var b64EncodeUnicode = function b64EncodeUnicode(str) {
  var bytes = utf8.encode(str);
  try {
    return base64.encode(bytes);
  } catch (error) {
    return bytes;
  }
};
export var b64DecodeUnicode = function b64DecodeUnicode(str) {
  var data = str.replace(/[\n\r]+/g, '');
  var bytes = base64.decode(data);
  try {
    return utf8.decode(bytes);
  } catch (error) {
    return bytes;
  }
};
export var mergeForceNewValues = function mergeForceNewValues(state, newState) {
  return mergeWith({}, state, newState, function (statePart, newStatePart) {
    var statePartIsObject = statePart && typeof statePart === 'object';
    var newStatePartIsObject = newStatePart && typeof newStatePart === 'object';
    var statePartIsArray = statePart && Array.isArray(statePart);
    var newStatePartIsArray = newStatePart && Array.isArray(newStatePart);
    if (statePartIsArray && newStatePartIsArray && newStatePart.length === 0) {
      return newStatePart;
    }
    if (statePartIsObject && newStatePartIsObject && Object.keys(newStatePart).length === 0) {
      return newStatePart;
    }
    return undefined;
  });
};