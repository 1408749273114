import { push }                           from "connected-react-router";
import { getApiType, getCurrentPathname } from 'dmpconnectjsapp-base/helpers/accessors';
import PropTypes                          from 'prop-types';
import React, { Component }               from 'react';

import { Button, Modal, }                                                                             from 'react-bootstrap';
import Collapse                                                                                       from 'react-bootstrap/Collapse';
import { connect }                                                                                    from 'react-redux';
import { clearModalError }                                                                    from '../../dmpconnect/actions';
import { API_TYPES }                                                                                  from '../../dmpconnect/constants';
import { getErrorDescription, getErrorMessage, getErrorSimplifiedDisplay, isErrorDisplaySimplified, } from '../../dmpconnect/errors';
import {
  errorActions, errorActionsMessages, executeErrorAction
}                                                                                                     from '../../dmpconnect/errors/errorActions';
import {
  errorSeverities
}                                                                                                     from '../../dmpconnect/errors/errorConfiguration';
import GetLogs                                                                                        from '../Common/Logs/GetLogsButton';
import ErrorDetails                                                                                   from '../Common/Message/ErrorDetails';

class ErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state       = {
      showDetails: false,
      show       : true,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  getErrorDescription(modalError) {
    if (modalError) {
      const { error: { customErrorMessage } = {} } = modalError;
      return {
        ...getErrorDescription(modalError.error),
        customErrorMessage,
      };
    }
    return null;
  }

  getHeaderClass(errorDescription) {
    const { modalError } = this.props;

    if (modalError && modalError.forceAction && modalError.forceAction === errorActions.NONE) {
      return 'bg-warning';
    }

    return errorDescription.severity === errorSeverities.HIGH_SEVERITY ? 'bg-danger' : 'bg-warning';
  }

  handleClose(logError) {
    const { dispatch, modalError } = this.props;

    const error = modalError || logError;

    if (error.forceReconnect === true) {
      dispatch(push('/'));
    } else if (error && error.forceAction) {
      executeErrorAction({ action: error.forceAction }, dispatch);
    } else {
      const errorDescription = this.getErrorDescription(error);
      if (errorDescription === null) {
        return;
      }
      executeErrorAction(errorDescription, dispatch);
    }
    dispatch(clearModalError());
    this.setState({ show: true, showDetails: false });
  }

  toggleDetails() {
    this.setState(prevState => ({
      showDetails: !prevState.showDetails,
    }));
  }

  render() {
    const { modalError, apiType } = this.props;
    const { showDetails, show }   = this.state;
    // const show = modalError !== null;
    const errorDescription        = this.getErrorDescription(modalError);

    const showDetailsToggleClass = showDetails ? 'active' : '';
    const showLogs               = apiType !== API_TYPES.REST && ((modalError && modalError.logs) || (errorDescription && errorDescription.logs));
    return (
      <>
        {modalError
         && (
           <Modal
             className="error-modal"
             show={show}
             onHide={() => this.handleClose()}
             centered
             backdrop="static"
           >
             <Modal.Header className={this.getHeaderClass(errorDescription)} closeButton>
               <Modal.Title as="h5" className="text-white">{errorDescription.title}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
               <div style={{ whiteSpace: 'pre-wrap' }}>
                 {(
                   errorDescription.customErrorMessage
                   || (
                     isErrorDisplaySimplified(modalError.error)
                     ? getErrorSimplifiedDisplay(modalError.error)
                     : getErrorMessage(modalError.error)
                   )
                 )}
               </div>
               {errorDescription.showDetails
                && (
                  <>
                    <button
                      type="button"
                      className={`btn btn-text font-weight-normal has-arrow before ${showDetailsToggleClass}`}
                      onClick={() => this.toggleDetails()}
                    >
                      Détails
                    </button>
                    <Collapse in={showDetails}>
                      <div>
                        <ErrorDetails
                          details={modalError.details}
                          error={modalError.error}
                        />
                      </div>
                    </Collapse>
                  </>
                )
               }
             </Modal.Body>
             <Modal.Footer className={showLogs !== false ? 'd-flex justify-content-between' : ''}>
               {showLogs !== false && (
                 <GetLogs
                   onOpen={() => this.setState({ show: false })}
                   error={modalError.details}
                   onClose={() => this.handleClose(modalError)}
                 />
               )}
               <Button variant="outline-secondary" onClick={() => this.handleClose()}>
                 {modalError.forceReconnect === true ? (
                   errorActionsMessages[errorActions.CLOSE_SESSION]
                 ) : (
                    errorActionsMessages[modalError.forceAction || errorDescription.action]
                  )}
               </Button>
             </Modal.Footer>
           </Modal>
         )}
      </>
    );
  }
}

ErrorModal.propTypes = {
  modalError: PropTypes.object,
  dispatch  : PropTypes.func.isRequired,
  apiType   : PropTypes.string.isRequired,
};

ErrorModal.defaultProps = {
  modalError: {},
};

function mapStateToProps(state) {
  const { dmpconnectApplication: { modalError } } = state;
  return {
    currentPathName: getCurrentPathname(state),
    modalError,
    apiType        : getApiType(state),
  };
}

const connectedErrorModal = connect(mapStateToProps)(ErrorModal);

export default connectedErrorModal;
