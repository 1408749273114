import { apiSections }                                                   from 'dmpconnectjsapp-base/constants';
import { getInteropCodesFromState, getPracticeLocationSettingFromState } from 'dmpconnectjsapp-base/helpers/accessors';
import React                                                             from 'react';
import { put, select }                                                   from 'redux-saga/effects';
import { setModalError, }                                                from '../actions';

export const invalidPracticeLocationErrorHandler = function* ({
                                                                error, context, command, modalError, silentError,
                                                              }) {
  const { s_apiErrorExtendedInformations, customErrorMessage } = error;

  if (!customErrorMessage && s_apiErrorExtendedInformations.toLowerCase().indexOf('structure introuvable ou inactive') !== -1) {
    const activitySectors            = yield select(getInteropCodesFromState, 'activitySectors');
    const { PracticeLocations = {} } = yield select(({ dmpconnect: { [apiSections.CPX_CARD_SECTION]: cpxCard } }) => cpxCard);
    const practiceLocationIndex      = yield select(getPracticeLocationSettingFromState);
    const practiceLocation           = PracticeLocations[practiceLocationIndex];
    const activitySector             = practiceLocation && practiceLocation.s_practiceLocationActivity ? activitySectors.find(as => as.code === practiceLocation.s_practiceLocationActivity) : null;
    // {
    //   type: 'DMPC_COMMAND_FAILURE',
    //     data: {

    // },
    //   command: {
    //     s_commandName: 'hl_getAccessibleDMPList',
    //       s_type: 'LASTAUTORIZATION',
    //       s_date: '20180705',
    //       s_sessionId: '{E3BD3909-1F81-4A7E-ABF6-24E6D48381FB}',
    //       timeout: 600
    //   },
    //   context: {
    //     section: 'accessibleDMPList',
    //       subSection: '899700232871',
    //       params: {}
    //   },
    //   silentError: true
    // }
    yield put({
                type: 'DMPC_COMMAND_FAILURE',
                data: {
                  ...error,
                  customErrorMessage: (
                                        // s_practiceLocationActivity(pin):"SA01"
                                        // s_practiceLocationBillingNumber(pin):"10B0134227"
                                        // s_practiceLocationHealthcareSettings(pin):""
                                        // s_practiceLocationName(pin):"HOPITAL DES 3. DAMES"
                                        // s_practiceLocationStructureId(pin):"10B0134227"
                                        // eslint-disable-next-line react/jsx-filename-extension
                                        <>
                                          <div>Votre situation d&apos;exercice en carte ne vous permet pas
                                            d&apos;accéder au DMP (structure fermée ou autre raison). Se rapprocher de
                                            votre Ordre et/ou de la CNAM.
                                          </div>
                                          {practiceLocation && (
                                            <>
                                              <br/>
                                              <ul>
                                                <li key="invalidPracticeLocationName">
                                                  Structure :
                                                  {' '}
                                                  {practiceLocation.s_practiceLocationName}
                                                </li>
                                                <li key="invalidPracticeLocationId">
                                                  Identifiant de structure :
                                                  {' '}
                                                  {practiceLocation.s_practiceLocationStructureId}
                                                </li>
                                                <li key="invalidPracticeLocationActivity">
                                                  Secteur d&apos;activité :
                                                  {' '}
                                                  {activitySector.label}
                                                </li>
                                              </ul>
                                            </>
                                          )}
                                        </>
                                      ),
                },
                command,
                context,
              });
  } else if (!silentError) {
    yield put(setModalError(modalError));
  }
};
