import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
import { API_TYPES, mssLoginTypes } from '../../constants';
import { getActiveMssAccountConfig, getApiType, getAuthenticationType, getConfigurationValue, getDmpConnectJSHost, getDmpConnectJSHttpProtocol, getDmpConnectJSPort, getEsRestHost, getEsRestPort, getEsRestUrl, getEsUser, getMssAccountConfigByEmail, getMssAuthenticationId, getMssLoginType, getSessionId, isAirActive } from '../../helpers/accessors';
import { authenticationTypes, commandsToTransactions, getAccessRightsProps } from '../../rules/accessRights';
import commands, { commandSubTypes, commandTypes, generateCommonDpParams, generateCommonMssCertParams, generateMssImapServerParams, generateMssSmtpServerParams, mssSubTypes } from './commands';
import JSONCommandParams from './JSONCommandParams';
import restCommandParams, { getCommonRestDPParams, getCommonRestParams } from './restCommandParams';
import webSocketCommandParams from './webSocketCommandParams';
var getCommandConfig = function getCommandConfig(commandName, apiType, subConfig) {
  var commandConfig;
  if ([API_TYPES.WS, API_TYPES.JSPOST].includes(apiType)) {
    commandConfig = webSocketCommandParams[commandName];
  }
  if (apiType === API_TYPES.REST) {
    commandConfig = restCommandParams[commandName];
  }
  if (apiType === API_TYPES.JSON) {
    commandConfig = JSONCommandParams[commandName];
  }
  if (subConfig) {
    return commandConfig[subConfig];
  }
  return commandConfig;
};
export var doWeCheckCpxToken = function doWeCheckCpxToken(commandName, state) {
  var apiType = getApiType(state);
  var commandConfig = getCommandConfig(commandName, apiType);
  return commandConfig.validateolaqinPscToken === true;
};
var generateWSCommand = function generateWSCommand(commandConfig, state, values, sessionId, mssEmailConfig) {
  var commandName = commandConfig.commandName,
    paramsGetter = commandConfig.paramsGetter,
    _commandConfig$timeou = commandConfig.timeout,
    timeout = _commandConfig$timeou === void 0 ? 30 : _commandConfig$timeou,
    longRunningTimeout = commandConfig.longRunningTimeout,
    multipleCalls = commandConfig.multipleCalls,
    subType = commandConfig.subType;
  var commonDpParams;
  if (subType && subType === commandSubTypes.DP) {
    commonDpParams = generateCommonDpParams(state);
  }
  var mssAccountConfig = {};
  if (mssEmailConfig) {
    mssAccountConfig = getMssAccountConfigByEmail(state, mssEmailConfig, true);
  } else {
    mssAccountConfig = getActiveMssAccountConfig(state);
  }
  var _mssAccountConfig = mssAccountConfig,
    mssLoginType = _mssAccountConfig.mssLoginType;
  var commonImapParams;
  if (subType && subType === commandSubTypes.MSS_IMAP) {
    commonImapParams = generateMssImapServerParams(state, mssAccountConfig);
  }
  var commonSmtpParams;
  if (subType && subType === commandSubTypes.MSS_SMTP) {
    commonSmtpParams = generateMssSmtpServerParams(state, mssAccountConfig);
  }
  var commonMssCertParams = generateCommonMssCertParams(state, subType, mssAccountConfig);
  var commonMssOtpParams;
  if (subType && subType === commandSubTypes.MSS_WS) {
    var mssAuthenticationId = getMssAuthenticationId(state);
    if (mssLoginType === mssLoginTypes.OTP || mssLoginType === mssLoginTypes.CERT) {
      commonMssOtpParams = {
        s_authenticationId: mssAuthenticationId
      };
    }
  }
  return {
    command: _objectSpread({
      s_commandName: commandName
    }, paramsGetter ? paramsGetter(values) : values, {
      s_sessionId: sessionId || getSessionId(state) || undefined,
      i_timeout: Number(timeout),
      timeout: timeout,
      longRunningTimeout: longRunningTimeout,
      multipleCalls: multipleCalls
    }, commonMssOtpParams, commonDpParams, commonImapParams, commonSmtpParams, commonMssCertParams)
  };
};
var generateRestCommand = function generateRestCommand(commandConfig, state, values, commandName) {
  var forceEs = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var mssEmailConfig = arguments.length > 5 ? arguments[5] : undefined;
  var dmpconnectPersistedConnectorConfiguration = state.dmpconnectPersistedConnectorConfiguration;
  var connectorToken = getConfigurationValue('connectorJWT', dmpconnectPersistedConnectorConfiguration);
  var air = isAirActive(state);
  var esUser = getEsUser(state);
  var transactionToCheck = commandsToTransactions[commandName];
  var _getActiveMssAccountC = getActiveMssAccountConfig(state),
    mssApiType = _getActiveMssAccountC.mssApiType; // do we force es command ?
  if (forceEs) {
    air = false;
  } else if (air && transactionToCheck) {
    var _getAccessRightsProps = getAccessRightsProps(state),
      forcedEsTransactions = _getAccessRightsProps.accessRights.forcedEsTransactions;
    var force = forcedEsTransactions[transactionToCheck] || false;
    air = !force;
  }
  var commonParams;
  if (commandConfig.subType && commandConfig.subType === commandSubTypes.DP) {
    commonParams = getCommonRestDPParams(state, esUser);
  } else {
    commonParams = getCommonRestParams(state, esUser, air, commandConfig.subType, commandName);
  }
  var mssAccountConfig = {};
  if (mssEmailConfig) {
    mssAccountConfig = getMssAccountConfigByEmail(state, mssEmailConfig, true);
  } else {
    mssAccountConfig = getActiveMssAccountConfig(state);
  }
  var commonImapParams;
  var commonSmtpParams;
  if (commandConfig.subType && commandConfig.subType === commandSubTypes.MSS && mssApiType === mssSubTypes.IMAP) {
    if (commandConfig.smtp) {
      commonSmtpParams = generateMssSmtpServerParams(state, mssAccountConfig);
    }
    if (commandConfig.imap) {
      commonImapParams = generateMssImapServerParams(state, mssAccountConfig);
    }
  }
  var esRestHost = getEsRestHost(state);
  var esRestPort = getEsRestPort(state);
  var esRestUrl = getEsRestUrl(state);
  var endpoint = commandConfig.endpoint,
    paramsGetter = commandConfig.paramsGetter,
    _commandConfig$timeou2 = commandConfig.timeout,
    timeout = _commandConfig$timeou2 === void 0 ? 30 : _commandConfig$timeou2;
  return {
    url: esRestUrl ? esRestUrl : "".concat(esRestHost, ":").concat(esRestPort),
    endpoint: endpoint,
    jwt: connectorToken,
    command: _objectSpread({}, commonParams, paramsGetter ? paramsGetter(values) : values, commonSmtpParams, commonImapParams, {
      i_timeout: Number(timeout)
    })
  };
};
var generateHttpCommand = function generateHttpCommand(commandConfig, state, values) {
  var dmpcjsHost = getDmpConnectJSHost(state);
  var dmpcjsPort = getDmpConnectJSPort(state);
  var dmpcjsHttpProtocol = getDmpConnectJSHttpProtocol(state);

  // const {dmpconnectPersistedConnectorConfiguration} = state;
  // const connectorToken = getConfigurationValue('connectorJWT', dmpconnectPersistedConnectorConfiguration);
  var endpoint = commandConfig.endpoint,
    paramsGetter = commandConfig.paramsGetter,
    _commandConfig$timeou3 = commandConfig.timeout,
    timeout = _commandConfig$timeou3 === void 0 ? 30 : _commandConfig$timeou3;
  return {
    url: "".concat(dmpcjsHttpProtocol, "://").concat(dmpcjsHost, ":").concat(dmpcjsPort),
    endpoint: endpoint,
    // jwt: connectorToken,
    command: _objectSpread({}, paramsGetter ? paramsGetter(values) : values, {
      i_timeout: Number(timeout)
    })
  };
};
var generateCommand = function generateCommand(synchronous, commandName, state, values, sessionId) {
  var forceEs = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  var subConfig = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : null;
  var mssEmailConfig = arguments.length > 7 ? arguments[7] : undefined;
  var apiType = getApiType(state);
  var commandConfig = getCommandConfig(commandName, apiType, subConfig);
  if (!commandConfig) {
    return false;
  }
  // TODO : si la commande n'est pas configuré, renvoyer un success automatiquement

  if (commandConfig.forwardTo) {
    var forwardTo = commandConfig.forwardTo,
      paramsGetter = commandConfig.paramsGetter;
    return {
      type: forwardTo,
      command: _objectSpread({}, paramsGetter ? paramsGetter(values) : values)
    };
  }
  var restCommandPart = '';
  var command;
  if (commandConfig.type === commandTypes.REST) {
    command = generateRestCommand(commandConfig, state, values, commandName, forceEs, mssEmailConfig);
    restCommandPart = '_REST';
  } else if (commandConfig.type === commandTypes.HTTP) {
    command = generateHttpCommand(commandConfig, state, values);
    restCommandPart = '_HTTP';
  } else {
    command = generateWSCommand(commandConfig, state, values, sessionId, mssEmailConfig);
  }

  // get the websocket command, but send it to js2 api
  if (apiType === API_TYPES.JSPOST && commandConfig.type === commandTypes.WS) {
    // const {dmpconnectPersistedConnectorConfiguration} = state;
    // const connectorToken = getConfigurationValue('connectorJWT', dmpconnectPersistedConnectorConfiguration);
    var dmpcjsHost = getDmpConnectJSHost(state);
    var dmpcjsPort = getDmpConnectJSPort(state);
    var dmpcjsHttpProtocol = getDmpConnectJSHttpProtocol(state);
    command = {
      command: command.command,
      // jwt: connectorToken,
      url: "".concat(dmpcjsHttpProtocol, "://").concat(dmpcjsHost, ":").concat(dmpcjsPort),
      endpoint: "/api/".concat(command.command.s_commandName)
    };
    restCommandPart = '_HTTP';
  }

  // get the websocket command, but send it to json api
  if (apiType === API_TYPES.JSON) {
    restCommandPart = '_JSON';
  }
  var cpxLoginType = getConfigurationValue('cpxLoginType', state.dmpConnectPersistedAppConfiguration);
  var olaqinJsRestSynchConfig = getConfigurationValue('olaqinJsRestSynchConfig', state.dmpConnectPersistedAppConfiguration);
  var syncCommandPart = synchronous === true || commandConfig.type === commandTypes.REST && commandConfig.subType === commandSubTypes.MSS || commandConfig.type === commandTypes.WS && apiType === API_TYPES.JSPOST && cpxLoginType === 'OPENID' // olaqin
  && olaqinJsRestSynchConfig === true && commandConfig.olaqinJsRestSynchrone === true ? '_SYNCHRONOUS' : '';
  var type = "DMPC_SEND".concat(restCommandPart).concat(syncCommandPart, "_COMMAND");
  return _objectSpread({
    type: type
  }, command);
};

/**
 * getCommandAction options argument
 * @typedef {Object}        ActionOptions
 * @property {boolean}     [synchronous=false] - is it synchronous or async
 * @property {object}      [contextParams] - values to save to context.params
 * @property {object}      [contextExtra] - values to add to context
 * @property {string}      [subSection=''] - value to use as the subSection id
 * @property {string}      [apiType] - Websocket or Rest
 * @property {string}      [silentError] - make errors silent or not
 * @property {string}      [sessionId] - sessionId to use, can be omitted if using the default session
 * @property {string}      [subConfig] - alternative version of the command. ex: MSS WEB vs MSS IMAP
 */

/**
 * @description           return an DmpConnect-JS Command action
 * @param {string}        commandName - the command to send
 * @param {string}        section - where to save the context in the store
 * @param {*}             [values] - the values to send in the command
 * @param {ActionOptions} [options] - the options
 */
var getCommandAction = function getCommandAction(commandName, section, values) {
  var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
    _ref$synchronous = _ref.synchronous,
    synchronous = _ref$synchronous === void 0 ? false : _ref$synchronous,
    _ref$contextParams = _ref.contextParams,
    contextParams = _ref$contextParams === void 0 ? {} : _ref$contextParams,
    _ref$contextExtra = _ref.contextExtra,
    contextExtra = _ref$contextExtra === void 0 ? {} : _ref$contextExtra,
    _ref$dataExtra = _ref.dataExtra,
    dataExtra = _ref$dataExtra === void 0 ? {} : _ref$dataExtra,
    _ref$subSection = _ref.subSection,
    subSection = _ref$subSection === void 0 ? '' : _ref$subSection,
    _ref$forceEs = _ref.forceEs,
    forceEs = _ref$forceEs === void 0 ? false : _ref$forceEs,
    silentError = _ref.silentError,
    _ref$sessionId = _ref.sessionId,
    sessionId = _ref$sessionId === void 0 ? null : _ref$sessionId,
    _ref$subConfig = _ref.subConfig,
    subConfig = _ref$subConfig === void 0 ? null : _ref$subConfig,
    _ref$remote = _ref.remote,
    remote = _ref$remote === void 0 ? false : _ref$remote,
    _ref$noRetries = _ref.noRetries,
    noRetries = _ref$noRetries === void 0 ? false : _ref$noRetries;
  return function (dispatch, getState) {
    var state = getState();
    // special process for TD0.2
    var authenticationType = getAuthenticationType(state);
    if (commandName === commands.getDirectAuthenticationDMPStatus) {
      commandName = authenticationType === authenticationTypes.DIRECT ? commands.getDirectAuthenticationDMPStatus : commands.getDMPStatus;
    }
    dispatch(_objectSpread({}, generateCommand(synchronous, commandName, state, values, sessionId, forceEs, subConfig, contextExtra.mssEmailConfig), {
      context: _objectSpread({
        section: section,
        subSection: !!subSection ? subSection : undefined,
        params: contextParams
      }, contextExtra, {
        dataExtra: dataExtra,
        remote: remote,
        noRetries: noRetries
      }),
      silentError: silentError
    }));
  };
};
export default getCommandAction;