import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
import moment from 'moment';
import 'moment/locale/fr';
import { mssSubTypes } from '../actions/config/commands';
import { API_TYPES, apiSections, mssLoginTypes } from '../constants';
import { authenticationTypes, getAccessRightsProps } from '../rules/accessRights';
import { decodeIns } from '../utils/insUtils';
import { isReady } from './common';
export var getApplicationId = function getApplicationId(_ref) {
  var dmpconnectConnectorConfig = _ref.dmpconnectConnectorConfig;
  return getConfigurationValue('applicationId', dmpconnectConnectorConfig);
};
export var getProductionMode = function getProductionMode(_ref2) {
  var dmpconnectConnectorConfig = _ref2.dmpconnectConnectorConfig;
  return getConfigurationValue('production_mode', dmpconnectConnectorConfig);
};
export var getDmpConnectJSProtocol = function getDmpConnectJSProtocol(_ref3) {
  var dmpconnectConnectorConfig = _ref3.dmpconnectConnectorConfig;
  return getConfigurationValue('dmpcjsProtocol', dmpconnectConnectorConfig);
};
export var getDmpConnectJSHttpProtocol = function getDmpConnectJSHttpProtocol(_ref4) {
  var dmpconnectConnectorConfig = _ref4.dmpconnectConnectorConfig;
  return getConfigurationValue('dmpcjsHttpProtocol', dmpconnectConnectorConfig);
};
export var getDmpConnectJSHost = function getDmpConnectJSHost(_ref5) {
  var dmpconnectConnectorConfig = _ref5.dmpconnectConnectorConfig;
  return getConfigurationValue('dmpcjsHost', dmpconnectConnectorConfig);
};
export var getDmpConnectJSPort = function getDmpConnectJSPort(_ref6) {
  var dmpconnectConnectorConfig = _ref6.dmpconnectConnectorConfig;
  return getConfigurationValue('dmpcjsPort', dmpconnectConnectorConfig);
};
export var getEsRestHost = function getEsRestHost(_ref7) {
  var dmpconnectConnectorConfig = _ref7.dmpconnectConnectorConfig;
  return getConfigurationValue('esRESTHost', dmpconnectConnectorConfig);
};
export var getEsRestPort = function getEsRestPort(_ref8) {
  var dmpconnectConnectorConfig = _ref8.dmpconnectConnectorConfig;
  return getConfigurationValue('esRESTPort', dmpconnectConnectorConfig);
};
export var getEsRestUrl = function getEsRestUrl(_ref9) {
  var dmpconnectConnectorConfig = _ref9.dmpconnectConnectorConfig;
  return getConfigurationValue('esRestUrl', dmpconnectConnectorConfig);
};
export var getSelectedINS = function getSelectedINS(_ref10) {
  var selectedIns = _ref10.dmpconnect.selectedIns;
  return selectedIns;
};
export var getCurrentPathname = function getCurrentPathname(state) {
  var pathname = state.router.location.pathname;
  return pathname;
};
export var getCpxCardStatus = function getCpxCardStatus(state) {
  var dmpconnect = state.dmpconnect;
  var cpxCard = dmpconnect.cpxCard;
  return cpxCard.s_status;
};
export var getdmpconnectConnectorConfig = function getdmpconnectConnectorConfig(state) {
  var dmpconnectConnectorConfig = state.dmpconnectConnectorConfig;
  return dmpconnectConnectorConfig;
};
export var getDmpconnectPersistedConnectorConfig = function getDmpconnectPersistedConnectorConfig(state) {
  var dmpconnectPersistedConnectorConfiguration = state.dmpconnectPersistedConnectorConfiguration;
  return dmpconnectPersistedConnectorConfiguration;
};
export var getDmpconnectInit = function getDmpconnectInit(state) {
  var dmpconnectInit = state.dmpconnectInit;
  return dmpconnectInit;
};
export var getDmpconnectCPxConfiguration = function getDmpconnectCPxConfiguration(state) {
  var dmpconnectCPxConfiguration = state.dmpconnectCPxConfiguration;
  return dmpconnectCPxConfiguration;
};
export var getAuthenticationType = function getAuthenticationType(state) {
  var _state$dmpconnectUser = state.dmpconnectUser,
    _state$dmpconnectUser2 = _state$dmpconnectUser === void 0 ? {} : _state$dmpconnectUser,
    _state$dmpconnectUser3 = _state$dmpconnectUser2.authenticationType,
    authenticationType = _state$dmpconnectUser3 === void 0 ? null : _state$dmpconnectUser3;
  return authenticationType;
};
export var getEsUser = function getEsUser(state) {
  var _state$dmpconnectUser4 = state.dmpconnectUser.esUser,
    esUser = _state$dmpconnectUser4 === void 0 ? null : _state$dmpconnectUser4;
  return esUser;
};
export var getSessionId = function getSessionId(state) {
  var dmpconnect = state.dmpconnect;
  var session = dmpconnect.session;
  return session.s_sessionId;
};
export var isUserLoggedIn = function isUserLoggedIn(state) {
  var dmpconnectUser = state.dmpconnectUser;
  return dmpconnectUser.loggedIn;
};
export var getLoginReferer = function getLoginReferer(state) {
  var dmpconnectUser = state.dmpconnectUser;
  return dmpconnectUser.loginReferer;
};
export var getEsUserMetaDatas = function getEsUserMetaDatas(user) {
  if (!user) {
    return {};
  }
  var _user$profile$https = user.profile['https://efficience.icanopee.fr/user_metadata'],
    metadata = _user$profile$https === void 0 ? {} : _user$profile$https;
  return metadata;
};
export var extractDate = function extractDate(date) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD/MM/YYYY';
  var utc = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return utc ? moment.utc(date, [format], true) : moment(date, [format], true);
};
export var formatDate = function formatDate(date) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD/MM/YYYY';
  return date.format(format);
};
export var getDirectAuthenticationStatus = function getDirectAuthenticationStatus(dmpconnect, ins) {
  var directAuthenticationDMPStatus = dmpconnect.directAuthenticationDMPStatus;
  if (directAuthenticationDMPStatus && directAuthenticationDMPStatus[ins]) {
    return directAuthenticationDMPStatus[ins];
  }
  return null;
};
export var getFormattedNames = function getFormattedNames(dmpDirectAuthenticationStatus) {
  if (!isReady(dmpDirectAuthenticationStatus)) {
    return null;
  }
  var ExistingTestAnswer = dmpDirectAuthenticationStatus.ExistingTestAnswer;
  var AdminData = ExistingTestAnswer.AdminData;
  var s_patientGivenName = AdminData.s_patientGivenName,
    s_patientName = AdminData.s_patientName;
  return "".concat(s_patientGivenName, " ").concat(s_patientName);
};
export var getUserConfiguration = function getUserConfiguration(cpxCard, key, dmpConfiguration) {
  var defaultValue = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var s_internalId = cpxCard.s_internalId;
  if (dmpConfiguration && dmpConfiguration[s_internalId] && ![null, undefined].includes(dmpConfiguration[s_internalId][key])) {
    return dmpConfiguration[s_internalId][key];
  }
  return defaultValue;
};
export var getConfigurationValue = function getConfigurationValue(key, dmpConfiguration) {
  if (dmpConfiguration && dmpConfiguration[key] !== undefined) {
    return dmpConfiguration[key];
  }
  return null;
};
export var getInteropCodesFromState = function getInteropCodesFromState(state, key) {
  var codes = state.dmpconnectInteropCodes[apiSections.INTEROPCODES][key];
  return codes;
};
export var getSpecialitiesInteropCodesFromState = function getSpecialitiesInteropCodesFromState(state) {
  var _state$dmpconnectInte = state.dmpconnectInteropCodes[apiSections.INTEROPCODES],
    physicianSpecialities = _state$dmpconnectInte.physicianSpecialities,
    dentistSpecialities = _state$dmpconnectInte.dentistSpecialities,
    pharmacistSpecialties = _state$dmpconnectInte.pharmacistSpecialties;
  return {
    '10': physicianSpecialities,
    '21': pharmacistSpecialties,
    '40': dentistSpecialities
  };
};
export var getNosCodesFromState = function getNosCodesFromState(state, table) {
  var codes = state.dmpconnectInteropCodes[apiSections.NOS_CODES];
  if (table) {
    return codes[table] || [];
  }
  return codes;
};
export var getDmpParameterFromState = function getDmpParameterFromState(state) {
  var params = state.dmpconnectInteropCodes[apiSections.DMP_PARAMETERS];
  return params;
};
export var isAirActive = function isAirActive(_ref11) {
  var dmpconnectConnectorConfig = _ref11.dmpconnectConnectorConfig;
  var air = getConfigurationValue('air', dmpconnectConnectorConfig);
  var apiType = getConfigurationValue('apiType', dmpconnectConnectorConfig);
  return air === true && apiType === API_TYPES.REST;
};
export var getApiType = function getApiType(_ref12) {
  var dmpconnectConnectorConfig = _ref12.dmpconnectConnectorConfig;
  return getConfigurationValue('apiType', dmpconnectConnectorConfig);
};
export var getDcParams = function getDcParams(_ref13) {
  var dmpconnectConnectorConfig = _ref13.dmpconnectConnectorConfig;
  return getConfigurationValue('dcparams', dmpconnectConnectorConfig);
};
export var getLicensingGroupId = function getLicensingGroupId(_ref14) {
  var dmpconnectConnectorConfig = _ref14.dmpconnectConnectorConfig;
  return getConfigurationValue('licensingGroupId', dmpconnectConnectorConfig);
};
export var getTseActive = function getTseActive(_ref15) {
  var dmpconnectTSEConfiguration = _ref15.dmpconnectTSEConfiguration;
  return getConfigurationValue('active', dmpconnectTSEConfiguration);
};
export var getTseWhoami = function getTseWhoami(_ref16) {
  var dmpconnectTSEConfiguration = _ref16.dmpconnectTSEConfiguration;
  return getConfigurationValue('whoami', dmpconnectTSEConfiguration);
};
export var getDmpConnectJSVersion = function getDmpConnectJSVersion(_ref17) {
  var dmpconnectConnectorConfig = _ref17.dmpconnectConnectorConfig;
  return getConfigurationValue('dmpcjsVersion', dmpconnectConnectorConfig);
};
export var getDmpConnectJSONVersion = function getDmpConnectJSONVersion(_ref18) {
  var dmpconnectConnectorConfig = _ref18.dmpconnectConnectorConfig;
  return getConfigurationValue('dmpcjsonVersion', dmpconnectConnectorConfig);
};
export var getCpxReader = function getCpxReader(_ref19) {
  var dmpconnectPersistedConnectorConfiguration = _ref19.dmpconnectPersistedConnectorConfiguration;
  return getConfigurationValue('cpxCardReader', dmpconnectPersistedConnectorConfiguration);
};
export var isEsLoginActive = function isEsLoginActive(state) {
  var dmpconnectESConfiguration = state.dmpconnectESConfiguration;
  return dmpconnectESConfiguration.esLoginActive;
};
export var getHealthcareSetting = function getHealthcareSetting(state) {
  var cpxCard = state.dmpconnect[apiSections.CPX_CARD_SECTION],
    dmpconnectESConfiguration = state.dmpconnectESConfiguration,
    dmpconnectCPxConfiguration = state.dmpconnectCPxConfiguration;
  var _getAccessRightsProps = getAccessRightsProps(state),
    authenticationType = _getAccessRightsProps.accessRights.authenticationType;
  var apiType = getApiType(state);
  var esLoginActive = dmpconnectESConfiguration.esLoginActive,
    healthcareSetting = dmpconnectESConfiguration.healthcareSetting;
  if ((esLoginActive || apiType === API_TYPES.REST) && [authenticationTypes.INDIRECT, authenticationTypes.AIR].includes(authenticationType)) {
    return healthcareSetting;
  }
  return getUserConfiguration(cpxCard, 'healthcareSetting', dmpconnectCPxConfiguration);
};
export var getPracticeLocationSettingFromState = function getPracticeLocationSettingFromState(state) {
  var cpxCard = state.dmpconnect[apiSections.CPX_CARD_SECTION],
    dmpconnectCPxConfiguration = state.dmpconnectCPxConfiguration;
  var practiceLocationSetting = getUserConfiguration(cpxCard, 'practiceLocationSetting', dmpconnectCPxConfiguration);
  return practiceLocationSetting !== null && Number(practiceLocationSetting) >= 0 ? Number(practiceLocationSetting) : practiceLocationSetting;
};
export var getPracticeSetting = function getPracticeSetting(state) {
  var cpxCard = state.dmpconnect[apiSections.CPX_CARD_SECTION],
    dmpconnectCPxConfiguration = state.dmpconnectCPxConfiguration,
    dmpconnectESConfiguration = state.dmpconnectESConfiguration;
  var esLoginActive = dmpconnectESConfiguration.esLoginActive,
    practiceSetting = dmpconnectESConfiguration.practiceSetting;
  var _getAccessRightsProps2 = getAccessRightsProps(state),
    authenticationType = _getAccessRightsProps2.accessRights.authenticationType;
  var apiType = getApiType(state);
  if ((esLoginActive || apiType === API_TYPES.REST) && [authenticationTypes.INDIRECT, authenticationTypes.AIR].includes(authenticationType)) {
    return practiceSetting;
  }
  return getUserConfiguration(cpxCard, 'practiceSetting', dmpconnectCPxConfiguration);
};
export var getCpxCode = function getCpxCode(state) {
  var _state$dmpconnect$api = state.dmpconnect[apiSections.CPX_CARD_SECTION],
    _state$dmpconnect$api2 = _state$dmpconnect$api === void 0 ? {} : _state$dmpconnect$api,
    _state$dmpconnect$api3 = _state$dmpconnect$api2.params,
    _state$dmpconnect$api4 = _state$dmpconnect$api3 === void 0 ? {} : _state$dmpconnect$api3,
    code = _state$dmpconnect$api4.code,
    encoded = _state$dmpconnect$api4.encoded;
  return encoded ? decodeIns(code || '') : code || '';
};
export var isALDiActive = function isALDiActive(_ref20) {
  var dmpconnectALDiConfiguration = _ref20.dmpconnectALDiConfiguration;
  return getConfigurationValue('active', dmpconnectALDiConfiguration);
};
export var getInsiConfig = function getInsiConfig(state) {
  var _getAccessRightsProps3 = getAccessRightsProps(state),
    authenticationType = _getAccessRightsProps3.accessRights.authenticationType;
  var getTransactionFrames = state.dmpConnectPersistedAppConfiguration.getTransactionFrames,
    dmpconnectCPxConfiguration = state.dmpconnectCPxConfiguration,
    _state$dmpconnectINSi = state.dmpconnectINSiConfiguration,
    active = _state$dmpconnectINSi.active,
    idam = _state$dmpconnectINSi.idam,
    numAm = _state$dmpconnectINSi.numAm,
    lpsVersion = _state$dmpconnectINSi.lpsVersion,
    lpsName = _state$dmpconnectINSi.lpsName,
    dmpconnectESConfiguration = state.dmpconnectESConfiguration,
    cpxCard = state.dmpconnect[apiSections.CPX_CARD_SECTION];
  var billingNumber = null;
  if (authenticationType === authenticationTypes.DIRECT) {
    billingNumber = getUserConfiguration(cpxCard, 'billingNumber', dmpconnectCPxConfiguration);
  } else {
    billingNumber = getConfigurationValue('es_finess', dmpconnectESConfiguration);
  }
  return {
    active: active,
    idam: idam,
    numAm: numAm,
    lpsVersion: lpsVersion,
    lpsName: lpsName,
    billingNumber: billingNumber,
    getTransactionFrames: getTransactionFrames
  };
};
export var isRemoteControlActive = function isRemoteControlActive(_ref21) {
  var dmpconnectConnectorConfig = _ref21.dmpconnectConnectorConfig;
  return getConfigurationValue('debugActivateRemoteControl', dmpconnectConnectorConfig) && !getConfigurationValue('forceDesactivateRemoteControl', dmpconnectConnectorConfig);
};
export var getRemoteControlAppName = function getRemoteControlAppName(_ref22) {
  var dmpConnectPersistedAppConfiguration = _ref22.dmpConnectPersistedAppConfiguration;
  return dmpConnectPersistedAppConfiguration.remoteControlAppName || '';
};
export var getMssAccounts = function getMssAccounts(state) {
  var _state$mssAccounts = state.mssAccounts,
    mssAccounts = _state$mssAccounts === void 0 ? {} : _state$mssAccounts;
  var _getAccessRightsProps4 = getAccessRightsProps(state),
    accessRights = _getAccessRightsProps4.accessRights;
  var _ref23 = mssAccounts[accessRights.psId] || {},
    _ref23$accounts = _ref23.accounts,
    accounts = _ref23$accounts === void 0 ? [] : _ref23$accounts;
  return accounts;
};
export var getMssAccountConfigByEmail = function getMssAccountConfigByEmail(state, email) {
  var strict = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var _state$mssAccounts2 = state.mssAccounts,
    mssAccounts = _state$mssAccounts2 === void 0 ? {} : _state$mssAccounts2;
  var _getAccessRightsProps5 = getAccessRightsProps(state),
    accessRights = _getAccessRightsProps5.accessRights;
  var _ref24 = mssAccounts[accessRights.psId] || {},
    _ref24$accounts = _ref24.accounts,
    accounts = _ref24$accounts === void 0 ? [] : _ref24$accounts;
  if (email) {
    return accounts.find(function (account) {
      return account.mssEmail === email;
    }) || {};
  }
  if (!strict && accounts.length === 1) {
    return accounts[0];
  }
  return undefined;
};
export var getActiveMssAccountConfig = function getActiveMssAccountConfig(state) {
  var _state$mssAccounts3 = state.mssAccounts,
    mssAccounts = _state$mssAccounts3 === void 0 ? {} : _state$mssAccounts3;
  var _getAccessRightsProps6 = getAccessRightsProps(state),
    accessRights = _getAccessRightsProps6.accessRights;
  var _ref25 = mssAccounts[accessRights.psId] || {},
    _ref25$accounts = _ref25.accounts,
    accounts = _ref25$accounts === void 0 ? [] : _ref25$accounts,
    activeAccount = _ref25.activeAccount;
  return accounts.find(function (account) {
    return account.id === activeAccount;
  }) || {};
};
export var getDefaultMssConfig = function getDefaultMssConfig(state) {
  var dmpconnectMSSConfiguration = state.dmpconnectMSSConfiguration;
  return _objectSpread({}, dmpconnectMSSConfiguration);
};
export var getMssEmail = function getMssEmail(state) {
  var mssConfig = getActiveMssAccountConfig(state);
  return mssConfig.mssEmail;
};
export var getMssSenderWording = function getMssSenderWording(state) {
  var mssConfig = getActiveMssAccountConfig(state);
  return mssConfig.mssSenderWording;
};
export var getMssReplyTo = function getMssReplyTo(state) {
  var mssConfig = getActiveMssAccountConfig(state);
  return mssConfig.mssReplyTo;
};
export var getMssLoginType = function getMssLoginType(state) {
  var mssConfig = getActiveMssAccountConfig(state);
  return mssConfig.mssLoginType;
};
export var getMssApiType = function getMssApiType(state) {
  var mssConfig = getActiveMssAccountConfig(state);
  return mssConfig.mssApiType;
};
export var getMssOperator = function getMssOperator(state) {
  var mssConfig = getActiveMssAccountConfig(state);
  return mssConfig.mssOperator;
};
export var getMssEmailFromUserCpx = function getMssEmailFromUserCpx(state) {
  var dmpconnectCPxConfiguration = state.dmpconnectCPxConfiguration;
  var _getAccessRightsProps7 = getAccessRightsProps(state),
    accessRights = _getAccessRightsProps7.accessRights;
  return getUserConfiguration({
    s_internalId: accessRights.psId
  }, 'mssEmail', dmpconnectCPxConfiguration);
};
export var getMssSenderWordingFromUserCpx = function getMssSenderWordingFromUserCpx(state) {
  var dmpconnectCPxConfiguration = state.dmpconnectCPxConfiguration;
  var _getAccessRightsProps8 = getAccessRightsProps(state),
    accessRights = _getAccessRightsProps8.accessRights;
  return getUserConfiguration({
    s_internalId: accessRights.psId
  }, 'mssSenderWording', dmpconnectCPxConfiguration);
};
export var getMssReplyToFromUserCpx = function getMssReplyToFromUserCpx(state) {
  var dmpconnectCPxConfiguration = state.dmpconnectCPxConfiguration;
  var _getAccessRightsProps9 = getAccessRightsProps(state),
    accessRights = _getAccessRightsProps9.accessRights;
  return getUserConfiguration({
    s_internalId: accessRights.psId
  }, 'mssReplyTo', dmpconnectCPxConfiguration);
};
export var getMssAuthenticationId = function getMssAuthenticationId(_ref26) {
  var mssAuthenticationId = _ref26.dmpConnectPersistedAppConfiguration.mssAuthenticationId;
  return mssAuthenticationId;
};
export var isMssCpxReady = function isMssCpxReady(state) {
  var _getActiveMssAccountC = getActiveMssAccountConfig(state),
    mssEmail = _getActiveMssAccountC.mssEmail,
    mssLoginType = _getActiveMssAccountC.mssLoginType;
  return mssEmail && mssLoginType === mssLoginTypes.CPX;
};
export var isMssPSCReady = function isMssPSCReady(state) {
  var _getActiveMssAccountC2 = getActiveMssAccountConfig(state),
    mssEmail = _getActiveMssAccountC2.mssEmail,
    mssImapServer = _getActiveMssAccountC2.mssImapServer,
    mssImapPort = _getActiveMssAccountC2.mssImapPort,
    mssImapLogin = _getActiveMssAccountC2.mssImapLogin,
    mssSmtpServer = _getActiveMssAccountC2.mssSmtpServer,
    mssSmtpPort = _getActiveMssAccountC2.mssSmtpPort,
    mssSmtpLogin = _getActiveMssAccountC2.mssSmtpLogin,
    mssLoginType = _getActiveMssAccountC2.mssLoginType;
  return mssEmail && !!mssImapServer && !!mssImapPort && !!(mssImapLogin || mssEmail) && !!mssSmtpServer && !!mssSmtpPort && !!(mssSmtpLogin || mssEmail) && mssLoginType === mssLoginTypes.PSC;
};
export var isMssCertReady = function isMssCertReady(state) {
  var performMssAuth = state.dmpconnect[apiSections.MSS_PERFORM_AUTHENTICATION];
  var _getActiveMssAccountC3 = getActiveMssAccountConfig(state),
    mssEmail = _getActiveMssAccountC3.mssEmail,
    mssLoginType = _getActiveMssAccountC3.mssLoginType,
    mssApiType = _getActiveMssAccountC3.mssApiType;
  var mssAuthenticationId = getMssAuthenticationId(state);
  return !!mssEmail && mssLoginType === mssLoginTypes.CERT && (mssApiType === mssSubTypes.IMAP || isReady(performMssAuth) && mssAuthenticationId);
};
export var isMssOTPReady = function isMssOTPReady(state) {
  var finalizeOtpAuth = state.dmpconnect[apiSections.MSS_FINALIZE_OTP_AUTH];
  var _getActiveMssAccountC4 = getActiveMssAccountConfig(state),
    mssEmail = _getActiveMssAccountC4.mssEmail,
    mssLoginType = _getActiveMssAccountC4.mssLoginType;
  var mssAuthenticationId = getMssAuthenticationId(state);
  return mssEmail && mssLoginType === mssLoginTypes.OTP && isReady(finalizeOtpAuth) && mssAuthenticationId;
};
export var getSessionTimeout = function getSessionTimeout(state) {
  var sessionTimeout = state.dmpconnectConnectorConfig.sessionTimeout;
  return sessionTimeout;
};
export var getEsCertificate = function getEsCertificate(_ref27) {
  var dmpconnectESConfiguration = _ref27.dmpconnectESConfiguration;
  return getConfigurationValue('esCertificate', dmpconnectESConfiguration);
};
export var getMSSEsCertificates = function getMSSEsCertificates(state) {
  var _state$dmpconnectESCo = state.dmpconnectESConfiguration,
    _state$dmpconnectESCo2 = _state$dmpconnectESCo === void 0 ? {} : _state$dmpconnectESCo,
    esMssCertificate = _state$dmpconnectESCo2.esMssCertificate,
    esMssImapCertificate = _state$dmpconnectESCo2.esMssImapCertificate,
    esMssSmtpCertificate = _state$dmpconnectESCo2.esMssSmtpCertificate,
    esMssWsCertificate = _state$dmpconnectESCo2.esMssWsCertificate;
  return {
    esMssCertificate: esMssCertificate,
    esMssImapCertificate: esMssImapCertificate,
    esMssSmtpCertificate: esMssSmtpCertificate,
    esMssWsCertificate: esMssWsCertificate
  };
};