import _objectSpread from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";
import _defineProperty from "C:\\Users\\conta\\PhpstormProjects\\efficience-vitale-reader\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import merge from 'lodash.merge';
import { dmpconnectConfigurationActionConstants, dmpconnectInitActionConstants } from '../constants';
var initialState = {
  vitaleCardReader: -1,
  cpxCardReader: -1,
  nfcCardReader: -1,
  connectorJWT: '',
  // onlyGeneratedConnectorJWT: false,
  authBearerUseExternalJWT: false,
  authBearerUseJWTLogin: false
};
function dmpconnectPersistedConnectorConfiguration() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_CONNECTOR_CONFIGURATION:
      return merge({}, state, _defineProperty({}, action.key, action.value));
    case dmpconnectInitActionConstants.DMPC_LOGOUT_SUCCESS:
      return _objectSpread({}, state, {
        connectorJWT: state.authBearerUseJWTLogin ? '' : state.connectorJWT
      });
    case dmpconnectInitActionConstants.DMPC_RESET_APPLICATION:
      return merge({}, state, {
        connectorJWT: ''
      });
    default:
      return state;
  }
}
export default function getDmpconnectPersistedConnectorConfiguration(_ref) {
  var _ref$vitaleCardReader = _ref.vitaleCardReader,
    vitaleCardReader = _ref$vitaleCardReader === void 0 ? -1 : _ref$vitaleCardReader,
    _ref$cpxCardReader = _ref.cpxCardReader,
    cpxCardReader = _ref$cpxCardReader === void 0 ? -1 : _ref$cpxCardReader,
    _ref$nfcCardReader = _ref.nfcCardReader,
    nfcCardReader = _ref$nfcCardReader === void 0 ? -1 : _ref$nfcCardReader,
    _ref$connectorJWT = _ref.connectorJWT,
    connectorJWT = _ref$connectorJWT === void 0 ? '' : _ref$connectorJWT,
    _ref$authBearerUseExt = _ref.authBearerUseExternalJWT,
    authBearerUseExternalJWT = _ref$authBearerUseExt === void 0 ? false : _ref$authBearerUseExt,
    _ref$authBearerUseJWT = _ref.authBearerUseJWTLogin,
    authBearerUseJWTLogin = _ref$authBearerUseJWT === void 0 ? false : _ref$authBearerUseJWT;
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      vitaleCardReader: vitaleCardReader,
      cpxCardReader: cpxCardReader,
      nfcCardReader: nfcCardReader,
      connectorJWT: connectorJWT,
      authBearerUseExternalJWT: authBearerUseExternalJWT,
      authBearerUseJWTLogin: authBearerUseJWTLogin
    };
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return dmpconnectPersistedConnectorConfiguration(state, action);
  };
}
;