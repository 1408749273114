export var isLoading = function isLoading(section) {
  if (!section) {
    return false;
  }
  var loading = section.loading;
  return loading === true;
};
export var isTooLong = function isTooLong(section) {
  if (!section) {
    return false;
  }
  var loading = section.loading,
    tooLong = section.tooLong;
  return loading === true && tooLong === true;
};
export var isReady = function isReady(section) {
  if (!section) {
    return false;
  }
  var s_status = section.s_status;
  return s_status === 'OK';
};
export var getError = function getError(section) {
  if (!section) {
    return null;
  }
  var _section$error = section.error,
    error = _section$error === void 0 ? null : _section$error;
  return error;
};
export var hasError = function hasError(section) {
  if (!section) {
    return false;
  }
  var _section$error2 = section.error,
    error = _section$error2 === void 0 ? null : _section$error2;
  return error !== null;
};
export var getSectionState = function getSectionState(state, section, subSection) {
  var sectionState = state.dmpconnect[section];
  var sState = sectionState;
  if (subSection) {
    sState = sectionState[subSection];
  }
  return {
    ready: isReady(sState),
    loading: isLoading(sState),
    hasError: hasError(sState)
  };
};