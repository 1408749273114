import { dmpconnectMonitoringActionConstants } from '../constants';
var initialState = {
  vitaleCard: {},
  cpxCard: {}
};
export default function dmpconnectMonitoring() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case dmpconnectMonitoringActionConstants.DMPC_CPX_MONITORING_START:
      return {
        vitaleCard: state.vitaleCard
      };
    case dmpconnectMonitoringActionConstants.DMPC_CPX_MONITORING_STOP:
      return {
        vitaleCard: state.vitaleCard,
        cpxCard: {}
      };
    case dmpconnectMonitoringActionConstants.DMPC_CPX_MONITORING_UPDATE:
      return {
        vitaleCard: state.vitaleCard,
        cpxCard: action.data
      };
    case dmpconnectMonitoringActionConstants.DMPC_VITALE_MONITORING_START:
      return {
        cpxCard: state.cpxCard
      };
    case dmpconnectMonitoringActionConstants.DMPC_VITALE_MONITORING_STOP:
      return {
        cpx: state.cpx,
        vitaleCard: {}
      };
    case dmpconnectMonitoringActionConstants.DMPC_VITALE_MONITORING_UPDATE:
      return {
        cpxCard: state.cpxCard,
        vitaleCard: action.data
      };
    default:
      return state;
  }
}