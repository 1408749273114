import xorCrypt from 'xor-crypt';
export var encodeIns = function encodeIns(ins) {
  return encodeURIComponent(window.btoa(xorCrypt(ins)));
};
export var decodeIns = function decodeIns(ins) {
  return xorCrypt(window.atob(decodeURIComponent(ins)));
};
export function isInsFormatValid(ins) {
  var regexp = /(^\d{22}$)|(^\d{6}[0-9ABab]\d{8}[TDN]?$)/;
  return regexp.test(ins);
}