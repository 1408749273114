export const getCurrentPathname = (state) => {
  const { router: { location: { pathname } } } = state;
  return pathname;
};

export const getSessionId = (state) => {
  const { dmpconnect } = state;
  const { session }    = dmpconnect;
  return session.s_sessionId;
};

export const getSaasTokenConfig = (state) => {
  const { dmpConnectPersistedAppConfiguration: { saasToken, validatedSaasTokens } } = state;
  return { saasToken, validatedSaasTokens };
};
